import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchGreyQualities,
  getGreyQualities,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import { useMemo } from "react";
import { useState } from "react";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { createDirectPO } from "../../app/reducers/DirectPO/DirectPOSlice";

const DirectUnfinishedEmbroidery = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const locationInfoId = localStorage.getItem("locationInfoId");

  useEffect(() => {
    dispatch(fetchUsers({ __t: "vendor" }));
    dispatch(fetchGreyQualities());
    dispatch(fetchLocations());
  }, []);

  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const { loading: greyLoading, greyQuality } = useSelector(getGreyQualities);
  const { loading: catalogLoading, catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);

  const data = useMemo(() => (catalog?.docs ? catalog.docs : []), [catalog]);
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );
  // console.log(currentItem, "index")

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      manufacturer: "",
      location: locationInfoId,
      catalog: "",
      product: "",
      quantity: "",
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      location: Yup.string().required(),
      catalog: Yup.string().required(),
      product: Yup.string().required(),
      quantity: Yup.number().required(),
    }),
    onSubmit: async (values) => {
      dispatch(createDirectPO({ ...values, type: "unfinishedEmbroidery" }));
      formik.resetForm();
    },
  });

  return (
    <>
      <PageWithCard heading="Direct Unfinished Embroidery Order">
        <div className="text-slate-800 font-semibold mb-4">Purchase Order</div>
        {/* {loading ? (
                <ClipLoader />
            ) : ( */}
        {progressLoading ? (
          <ProgressBar currentItem={currentItem} totalItem={totalItem} />
        ) : (
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
            <FormikSelectGroup
              formik={formik}
              label="Manufacturer"
              name="manufacturer"
              required
              onChange={(data) => {
                dispatch(
                  fetchCatalogs({
                    manufacturer: data.value,
                    isEmbroidery: true,
                  })
                );
                formik.setFieldValue("manufacturer", data.value);
              }}
              onBlur={formik.handleBlur}
              options={generateOptions({
                array: users ? users.docs : [],
                valueField: "_id",
                labelField: "name",
              })}
            />
            {formik.values.manufacturer && (
              <>
                <FormikSelectGroup
                  formik={formik}
                  label="Location"
                  name="location"
                  required
                  options={generateOptions({
                    array: location ? location.docs : [],
                    valueField: "_id",
                    labelField: "name",
                  })}
                />
                <FormikSelectGroup
                  formik={formik}
                  label="Select Catalog"
                  name={`catalog`}
                  onChange={async (selectedOption) => {
                    dispatch(fetchProducts({ catalog: selectedOption.value }));
                    formik.setFieldValue(`catalog`, selectedOption.value);
                  }}
                  options={generateOptions({
                    array: data,
                    valueField: "_id",
                    labelField: "name",
                  })}
                  required
                />
                {formik.values.catalog && (
                  <>
                    <FormikSelectGroup
                      formik={formik}
                      label="Select Color"
                      name={`product`}
                      options={generateOptions({
                        array: productData,
                        valueField: "_id",
                        labelField: "color",
                      })}
                      required
                    />

                    <FormikInputGroup
                      formik={formik}
                      label="Quantity"
                      type="number"
                      name={`quantity`}
                      required
                    />
                  </>
                )}
              </>
            )}
            <div>
              <PrimaryButton type="submit" onClick={formik.handleSubmit}>
                Submit
              </PrimaryButton>
            </div>
          </form>
        )}
      </PageWithCard>
    </>
  );
};

export default DirectUnfinishedEmbroidery;
