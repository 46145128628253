import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "productDirectPO";

export const fetchDirecPOs = fetchData.generateThunk(SLICE_NAME,SLICE_NAME);
export const editDirectPO = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchDirectPO = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteDirectPO = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createDirectPO = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const directPOSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchDirecPOs, SLICE_NAME),
    ...createData.generateExtraReducers(createDirectPO, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteDirectPO, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchDirectPO, SLICE_NAME),
    ...editData.generateExtraReducers(editDirectPO, SLICE_NAME),
  },
});

export const getDirectPO = (state) => state.directPO;
export const { setEditId } = directPOSlice.actions;
export default directPOSlice.reducer;
