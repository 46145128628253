import { FieldArray, FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
    fetchUsers, getUsers,
} from "../../app/reducers/Users/userSlice";
import {
    setEditId,
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getProductPO,
} from "../../app/reducers/PurchaseOrder/purchaseOrderSlice";
import EditModal from "../../components/infrastructure/Modals/EditModal";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import {
    generateOptions,
} from "../../utils/Utils";
import FormikFileInput from "../../components/formik/FormikFileInput";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import { useState } from "react";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";

const EditPurchaseModel = (props) => {
    const dispatch = useDispatch();
    console.log(props?.values)
    useEffect(() => {
        dispatch(fetchUsers({ role: 'manufacturer' }))
    }, [])

    const [loadingUpload, setLoadingUpload] = useState(false)


    const {
        elementEditId,
        editDataLoading,
        elementEditData,
    } = useSelector(getProductPO);
    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
        },
        onSubmit: async (values) => {
           try{
             const response = await authAxiosInstance.patch(`productPO`, {...values,oldData:elementEditData})
             if (response.data) {
                toast.success(response.data.message)
                dispatch(fetchPurchaseOrders({...props?.values,populate: true}))
             }
           }
           catch(error) {
            if(error.response.data.message) {
                return toast.error(error.response.data.message);
              }
              toast.error("Something went wrong");
           }
        },
    });
    useEffect(() => {
        if (elementEditId) {
            dispatch(fetchPurchaseOrder({ _id: elementEditId }));
        }
    }, [elementEditId]);
    return (
        <EditModal
            editProperty={elementEditId}
            setEditProperty={setEditId}
            getEditData={fetchPurchaseOrder}
            title="Edit Purchase"
        >
            {editDataLoading ? (
                <ClipLoader />
            ) : (
                <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4 p-4'>
                    <div className="mt-4">
                        <FormikInputGroup
                            label="SystemId"
                            name="_id"
                            formik={formik}
                            required
                            readOnly
                        />
                        <FormikInputGroup
                            label="Quantity"
                            name="quantity"
                            formik={formik}
                            required
                            readOnly
                        />
                        <FormikProvider value={formik} label="Received Item">
                            {
                            formik.values?.received?.length > 0 &&
                            <div className="font-medium mt-1">Received Items</div>
                            }
                            <FieldArray
                                name='received'
                                render={(arrayHelpers) => {
                                    return (
                                        <div className='flex flex-col gap-2 mt-2'>
                                            {/* <p>Address</p> */}
                                            <div>
                                                {formik.values?.received?.map((ele, index) => (
                                                    <div
                                                        className='relative p-4 mb-2'
                                                        style={{
                                                            border: '1px solid #d6c7c7',
                                                            borderRadius: '5px',
                                                        }}
                                                        key={index}
                                                    >
                                                        <>
                                                            <FormikInputDateGroup
                                                                label='Date'
                                                                name={`received.${index}.date`}
                                                                formik={formik}
                                                                readOnly
                                                            />
                                                            <FormikInputGroup
                                                                formik={formik}
                                                                label='Reeived Item'
                                                                type='number'
                                                                value={
                                                                    formik.values.received[index].receivedItem
                                                                }
                                                                name={`received.${index}.receivedItem`}
                                                                required
                                                            />
                                                            <FormikInputGroup
                                                                formik={formik}
                                                                label='Damaged Item'
                                                                type='number'
                                                                value={
                                                                    formik.values.received[index].damagedItem
                                                                }
                                                                name={`received.${index}.damagedItem`}
                                                                required
                                                            />
                                                        </>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                }}
                            />
                        </FormikProvider>
                        {
                            loadingUpload ? <ClipLoader /> :
                            formik.values?.received?.length > 0 && formik.values.status != 'completed' && formik.values.status != 'settled' &&
                                <PrimaryButton type='submit'>Edit</PrimaryButton>
                        }
                    </div>
                </form>
            )}
        </EditModal>
    );
};

export default EditPurchaseModel;
