import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchSale, getSale } from "../../app/reducers/Sale/saleSlice";
import { BillDocument } from "./Bill";
import { PDFViewer } from "@react-pdf/renderer";

const ViewBillPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { elementEditData } = useSelector(getSale);
  useEffect(() => {
    dispatch(fetchSale({ _id: id, populate: true }));
  }, [id]);
  console.log(elementEditData);
  return (
    <div className="flex justify-center items-center h-screen">
      <PDFViewer className="w-full h-screen">
        {elementEditData && (
          <BillDocument
            bill={{
              ...elementEditData,
            }}
          />
        )}
      </PDFViewer>
    </div>
  );
};

export default ViewBillPage;
