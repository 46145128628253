import { FieldArray, FormikProvider } from 'formik'
import QueryString from 'qs'
import React from 'react'
import { useState } from 'react'
import FormikSelectGroup from '../../components/formik/FormikSelectGroup'
import FormikInputGroup from '../../components/formik/FormikInputGroup'
import DangerButton from '../../components/infrastructure/Buttons/DangerButton'
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton'
import { generateOptions } from '../../utils/Utils'
import { authAxiosInstance } from '../../utils/axiosConfig'
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect'
import { toast } from 'react-toastify'

const AddProgram = ({ formik, catalogData, index, arrayHelpers }) => {

    const [typeData, setTypeData] = useState([])

    const [fetchProductDocs, setFetchProductDocs] = useState([])

    const fetchProductColor = async (data) => {
        const string = QueryString.stringify(data);
        const resp = await authAxiosInstance.get(`product?${string}`)
        console.log("fetchProductColor", resp)
        setFetchProductDocs(resp?.data?.data?.docs ? resp.data.data.docs : [])
    }
    return (
        <div>

            {/* <FormikSelectGroup
                formik={formik}
                label='Select Catalog'
                onChange={async (selectedOption) => {
                    let findCatalog = catalogData.find(d => d._id == selectedOption.value)
                    if (findCatalog?.isEmbroidery) {
                        setTypeData([{ label: 'finished', value: 'finished' }, { label: 'unfinished', value: 'unfinished' }, { label: 'unfinishedEmbroidery', value: 'unfinished embroidery' }])
                    } else {
                        setTypeData([{ label: 'finished', value: 'finished' }, { label: 'unfinished', value: 'unfinished' }])
                    }
                    // dispatch(fetchProducts({ catalog: selectedOption.value }))
                    fetchProductColor({ catalog: selectedOption.value })
                    console.log("formikValues", formik.values)
                    formik.setFieldValue(
                        `products.${index}.catalog`,
                        selectedOption.value
                    )
                }}
                name={`products.${index}.catalog`}
                options={generateOptions({
                    array: catalogData,
                    valueField: '_id',
                    labelField: 'name',
                })}
                required
            /> */}
            <FormikAsyncSelect
                formik={formik}
                label='Select Catalog'
                getOptions={async (value) => {
                    console.log("getOptions", value)
                    const query = {
                        search: value,
                    };
                    const string = QueryString.stringify(query);
                    const catalog = await authAxiosInstance.get(`catalog?${string}`);
                    console.log("getOptions1", catalog)
                    const options = catalog.data.data.docs.map((ele) => ({
                        label: ele.name,
                        value: ele._id,
                    }));
                    return options;
                }}
                onChange={async (selectedOption) => {
                    let catalogExist = formik.values.products.find(d => d.catalog == selectedOption.value)
                    if (catalogExist) {
                        toast.error("Catalog Already Exist")
                    }
                    let findCatalog = catalogData.find(d => d._id == selectedOption.value)
                    if (findCatalog?.isEmbroidery) {
                        setTypeData([{ label: 'finished', value: 'finished' }, { label: 'unfinished', value: 'unfinished' }, { label: 'unfinishedEmbroidery', value: 'unfinished embroidery' }])
                    } else {
                        setTypeData([{ label: 'finished', value: 'finished' }, { label: 'unfinished', value: 'unfinished' }])
                    }
                    // dispatch(fetchProducts({ catalog: selectedOption.value }))
                    fetchProductColor({ catalog: selectedOption.value })
                    console.log("formikValues", formik.values)
                    formik.setFieldValue(
                        `products.${index}.catalog`,
                        selectedOption.value
                    )
                }}
                name={`products.${index}.catalog`}
                options={generateOptions({
                    array: catalogData,
                    valueField: '_id',
                    labelField: 'name',
                })}
                required
            />
            <FormikSelectGroup
                formik={formik}
                label='Select Color'
                name={`products.${index}.color`}
                onChange={(option) => {
                    const greyColor = formik.values.greyColor.toLowerCase().split("/")[1]
                    console.log("confirm", greyColor)
                    if (greyColor !== option.label.toLowerCase()) {

                        const confirm = window.confirm("Quality color selected is diffrent. Are you sure you want to continue")
                        if (confirm) {
                            formik.setFieldValue(`products.${index}.color`, option.value)
                        }
                    } else {
                        formik.setFieldValue(`products.${index}.color`, option.value)
                    }
                }}
                options={generateOptions({
                    array: fetchProductDocs,
                    valueField: '_id',
                    labelField: 'color',
                })}
                required
            />
            <FormikSelectGroup
                formik={formik}
                label='Select Type'
                name={`products.${index}.type`}
                options={generateOptions({
                    array: typeData,
                    valueField: 'label',
                    labelField: 'value',
                })}
                required
            />
            <FormikInputGroup
                formik={formik}
                label="Quantity"
                type="number"
                name={`products.${index}.quantity`}
                required
            />
            <div>
                <DangerButton
                    className='mt-3'
                    onClick={() => {
                        arrayHelpers.remove(index)
                    }}
                    type='button'
                >
                    Remove
                </DangerButton>
            </div>

        </div>
    )
}

export default AddProgram