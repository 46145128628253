import React from 'react'
import ModalBasic from './ModalBasic'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAssets, getMediaLibrary } from '../../../app/reducers/MediaLibrary/mediaLibrarySlice'

const ViewPhotoModal = ({ modalOpen, setModalOpen, photoData, baseUrlRequired }) => {
    // const dispatch = useDispatch()
    const { baseUrl } = useSelector(getMediaLibrary)
    // useEffect(() => {
    //     dispatch(fetchAssets({ currentFolder: '' }));
    // })
    return (
        <ModalBasic modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Photo Preview"} max_width='max-w-[1000px]'>
            <img className='w-full' src={baseUrlRequired ? baseUrl + photoData : photoData} alt="" />
        </ModalBasic>
    )
}

export default ViewPhotoModal