import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { Edit2, Eye, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { fetchDirecPOs, getDirectPO } from "../../app/reducers/DirectPO/DirectPOSlice";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useFormik } from "formik";
import { generateOptions } from "../../utils/Utils";
import * as Yup from 'yup'
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchProductStockAdjustments, fetchStockAdjustments, getStockAdjustment } from "../../app/reducers/StockAdjustment/stockAdjustmentSlice";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { useCallback } from "react";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import ImageComponent from "../../components/ImageComponent";

const ViewStockAdjustment = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState()
    const { loading: stockAdjustmentLoading, stockAdjustment, stockAdjustmentProduct } = useSelector(getStockAdjustment);
    const columns = [
        {
            Header: "SRNO",
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },

        {
            Header: "Photo",
            Cell: ({ row }) => {
                return (
                    <div
                        style={{
                            width: '170px',
                            height: 'auto',
                            padding: '10px',
                        }}
                    >
                        {row.original?.greyColorData?.image &&
                            <ImageComponent src={row.original?.greyColorData?.image} alt='nothing' />}
                    </div>
                );
            },
        },
        {
            Header: "location",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.locationData?.name}
                    </span>
                );
            },
        },
        {
            Header: "Grey Color",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.greyColorData?.color}
                    </span>
                );
            },
        },
        {
            Header: "Grey Quality",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.greyQualityData?.name}
                    </span>
                );
            },
        },
        {
            Header: "Old Quality",
            accessor: "oldFinished"
        },
        {
            Header: "Quantity",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.quantity}
                    </span>
                );
            },
        }
    ];
    const columnsProduct = [
        {
            Header: "SRNO",
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },

        {
            Header: "Photo",
            Cell: ({ row }) => {
                return (
                    <div
                        style={{
                            width: '170px',
                            height: 'auto',
                            padding: '10px',
                        }}
                    >
                        {row.original?.productData?.image &&
                            <ImageComponent src={row.original?.productData?.image} alt='nothing' />}
                    </div>
                );
            },
        },
        {
            Header: "location",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.locationData?.name}
                    </span>
                );
            },
        },
        {
            Header: "Catalog",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.catalogData?.name}
                    </span>
                );
            },
        },
        {
            Header: "Product",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.productData?.color}
                    </span>
                );
            },
        },
        {
            Header: "Product Type",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.productType}
                    </span>
                );
            },
        },
        {
            Header: "Old Quality",
            accessor: "oldData"
        },
        {
            Header: "Quantity",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.quantity}
                    </span>
                );
            },
        }
    ];

    useEffect(() => {
        if (formik.values.type == "grey") {
            dispatch(fetchStockAdjustments(
                { page, populate: true, }
            ))
        }
        else {
            dispatch(fetchProductStockAdjustments({ page, populate: true, }))
        }
    }, [page])

    const columnsMemo = useMemo(() => columns, [columns]);
    const dataMemo = useMemo(() => (stockAdjustment?.docs ? stockAdjustment.docs : []), [stockAdjustment]);
    const productMemo = useMemo(() => (stockAdjustmentProduct?.docs ? stockAdjustmentProduct?.docs : []), [stockAdjustmentProduct]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            type: '',
        },
        validationSchema: Yup.object({
            type: Yup.string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            // if(values.type == 'grey') {
            //     dispatch(fetchStockAdjustments({  populate: true }))
            // }else{

            //     dispatch(fetchProductStockAdjustments({  populate: true }))
            // }
        },
    })
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            if (formik.values.type == "grey") {

                dispatch(
                    fetchStockAdjustments({
                        search: search,
                        populate: true,
                    })
                );
            } else {
                dispatch(fetchProductStockAdjustments({ search: search, populate: true }))
            }
        }, 300),
        []
    );
    return (
        <PageWithCard heading="View Stock Adjustment">
            <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4 mb-3'>
                <FormikSelectGroup
                    formik={formik}
                    label='Select Type'
                    name='type'
                    onChange={(selectedOption) => {
                        if (selectedOption.value == 'grey') {
                            dispatch(fetchStockAdjustments({ populate: true }))
                        } else {

                            dispatch(fetchProductStockAdjustments({ populate: true }))
                        }
                        formik.setFieldValue('type', selectedOption.value)
                    }}
                    options={generateOptions({
                        array: [{ label: 'Grey', value: 'grey' }, { label: 'Product', value: 'productAdjust' }],
                        valueField: 'value',
                        labelField: 'label',
                    })}
                    required
                />
                {/* <div>
                    <PrimaryButton type='submit'>Submit</PrimaryButton>
                </div> */}
            </form>
            {
                formik.values.type == 'grey' && dataMemo &&
                (<TableWithHeadingAndGlobalSearch
                    columns={columnsMemo}
                    data={dataMemo}
                    heading="View Stock Adjustment"
                    loading={stockAdjustmentLoading}
                    searchFunction={(value) => {
                        debouncedSearch(value);
                    }}
                />)
            }
            {
                formik.values.type == 'productAdjust' && productMemo &&
                (<>
                    <TableWithHeadingAndGlobalSearch
                        columns={columnsProduct}
                        data={productMemo}
                        heading="View Stock Adjustment"
                        loading={stockAdjustmentLoading}
                        searchFunction={(value) => {
                            debouncedSearch(value);
                        }}
                    />
                    <PaginationClassic paginationDetails={stockAdjustmentProduct} setPage={setPage} />
                </>
                )
            }

        </PageWithCard>
    );
};

export default ViewStockAdjustment;
