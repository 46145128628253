import React from "react";
import SecondaryButton from "../infrastructure/Buttons/SecondaryButton";
import { CSVLink } from "react-csv";

const ProgressBar = ({ totalItem, currentItem, errorData=null }) => {

  let progress = 100 / totalItem
  return (
    <>
      {
        errorData && errorData.length > 0 &&
        <CSVLink
          className="text-red-500 underline"
          data={errorData}
          filename={"errors.csv"}
        >
          Errors File
        </CSVLink>
      }
      <div style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}>
        <div>{currentItem} Out of {totalItem} created</div>
        <div style={{ width: "100%", height: "10px", border: "1px solid black" }}>
          <div style={{ backgroundColor: "blue", height: "inherit", width: `${currentItem * progress}%` }}></div>
        </div>

        {totalItem == currentItem &&
          <SecondaryButton className="mt-3" onClick={() => { window.location.reload() }}>
            Reload
          </SecondaryButton>}
      </div>
    </>
  );
};

export default ProgressBar;
