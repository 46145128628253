import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  editUser,
  getUsers,
  setEditId,
} from "../../app/reducers/Users/userSlice";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { EMPLOYEE_ROLES, VENDOR_ROLES } from "../../utils/dropdownOptions";
import { generateOptions } from "../../utils/Utils";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import ADMIN_ROLES from "../../utils/roles";

const EditUserForm = () => {
  const { elementEditData, editDataLoading, editUserId } =
    useSelector(getUsers);

  const dispatch = useDispatch();
  const { ...userValues } = elementEditData || {};
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...userValues, password: "" },
    onSubmit: (values) => {
      dispatch(editUser({ ...values }));
    },
  });
  useEffect(() => {
    return () => dispatch(setEditId(null));
  }, [editUserId]);

  return (
    <>
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup label="Name" formik={formik} name="name" required />
          <FormikInputGroup
            label="Company Name"
            formik={formik}
            name="companyName"
            required
          />
          <FormikInputGroup
            label="Phone"
            formik={formik}
            name="username"
            required
          />
          {ADMIN_ROLES.EMPLOYEE.includes(userValues.role) && (
            <FormikInputGroup
              label="Password (only for Employee)"
              formik={formik}
              name="password"
              type="password"
            />
          )}
          <FormikInputGroup label="Email" formik={formik} name="email" />
          <FormikSelectGroup
            label="Role"
            formik={formik}
            name="role"
            required
            options={generateOptions({
              array: [...EMPLOYEE_ROLES, ...VENDOR_ROLES],
              labelField: "label",
              valueField: "value",
            })}
          />
          <FormikInputGroup
            label="GST Number"
            formik={formik}
            name="gstNumber"
          />
          <FormikTextareaGroup label="Address" formik={formik} name="address" />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </>
  );
};

export default EditUserForm;
