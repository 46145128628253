import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { Edit2, Trash, X } from 'react-feather';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  fetchProducts,
  getProduct,
  setEditId,
  deleteProduct,
} from '../../app/reducers/Product/productSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import {
  fetchStocks,
  getStockTransfer,
  editStock,
} from '../../app/reducers/StockTransfer/stockTransferSlice';
import { async } from 'regenerator-runtime';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import ADMIN_ROLES from '../../utils/roles';
import ImageComponent from '../../components/ImageComponent';
import PaginationClassic from '../../components/pagination/PaginationClassic';

const ViewStockTransfer = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(getAuth);
  const locationInfoId = localStorage.getItem('locationInfoId');
  const [page, setPage] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    dispatch(fetchStocks({ to: locationInfoId, page: page, limit: 300, populate: true }));
  }, [page]);

  const { loading, stockTransfer } = useSelector(getStockTransfer);

  const data = useMemo(() => (stockTransfer?.docs ? stockTransfer.docs : []), [stockTransfer]);

  const columns = [
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: 'Photo',
      Cell: prop => {
        const { data, row } = prop;
        console.log(row);
        return (
          <>
            {row.original?.productData?.image && (
              <ImageComponent src={row.original?.productData?.image} style={{ width: '120px' }} />
            )}
          </>
        );
      },
    },
    {
      Header: 'From',
      accessor: 'fromData.name',
    },
    {
      Header: 'To',
      accessor: 'toData.name',
    },
    {
      Header: 'Catalog',
      accessor: 'catalogData.name',
    },
    {
      Header: 'Product',
      accessor: 'productData.color',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Action',
      Cell: prop => {
        const { data, row } = prop;
        return (
          <div
            className="flex items-center gap-2"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {row.original.status == 'approved' ? (
              <span style={{ color: 'blue' }}>Approved</span>
            ) : row.original.status == 'rejected' ? (
              <span style={{ color: 'red' }}>Rejected</span>
            ) : (
              <>
                {[...ADMIN_ROLES.MANAGEMENT, 'stock_transfer'].includes(user.role) && (
                  <>
                    <PrimaryButton
                      disabled={isSubmitting}
                      onClick={async () => {
                        try {
                          setIsSubmitting(true);
                          await dispatch(editStock({ ...row.original, status: 'approved' }));
                          await dispatch(fetchStocks({ to: locationInfoId, populate: true }));
                        } catch (error) {
                          console.log(error);
                        } finally {
                          setTimeout(() => {
                            setIsSubmitting(false);
                          }, 500);
                        }
                      }}
                    >
                      Approve
                    </PrimaryButton>
                    <PrimaryButton
                      disabled={isSubmitting}
                      onClick={async () => {
                        try {
                          setIsSubmitting(true);
                          await dispatch(editStock({ ...row.original, status: 'rejected' }));
                          await dispatch(fetchStocks({ to: locationInfoId, populate: true }));
                        } catch (error) {
                          console.log(error);
                        } finally {
                          setTimeout(() => {
                            setIsSubmitting(false);
                          }, 500);
                        }
                      }}
                    >
                      Reject
                    </PrimaryButton>
                  </>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const debouncedSearch = useCallback(
    _.debounce(search => {
      console.log('check');
      dispatch(fetchStocks({ search: search, to: locationInfoId, populate: true }));
    }, 300),
    []
  );

  return (
    <>
      <PageWithCard heading="View Stock Transfer">
        {locationInfoId ? (
          <>
            <TableWithHeadingAndSearch
              heading="Products"
              data={data}
              columns={columns}
              loading={loading}
              searchFunction={value => {
                debouncedSearch(value);
              }}
            />
            <PaginationClassic paginationDetails={stockTransfer} setPage={setPage} />
          </>
        ) : (
          <h2>Please Assign Location To See Stock Transfer</h2>
        )}
      </PageWithCard>
    </>
  );
};

export default ViewStockTransfer;
