import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchGreyQualities,
  getGreyQualities,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import { useMemo, useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import moment from "moment";
import {
  getGreyColor,
  fetchGreyColors,
} from "../../app/reducers/GreyColor/greyColorSlice";

const SendPrintOrder = () => {
  const dispatch = useDispatch();
  const { id: greyId } = useParams();
  console.log(greyId, "greyID");
  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchGreyQualities());
    if (greyId) {
      dispatch(fetchGreyColors({ greyQuality: greyId }));
    }
    dispatch(fetchLocations());
  }, []);

  const { users } = useSelector(getUsers);
  const { loading: greyLoading, greyQuality } = useSelector(getGreyQualities);
  const { loading: greyColorLoading, greyColor } = useSelector(getGreyColor);
  const { loading: locationLoading, location } = useSelector(getLocation);

  const greyQuaityData = useMemo(
    () => (greyQuality?.docs ? greyQuality.docs : []),
    [greyQuality]
  );
  const greyColorData = useMemo(
    () => (greyColor?.docs ? greyColor.docs : []),
    [greyColor]
  );

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      manufacturer: "",
      greyQuality: greyId ? greyId : "",
      greyColor: "",
      location: "",
      chalan: "",
      expected_delivery_date: moment(new Date()).add(7, "days"),
      quantity: 0,
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      greyQuality: Yup.string().required(),
      greyColor: Yup.string().required(),
      location: Yup.string().required(),
      expected_delivery_date: Yup.string(),
      chalan: Yup.number().required(),
      quantity: Yup.number().min(1, "Please add atleast 1 qty"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await authAxiosInstance.post(`printPO`, values);
        if (response.data) {
          resetForm();
          toast.success(response.data.message);
        }
      } catch (error) {
        if (error.response.data.message) {
          return toast.error(error.response.data.message);
        }
        toast.error("Something went wrong");
      }
    },
  });

  return (
    <>
      <PageWithCard heading="Send Print Order">
        <div className="text-slate-800 font-semibold mb-4">
          Send Print Order
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikSelectGroup
            formik={formik}
            label="Manufacturer"
            name="manufacturer"
            required
            options={generateOptions({
              array: users ? users.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
          />
          <FormikInputDateGroup
            label="Expected Delivery Date"
            name="expected_delivery_date"
            formik={formik}
          />
          <FormikSelectGroup
            formik={formik}
            label="Location"
            name="location"
            required
            options={generateOptions({
              array: location ? location.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
          />
          <FormikSelectGroup
            formik={formik}
            label="Grey"
            name="greyQuality"
            onChange={(selectedOptions) => {
              dispatch(fetchGreyColors({ greyQuality: selectedOptions.value }));
              formik.setFieldValue("greyQuality", selectedOptions.value);
            }}
            options={generateOptions({
              array: greyQuaityData,
              valueField: "_id",
              labelField: "name",
            })}
            required
          />
          {formik.values.greyQuality && (
            <>
              <FormikSelectGroup
                formik={formik}
                label="Grey Color"
                name="greyColor"
                required
                options={generateOptions({
                  array: greyColorData,
                  valueField: "_id",
                  labelField: "color",
                })}
              />
              <FormikInputGroup
                type="number"
                formik={formik}
                label="Quantity"
                name="quantity"
              />
              <FormikInputGroup
                type="number"
                formik={formik}
                label="Chalan"
                name="chalan"
              />
            </>
          )}
          <div>
            <PrimaryButton
              disabled={formik.isSubmitting}
              type="submit"
              onClick={formik.handleSubmit}
            >
              Submit
            </PrimaryButton>
          </div>
        </form>
      </PageWithCard>
    </>
  );
};

export default SendPrintOrder;
