import React, { useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import { useFormik } from "formik";
import { getCashbooks } from "../../app/reducers/Cashbook/cashbookSlice";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { exportCsv, generateOptions } from "../../utils/Utils";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import moment from "moment";
import { useEffect } from "react";
import { useMemo } from "react";
import {
  CASH_REPORT_PAYMENT_METHODS,
  CASH_REPORT_PAYMENT_TYPE,
} from "../../utils/dropdownOptions";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { ClipLoader } from "react-spinners";
import QueryString from "qs";
import { toast } from "react-toastify";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { limitVar } from "../../utils/limitVariable";
import { getAuth } from "../../app/reducers/Auth/authSlice";

export const CashReport = () => {
  const [cash, setCash] = useState([]);
  const [bank, setBank] = useState([]);
  const { location, loading } = useSelector(getLocation);
  const [isDownloading, setIsDownloading] = useState(false);
  const locationInfoId = localStorage.getItem("locationInfoId");
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      mode: ["bank", "cash"],
      store: [],
      type: ["credit"],
      from: moment().startOf("day").valueOf(),
      to: moment().endOf("day").valueOf(),
    },
    onSubmit: async (values) => {
      try {
        let query = {
          store: { $in: values.store },
          type: { $in: values.type },
          createdAt: {
            $gte: values.from,
            $lte: values.to,
          },
          populate: true,
          limit: 2000,
        };
        const string = QueryString.stringify(query);
        for (let i = 0; i < CASH_REPORT_PAYMENT_METHODS.length; i++) {
          if (values.mode.includes("bank")) {
            const resBank = await authAxiosInstance.get(
              `/cashbook/bank?${string}`
            );

            setBank(
              resBank?.data?.data?.docs.map((el) => ({ ...el, mode: "bank" }))
            );
          } else {
            setBank([]);
          }

          if (values.mode.includes("cash")) {
            const resBank = await authAxiosInstance.get(
              `/cashbook/cash?${string}`
            );

            setCash(
              resBank?.data?.data?.docs.map((el) => ({ ...el, mode: "cash" }))
            );
          } else {
            setCash([]);
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Submission failed");
      }
    },
  });

  useEffect(() => {
    let query = {
      store: { $in: formik.values.store },
      type: { $in: formik.values.type },
      createdAt: {
        $gte: formik.values.from,
        $lte: formik.values.to,
      },
      populate: true,
    };
    const string = QueryString.stringify(query);
    const fetchData = async () => {
      try {
        let bankResp = await authAxiosInstance.get(`/cashbook/bank?${string}`);
        console.log(bankResp, "respBank");
        setBank(
          bankResp?.data?.data?.docs?.map((ele) => ({ ...ele, mode: "bank" }))
        );

        let cashResp = await authAxiosInstance.get(`/cashbook/cash?${string}`);
        console.log(cashResp, "cashResp");
        setCash(
          cashResp?.data?.data?.docs?.map((ele) => ({ ...ele, mode: "cash" }))
        );
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch");
      }
    };
    dispatch(fetchLocations({ populate: true }));
    fetchData();
  }, []);

  const exportCsvDownloadData = async () => {
    try {
      setIsDownloading(true);
      let query = {
        store: { $in: formik.values.store },
        type: { $in: formik.values.type },
        createdAt: {
          $gte: formik.values.from,
          $lte: formik.values.to,
        },
        populate: true,
        limit: limitVar,
      };
      const string = QueryString.stringify(query);
      let data = [];

      for (let i = 0; i < formik.values.mode.length; i++) {
        const modeValue = formik.values.mode[i];
        if (modeValue.includes("bank")) {
          let bankResp = await authAxiosInstance.get(
            `/cashbook/bank?${string}`
          );
          if (bankResp.data.data.docs.length > 0) {
            const bankData = bankResp.data.data.docs;
            bankData.forEach((el) => {
              data.push({
                Store_Name: el.locationData.name || "",
                Date: moment(el.createdAt).format("DD/MM/YYYY"),
                Mode: "bank",
                "Expense Category": el.expenseCategory || "",
                Type: el.type || "",
                Amount: el.amount || "",
                Note: el.notes || "",
              });
            });
          }
        }

        if (modeValue.includes("cash")) {
          let cashResp = await authAxiosInstance.get(
            `/cashbook/cash?${string}`
          );
          console.log(cashResp, "check cashResp");
          if (cashResp.data.data.docs.length > 0) {
            const cashData = cashResp.data.data.docs;
            cashData.forEach((el) => {
              data.push({
                Store_Name: el.locationData.name || "",
                Date: moment(el.createdAt).format("DD/MM/YYYY"),
                Mode: "cash",
                "Expense Category": el.expenseCategory || "",
                Type: el.type || "",
                Amount: el.amount || "",
                Note: el.notes || "",
              });
            });
          }
        }
      }
      exportCsv(data);
    } catch (error) {
      console.log(error);
      toast.error("Failed to Download");
    } finally {
      setIsDownloading(false);
    }
  };

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        if (row?.original?.createdAt) {
          return moment(row?.original?.createdAt).format("DD/MM/YYYY");
        } else {
          return "No Date";
        }
      },
    },
    {
      Header: "Store Name",
      accessor: "locationData.name",
    },
    {
      Header: "Mode",
      accessor: "mode",
    },

    {
      Header: "Expense Category",
      accessor: "expenseCategory",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Note",
      accessor: "notes",
    },
  ];

  const dataMemo = useMemo(
    () => [...cash, ...bank],
    [JSON.stringify([...cash, ...bank])]
  );
  const columnsMemo = useMemo(() => columns, [columns]);

  console.log(formik.values, "formik");
  console.log(locationInfoId, "location");
  return (
    <PageWithCard heading="Cash Report">
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 "
      >
        <FormikMultiSelect
          name="mode"
          formik={formik}
          label="Mode"
          options={CASH_REPORT_PAYMENT_METHODS}
        />
        <FormikMultiSelect
          name="type"
          formik={formik}
          label="Type"
          options={CASH_REPORT_PAYMENT_TYPE}
        />

        <FormikMultiSelect
          name="store"
          formik={formik}
          label="Select Store"
          options={generateOptions({
            array: location?.docs ? location?.docs : [],
            valueField: "_id",
            labelField: "name",
          })}
        />
        {/* <FormikMultiSelect
          name="type"
          formik={formik}
          label="Type"
          options={PAYMENT_REPORT_TYPE}
        /> */}
        <FormikInputDateGroup label="Date From" name="from" formik={formik} />
        <FormikInputDateGroup label="Date To" name="to" formik={formik} />
        <div className="flex flex-row gap-2 py-1 md:py-7">
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
          <div>
            {isDownloading ? (
              <ClipLoader />
            ) : (
              <>
                <PrimaryButton type="button" onClick={exportCsvDownloadData}>
                  Download
                </PrimaryButton>
              </>
            )}
          </div>
        </div>
      </form>

      <TableWithHeadingAndSearch
        heading="Cashbook Report"
        data={dataMemo}
        columns={columnsMemo}
      />
    </PageWithCard>
  );
};
