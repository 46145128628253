import { FieldArray, FormikProvider, useFormik } from 'formik'
import React, { useEffect } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import FormikInputGroup from '../../components/formik/FormikInputGroup'
import FormikSelectGroup from '../../components/formik/FormikSelectGroup'
import { ClipLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton'
import { fetchUsers, getUsers } from '../../app/reducers/Users/userSlice'
import { useNavigate } from 'react-router-dom'
import { generateOptions } from '../../utils/Utils'
import {
  fetchGreyQualities,
  getGreyQualities,
} from '../../app/reducers/GreyQuality/greyQualitySlice'
import { useCallback } from 'react'
import { useMemo } from 'react'
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup'
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup'
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton'
import DangerButton from '../../components/infrastructure/Buttons/DangerButton'
import ProgressBar from '../../components/progressBar/ProgressBar'
import { useState } from 'react'
import { authAxiosInstance } from '../../utils/axiosConfig'
import {
  fetchLocations,
  getLocation,
} from '../../app/reducers/Location/locationSlice'
import {
  fetchGreyColors,
  getGreyColor,
} from '../../app/reducers/GreyColor/greyColorSlice'
import moment from 'moment'
import { toast } from 'react-toastify'

const GreyPurchaseOrder = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [currentItem, setCurrentItem] = useState(0)
  const [totalItem, setTotalItem] = useState(0)
  const [progressLoading, setProgressLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchUsers({ role: 'manufacturer' }))
    dispatch(fetchGreyQualities())
    dispatch(fetchLocations())
  }, [])

  const { users } = useSelector(getUsers)
  const { location } = useSelector(getLocation)
  const { loading: greyLoading, greyQuality } = useSelector(getGreyQualities)
  const { loading: greyColorLoading, greyColor } = useSelector(getGreyColor)


  const data = useMemo(
    () => (greyQuality?.docs ? greyQuality.docs : []),
    [greyQuality])

  const greyColordata = useMemo(
    () => (greyColor?.docs ? greyColor.docs : []),
    [greyColor]
  )

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      manufacturer: '',
      location: '',
      expected_delivery_date: moment(new Date).add(7, 'days'),
      notes: '',
      greyQuality: [
        {
          grey: '',
          greyColor: '',
          grey_unit: '',
          quantity: 0,
        },
      ],
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      location: Yup.string().required(),
      expected_delivery_date: Yup.string(),
      notes: Yup.string(),
      greyQuality: Yup.array()
        .of(
          Yup.object({
            grey: Yup.string().required('Please select grey quality'),
            grey_unit: Yup.string().required(),
            greyColor: Yup.string().required('Grey Color is required'),
            quantity: Yup.number()
              .min(1, 'Please enter minimum one number')
              .required('quantity is required'),
          })
        )
        .required()
        .min(1, 'Please Add atleast one quantity'),
    }),
    onSubmit: async (values) => {
      if (values.greyQuality.length > 0) {
        setProgressLoading(true)
        setTotalItem(values.greyQuality.length)
        values.greyQuality.forEach(async (d, index) => {
          let data = {
            manufacturer: values.manufacturer,
            expected_delivery_date: values.expected_delivery_date,
            location: values.location,
            remaining: d.quantity,
            ...values.greyQuality[index],
          }
          const response = await authAxiosInstance.post(`greyPO`, data)
          if (response.data) {
            setCurrentItem((prevState) => prevState + 1)
          }
        })
      }
      formik.resetForm()
    },
  })

  return (
    <>
      <PageWithCard heading='Add Grey'>
        <div className='text-slate-800 font-semibold mb-4'>Grey Detail</div>
        {/* {loading ? (
                <ClipLoader />
            ) : ( */}
        {progressLoading ? (
          <ProgressBar currentItem={currentItem} totalItem={totalItem} />
        ) : (
          <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4'>
            <FormikSelectGroup
              formik={formik}
              label='Manufacturer'
              name='manufacturer'
              required
              onChange={(data) => {
                dispatch(fetchGreyQualities({ manufacturer: data.value }))
                formik.setFieldValue('manufacturer', data.value)
              }}
              onBlur={formik.handleBlur}
              options={generateOptions({
                array: users ? users.docs : [],
                valueField: '_id',
                labelField: 'name',
              })}
            />
            <FormikInputDateGroup
              label='Expected Delivery Date'
              name='expected_delivery_date'
              formik={formik}
            />
            <FormikSelectGroup
              formik={formik}
              label='Location'
              name='location'
              required
              options={generateOptions({
                array: location ? location.docs : [],
                valueField: '_id',
                labelField: 'name',
              })}
            />
            <FormikTextareaGroup formik={formik} label='Notes' name='notes' />
            {formik.values.manufacturer && (
              <FormikProvider value={formik}>
                <FieldArray
                  name='greyQuality'
                  render={(arrayHelpers) => {
                    return (
                      <div className='flex flex-col gap-2'>
                        {/* <p>Address</p> */}
                        <div>
                          {formik.values.greyQuality.map((ele, index) => (
                            <div
                              className='relative p-4 mb-2'
                              style={{
                                border: '1px solid #d6c7c7',
                                borderRadius: '5px',
                              }}
                              key={index}
                            >
                              <FormikSelectGroup
                                formik={formik}
                                label='Select Quality'
                                onChange={(selectedOption) => {
                                  dispatch(fetchGreyColors({ greyQuality: selectedOption.value }))
                                  formik.setFieldValue(
                                    `greyQuality.${index}.grey`,
                                    selectedOption.value
                                  )
                                  let greyQuality = data.find(
                                    (d) => d._id == selectedOption.value
                                  )
                                  formik.setFieldValue(
                                    `greyQuality.${index}.grey_unit`,
                                    greyQuality.unit
                                  )
                                }}
                                name={`greyQuality.${index}.grey`}
                                options={generateOptions({
                                  array: data,
                                  valueField: '_id',
                                  labelField: 'name',
                                })}
                                required
                              />
                              {formik?.errors?.greyQuality &&
                                Array.isArray(formik?.errors?.greyQuality) &&
                                formik?.errors?.greyQuality[index]?.grey &&
                                formik?.errors?.greyQuality[index]?.grey ? (
                                <p className='text-xs text-red-500'>
                                  {formik.errors['greyQuality'][index].grey}
                                </p>
                              ) : null}
                              {formik.values.greyQuality[index].grey && (
                                <>
                                  <FormikSelectGroup
                                    formik={formik}
                                    label='Select Grey Color'
                                    onChange={(selectedOption) => {
                                      let productExist = formik.values.greyQuality.find(d => d.greyColor == selectedOption.value && d.grey == formik.values.greyQuality[index].grey)
                                      if (productExist) {
                                          return toast.error("Color already exist")
                                      }
                                      formik.setFieldValue(
                                        `greyQuality.${index}.greyColor`,
                                        selectedOption.value
                                      )
                                    }}
                                    name={`greyQuality.${index}.greyColor`}
                                    options={generateOptions({
                                      array: greyColordata,
                                      valueField: '_id',
                                      labelField: 'color',
                                    })}
                                    required
                                  />
                                  {formik?.errors?.greyQuality &&
                                    Array.isArray(formik?.errors?.greyQuality) &&
                                    formik?.errors?.greyQuality[index]
                                      ?.greyColor ? (
                                    <p className='text-xs text-red-500'>
                                      {
                                        formik.errors['greyQuality'][index]
                                          .greyColor
                                      }
                                    </p>
                                  ) : null}

                                  <FormikInputGroup
                                    formik={formik}
                                    label='Unit'
                                    value={
                                      formik.values.greyQuality[index].grey_unit
                                    }
                                    name={`greyQuality.${index}.grey_unit`}
                                    required
                                    readOnly
                                  />
                                  <FormikInputGroup
                                    formik={formik}
                                    label='Quantity'
                                    type='number'
                                    value={
                                      formik.values.greyQuality[index].quantity
                                    }
                                    name={`greyQuality.${index}.quantity`}
                                    required
                                  />
                                  {formik?.errors?.greyQuality &&
                                    Array.isArray(formik?.errors?.greyQuality) &&
                                    formik?.errors?.greyQuality[index]
                                      ?.quantity ? (
                                    <p className='text-xs text-red-500'>
                                      {
                                        formik.errors['greyQuality'][index]
                                          .quantity
                                      }
                                    </p>
                                  ) : null}
                                </>
                              )}
                              <div>
                                <DangerButton
                                  className='mt-3'
                                  onClick={() => {
                                    arrayHelpers.remove(index)
                                  }}
                                  type='button'
                                >
                                  Remove
                                </DangerButton>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div>
                          <SecondaryButton
                            onClick={() => {
                              arrayHelpers.push('')
                            }}
                            type='button'
                          >
                            Add More
                          </SecondaryButton>
                        </div>
                      </div>
                    )
                  }}
                />
                {formik.errors['greyQuality'] &&
                  !Array.isArray(formik.errors['greyQuality']) &&
                  formik.errors['greyQuality'] ? (
                  <p className='text-xs text-red-500'>
                    {formik.errors['greyQuality']}
                  </p>
                ) : null}
              </FormikProvider>
            )}

            <div>
              <PrimaryButton type='submit' onClick={formik.handleSubmit}>
                Submit
              </PrimaryButton>
            </div>
          </form>
        )}
        {/* // )} */}

        {/* <TableWithHeadingAndGlobalSearch
        heading="Grey Quality"
        data={data}
        columns={columns}
        loading={greyLoading}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
      /> */}
      </PageWithCard>
    </>
  )
}

export default GreyPurchaseOrder
