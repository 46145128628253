import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchGreyQualities,
  getGreyQualities,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import { useMemo } from "react";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import moment from "moment";
import { toast } from "react-toastify";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import QueryString from "qs";
import { PurchaseOrderContainer } from "./PurchaseOrderContainer";

const CreatePurchaseOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchGreyQualities());
    dispatch(fetchLocations());
  }, []);
  const locationInfoId = localStorage.getItem("locationInfoId");
  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const { loading: greyLoading, greyQuality } = useSelector(getGreyQualities);
  const { loading: catalogLoading, catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);

  const data = useMemo(() => (catalog?.docs ? catalog.docs : []), [catalog]);
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );
  const expected_delivery_date = moment(new Date()).add(7, "days").valueOf();
  // console.log(currentItem, "index")

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      manufacturer: "",
      location: locationInfoId ? locationInfoId : "",
      //   expected_delivery_date: expected_delivery_date,
      notes: "",
      catalog: [],
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      location: Yup.string().required(),
      expected_delivery_date: Yup.string(),
      notes: Yup.string(),
      // catalog: Yup.array()
      //   .of(
      //     Yup.object({
      //       catalog: Yup.string().required("Please select catalog"),
      //       product: Yup.string().required("Please select color"),
      //       quantity: Yup.number()
      //         .min(1, "Please enter minimum one number")
      //         .required("quantity is required"),
      //     })
      //   )
      //   .required()
      //   .min(1, "Please Add atleast one quantity"),
    }),
    onSubmit: async (values) => {
      if (values.catalog.length > 0) {
        setProgressLoading(true);
        setTotalItem(values.catalog.length);
        values.catalog.forEach(async (d, index) => {
          let data = {
            manufacturer: values.manufacturer,
            expected_delivery_date: values.expected_delivery_date,
            location: values.location,
            remaining: d.quantity,
            ...values.catalog[index],
          };
          const response = await authAxiosInstance.post(`productPO`, data);
          if (response.data) {
            setCurrentItem((prevState) => prevState + 1);
          }
        });
      }
      formik.resetForm();
    },
  });

  return (
    <>
      <PageWithCard heading="Create Purchase Order">
        <div className="text-slate-800 font-semibold mb-4">Purchase Order</div>
        {/* {loading ? (
                <ClipLoader />
            ) : ( */}
        {progressLoading ? (
          <ProgressBar currentItem={currentItem} totalItem={totalItem} />
        ) : (
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
            <FormikSelectGroup
              formik={formik}
              label="Manufacturer"
              name="manufacturer"
              required
              onChange={(data) => {
                dispatch(fetchCatalogs({ manufacturer: data.value }));
                formik.setFieldValue("manufacturer", data.value);
              }}
              onBlur={formik.handleBlur}
              options={generateOptions({
                array: users ? users.docs : [],
                valueField: "_id",
                labelField: "name",
              })}
            />
            <FormikInputDateGroup
              label="Expected Delivery Date"
              name="expected_delivery_date"
              formik={formik}
            />
            <FormikSelectGroup
              formik={formik}
              label="Location"
              name="location"
              required
              options={generateOptions({
                array: location ? location.docs : [],
                valueField: "_id",
                labelField: "name",
              })}
            />
            <FormikTextareaGroup formik={formik} label="Notes" name="notes" />
            {formik.values.manufacturer && (
              <FormikProvider value={formik}>
                <FieldArray
                  name="catalog"
                  render={(arrayHelpers) => {
                    return (
                      <div className="flex flex-col gap-2">
                        {/* <p>Address</p> */}
                        <div>
                          {formik.values.catalog.map((ele, index) => (
                            // <div
                            //   className="relative p-4 mb-2"
                            //   style={{
                            //     border: "1px solid #d6c7c7",
                            //     borderRadius: "5px",
                            //   }}
                            //   key={index}
                            // >
                            //   <FormikAsyncSelect
                            //     formik={formik}
                            //     label="Select Catalog"
                            //     getOptions={async (search) => {
                            //       const resp = await dispatch(
                            //         fetchCatalogs({ search })
                            //       );
                            //       console.log(resp);
                            //       return resp.payload.data.docs.map((ele) => ({
                            //         label: ele.name,
                            //         value: ele._id,
                            //       }));
                            //     }}
                            //     onChange={async (selectedOption) => {
                            //       let id = {
                            //         _id: selectedOption.value,
                            //       };
                            //       const stringify = QueryString.stringify(id);
                            //       const catalogDetail =
                            //         await authAxiosInstance.get(
                            //           `catalog?${stringify}`
                            //         );
                            //       if (
                            //         catalogDetail?.data &&
                            //         catalogDetail.data?.data &&
                            //         catalogDetail.data?.data?.docs.length > 0
                            //       ) {
                            //       }
                            //       dispatch(
                            //         fetchProducts({
                            //           catalog: selectedOption.value,
                            //         })
                            //       );

                            //       formik.setFieldValue(
                            //         `catalog.${index}.catalog`,
                            //         selectedOption.value
                            //       );
                            //     }}
                            //     name={`catalog.${index}.catalog`}
                            //     required
                            //   />

                            //   {/* <FormikSelectGroup
                            //                                     formik={formik}
                            //                                     label='Select Catalog'
                            //                                     onChange={async (selectedOption) => {
                            //                                         dispatch(fetchProducts({ catalog: selectedOption.value }))
                            //                                         formik.setFieldValue(
                            //                                             `catalog.${index}.catalog`,
                            //                                             selectedOption.value
                            //                                         )
                            //                                     }}
                            //                                     name={`catalog.${index}.catalog`}
                            //                                     options={generateOptions({
                            //                                         array: data,
                            //                                         valueField: '_id',
                            //                                         labelField: 'name',
                            //                                     })}
                            //                                     required
                            //                                 /> */}
                            //   {formik?.errors?.catalog &&
                            //   Array.isArray(formik?.errors?.catalog) &&
                            //   formik?.errors?.catalog[index]?.catalog &&
                            //   formik?.errors?.catalog[index]?.catalog ? (
                            //     <p className="text-xs text-red-500">
                            //       {formik.errors["catalog"][index].catalog}
                            //     </p>
                            //   ) : null}
                            //   {formik.values.catalog[index].catalog && (
                            //     <>
                            //       <FormikSelectGroup
                            //         formik={formik}
                            //         label="Select Color"
                            //         name={`catalog.${index}.product`}
                            //         onChange={(selectedOption) => {
                            //           let productExist =
                            //             formik.values.catalog.find(
                            //               (d) =>
                            //                 d.product == selectedOption.value &&
                            //                 d.catalog ==
                            //                   formik.values.catalog[index]
                            //                     .catalog
                            //             );
                            //           if (productExist) {
                            //             return toast.error(
                            //               "Product already exist"
                            //             );
                            //           }
                            //           formik.setFieldValue(
                            //             `catalog.${index}.product`,
                            //             selectedOption.value
                            //           );
                            //         }}
                            //         options={generateOptions({
                            //           array: productData,
                            //           valueField: "_id",
                            //           labelField: "color",
                            //         })}
                            //         required
                            //       />
                            //       {formik?.errors?.catalog &&
                            //       Array.isArray(formik?.errors?.catalog) &&
                            //       formik?.errors?.catalog[index]?.product &&
                            //       formik?.errors?.catalog[index]?.product ? (
                            //         <p className="text-xs text-red-500">
                            //           {formik.errors["catalog"][index].product}
                            //         </p>
                            //       ) : null}
                            //       <FormikInputGroup
                            //         formik={formik}
                            //         label="Quantity"
                            //         type="number"
                            //         value={
                            //           formik.values.catalog[index].quantity
                            //         }
                            //         name={`catalog.${index}.quantity`}
                            //         required
                            //       />
                            //       {formik?.errors?.catalog &&
                            //       Array.isArray(formik?.errors?.catalog) &&
                            //       formik?.errors?.catalog[index]?.quantity ? (
                            //         <p className="text-xs text-red-500">
                            //           {formik.errors["catalog"][index].quantity}
                            //         </p>
                            //       ) : null}
                            //     </>
                            //   )}
                            //   <div>
                            //     <DangerButton
                            //       className="mt-3"
                            //       onClick={() => {
                            //         arrayHelpers.remove(index);
                            //       }}
                            //       type="button"
                            //     >
                            //       Remove
                            //     </DangerButton>
                            //   </div>
                            // </div>

                            <PurchaseOrderContainer
                              key={index}
                              formik={formik}
                              arrayHelpers={arrayHelpers}
                              index={index}
                            />
                          ))}
                        </div>
                        <div>
                          <SecondaryButton
                            onClick={() => {
                              arrayHelpers.push({
                                catalog: ""
                              });
                            }}
                            type="button"
                          >
                            Add More
                          </SecondaryButton>
                        </div>
                      </div>
                    );
                  }}
                />
                {formik.errors["catalog"] &&
                  !Array.isArray(formik.errors["catalog"]) &&
                  formik.errors["catalog"] ? (
                  <p className="text-xs text-red-500">
                    {formik.errors["catalog"]}
                  </p>
                ) : null}
              </FormikProvider>
            )}

            <div>
              <PrimaryButton type="submit" onClick={formik.handleSubmit}>
                Submit
              </PrimaryButton>
            </div>
          </form>
        )}
      </PageWithCard>
    </>
  );
};

export default CreatePurchaseOrder;
