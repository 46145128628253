import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { generateOptions } from "../../utils/Utils";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import { fetchCatalogs } from "../../app/reducers/Catalog/catalogSlice";
import { toast } from "react-toastify";
import QueryString from "qs";
import { Minus, Plus } from "react-feather";
import customId from "custom-id";
import { DATA_TYPE } from "../../utils/dropdownOptions";

const Return = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const [errorData, setErrorData] = useState([]);

  const locationInfoId = localStorage.getItem("locationInfoId");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchCatalogs());
  }, []);
  const { location } = useSelector(getLocation);

  const increment = async (e, i) => {
    e.stopPropagation();
    let data = formik.values.product[i];
    console.log("DAta", data);
    const stringSale = QueryString.stringify({
      "product.product_id": data._id,
      customerId: formik.values.customerId,
    });
    const saleCheck = await authAxiosInstance.get(`sale?${stringSale}`);
    console.log(saleCheck, "saleCheck");
    const string = QueryString.stringify({
      search: data.sku,
      populate: true,
    });
    const resp = await authAxiosInstance.get(`product?${string}`);
    const product = resp.data.data.docs[0];
    if (
      saleCheck?.data?.data?.docs.length <= 0 &&
      formik.values.dataType === "new"
    ) {
      return alert("Sale Not exist for customer");
    }
    console.log(data, "stock");
    const stringInv = QueryString.stringify({
      product: data.id,
      location: formik.values.location,
    });
    const invRsp = await authAxiosInstance.get(`productInventory?${stringInv}`);
    const stock = invRsp.data.data.docs[0];
    console.log(stock, "stock");
    if (stock?.sold <= 0 && formik.values.dataType === "new") {
      return alert("No Sales Remaining");
    }
    if (!stock) {
      return alert("No Stock Entry Found");
    }
    let incQty = data.quantity + 1;
    if (stock?.sold < incQty && formik.values.dataType === "new") {
      return alert("Return Exceeding Sale");
    }
    formik.setFieldValue(`product.${i}.quantity`, incQty);
    formik.setFieldValue(
      `product.${i}.amount`,
      product.catalogData.price * incQty
    );
  };

  const decrement = async (e, i) => {
    e.stopPropagation();
    let data = formik.values.product[i];
    if (data.quantity == 1) {
      window.alert("Not allowed");
    } else {
      const string = QueryString.stringify({
        search: data.sku,
        populate: true,
      });
      const resp = await authAxiosInstance.get(`product?${string}`);
      const product = resp.data.data.docs[0];
      let incQty = data.quantity - 1;
      formik.setFieldValue(`product.${i}.quantity`, incQty);
      formik.setFieldValue(
        `product.${i}.amount`,
        product.catalogData.price * incQty
      );
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: locationInfoId ? locationInfoId : "",
      customerId: "",
      dataType: "",
      product: [""],
    },
    validationSchema: Yup.object({
      location: Yup.string().required(),
      customerId: Yup.string().required(),
      dataType: Yup.string().required(),
      product: Yup.array()
        .of(Yup.object({}))
        .required()
        .min(1, "Please Add atleast one product"),
    }),
    onSubmit: async (values) => {
      console.log(values, "check");
      let filterProduct = values.product?.filter((d) =>
        d?.sku ? d.sku : d?.catalog
      );
      let referenceId = customId({});
      console.log(filterProduct, "checking nowa");
      if (filterProduct.length > 0) {
        setProgressLoading(true);
        setTotalItem(filterProduct.length);
        for (let i = 0; i < filterProduct.length; i++) {
          try {
            let data = {
              catalog: filterProduct[i].catalog,
              product: filterProduct[i].product,
              quantity: filterProduct[i].quantity,
              customerId: values.customerId,
              amount: filterProduct[i].amount,
              location: values.location,
              refId: referenceId,
              type: "Barcode",
              dataType: values.dataType,
            };
            const response = await authAxiosInstance.post(`return`, data);
            if (response.data) {
              setCurrentItem((prevState) => prevState + 1);
            }
          } catch (error) {
            console.log(error, "new error hu mei");
            setCurrentItem((prevState) => prevState + 1);
            setErrorData((prev) => [
              ...prev,
              {
                ...filterProduct[i],
                error: JSON.stringify(error.response?.data),
              },
            ]);
          }
        }
      } else {
        return alert("Please add atleast one product");
      }
      formik.resetForm();
    },
  });

  const onKeyPressBarcode = async (event, i, arrayHelpers) => {
    event.stopPropagation();
    if (event.keyCode === 13 && event.target.value) {
      let checkExist = formik.values.product?.find(
        (d) => d.sku == event.target.value
      );
      const string = QueryString.stringify({
        search: event.target.value,
        populate: true,
      });
      const resp = await authAxiosInstance.get(`product?${string}`);
      const product = resp.data.data.docs[0];
      if (!product) {
        alert("Product Not found");
      }
      const stringSale = QueryString.stringify({
        "product.product_id": product._id,
        customerId: formik.values.customerId,
      });
      const saleCheck = await authAxiosInstance.get(`sale?${stringSale}`);
      console.log(saleCheck, "saleCheck");
      if (
        saleCheck?.data?.data?.docs.length <= 0 &&
        formik.values.dataType === "new"
      ) {
        return alert("Sale Not exist for customer");
      }
      const stringInv = QueryString.stringify({
        product: product._id,
        location: formik.values.location,
      });
      const invRsp = await authAxiosInstance.get(
        `productInventory?${stringInv}`
      );
      const stock = invRsp.data.data.docs[0];
      if (stock?.sold <= 0 && formik.values.dataType === "new") {
        return alert("Return Quantity Exeeded Sale");
      }
      if (!stock) {
        return alert("Stock not found");
      }
      if (checkExist) {
        let incQty = checkExist.quantity + 1;
        // formik.setFieldValue(
        //   `product.${i}.amount`,
        //   product.catalogData.price * incQty
        // );
        // console.log(
        //   "pamount",
        //   product.catalogData.price * incQty,
        //   `product.${i}.amount`
        // );
        console.log(stock?.finished, incQty, "new hu");
        if (stock?.sold < incQty && formik.values.dataType === "new") {
          return alert("No more sales");
        }
        let productIndex = formik.values.product?.findIndex(
          (d) => d.sku == event.target.value
        );
        formik.setFieldValue(`product.${productIndex}.quantity`, incQty);
        formik.setFieldValue(
          `product.${productIndex}.amount`,
          product.catalogData.price * incQty
        );
        formik.setFieldValue(`product.${i}.id`, "");

        return toast.success("Qty updated on product");
      }
      console.log(product, "dddd");
      formik.setFieldValue(`product.${i}.sku`, product.sku);
      formik.setFieldValue(`product.${i}.catalog`, product.catalogData._id);
      formik.setFieldValue(
        `product.${i}.catalogName`,
        product.catalogData.name
      );
      formik.setFieldValue(`product.${i}.product`, product._id);
      formik.setFieldValue(`product.${i}.productName`, product.color);
      formik.setFieldValue(`product.${i}.id`, product._id);
      formik.setFieldValue(`product.${i}.location`, formik.values.location);
      formik.setFieldValue(`product.${i}.quantity`, 1);
      formik.setFieldValue(`product.${i}.amount`, product.catalogData.price);
      arrayHelpers.push("");
    }
  };

  return (
    <>
      <PageWithCard heading="Return Barcode">
        {progressLoading ? (
          <ProgressBar
            currentItem={currentItem}
            totalItem={totalItem}
            errorData={errorData}
          />
        ) : (
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
            <FormikSelectGroup
              formik={formik}
              label="Select Type"
              name="dataType"
              onChange={(selectedOption) => {
                formik.resetForm();
                formik.setFieldValue("dataType", selectedOption.value);
              }}
              required
              options={generateOptions({
                array: DATA_TYPE,
                valueField: "value",
                labelField: "label",
              })}
            />
            {formik.values.dataType && (
              <>
                <FormikAsyncSelect
                  name="customerId"
                  label="Customer"
                  formik={formik}
                  getOptions={async (value) => {
                    const query = {
                      search: value,
                      role: "customer",
                      populate: true,
                    };
                    const string = QueryString.stringify(query);
                    const users = await authAxiosInstance.get(`user?${string}`);
                    const options = users.data.data.docs.map((ele) => ({
                      label: `${ele.name} / ${ele.username}`,
                      value: ele._id,
                    }));
                    return options;
                  }}
                  onChange={async (selectedOption) => {
                    const string = QueryString.stringify({
                      _id: selectedOption.value,
                      populate: true,
                    });
                    const stringCredit = QueryString.stringify({
                      userId: selectedOption.value,
                      populate: true,
                    });
                    const resp = await authAxiosInstance.get(`user?${string}`);
                    const userCredit = await authAxiosInstance.get(
                      `user/get/credit?${stringCredit}`
                    );
                    console.log(userCredit, "userCredit");
                    console.log(resp, "ne hj");
                    const user = resp.data.data.docs[0];
                    const userBalance = userCredit.data.data.userCredit;
                    formik.setFieldValue("customerId", user._id);
                    formik.setFieldValue("customerName", user.name);
                    formik.setFieldValue("customerPhone", user.username);
                    formik.setFieldValue("customerCredit", userBalance);
                  }}
                />
                <div className="flex gap-4 w-full">
                  <FormikInputGroup
                    name="customerName"
                    formik={formik}
                    label="Customer Name"
                    readOnly
                    fullWidth
                  />
                  <FormikInputGroup
                    name="customerPhone"
                    formik={formik}
                    label="Customer Phone"
                    readOnly
                    fullWidth
                  />
                  <FormikInputGroup
                    name="customerCredit"
                    formik={formik}
                    label="Customer Credit"
                    readOnly
                    fullWidth
                  />
                </div>

                <FormikSelectGroup
                  formik={formik}
                  label="Location"
                  name="location"
                  onChange={(selectedOption) => {
                    formik.setFieldValue("product", [""]);
                    formik.setFieldValue("location", selectedOption.value);
                  }}
                  required
                  options={generateOptions({
                    array: location ? location.docs : [],
                    valueField: "_id",
                    labelField: "name",
                  })}
                />

                {formik.values.location && formik.values.customerId && (
                  <>
                    <FormikProvider value={formik}>
                      <FieldArray
                        name="product"
                        render={(arrayHelpers) => {
                          return (
                            <div className="flex flex-col gap-2">
                              {/* <p>Address</p> */}
                              <div>
                                {formik.values.product.map((ele, index) => (
                                  <div
                                    className="relative p-4 mb-2"
                                    style={{
                                      border: "1px solid #d6c7c7",
                                      borderRadius: "5px",
                                    }}
                                    key={index}
                                  >
                                    {!formik.values.product[index].sku && (
                                      <FormikInputGroup
                                        autoFocus={true}
                                        formik={formik}
                                        label={`Barcode${index + 1}`}
                                        name={`product.${index}.id`}
                                        onKeyDown={(e) =>
                                          onKeyPressBarcode(
                                            e,
                                            index,
                                            arrayHelpers
                                          )
                                        }
                                      />
                                    )}
                                    {formik.values.product[index].sku && (
                                      <div className="flex gap-3">
                                        <FormikInputGroup
                                          formik={formik}
                                          label={`sku`}
                                          name={`product.${index}.sku`}
                                          readOnly
                                        />
                                        <FormikInputGroup
                                          formik={formik}
                                          label={`Catalog`}
                                          name={`product.${index}.catalogName`}
                                          readOnly
                                        />
                                        <FormikInputGroup
                                          formik={formik}
                                          label={`Color`}
                                          name={`product.${index}.productName`}
                                          readOnly
                                        />
                                        <Minus
                                          onClick={(e) => decrement(e, index)}
                                          style={{ marginTop: "25px" }}
                                        />
                                        <FormikInputGroup
                                          formik={formik}
                                          label={`Qty`}
                                          name={`product.${index}.quantity`}
                                          readOnly
                                        />
                                        <Plus
                                          onClick={(e) => increment(e, index)}
                                          style={{ marginTop: "25px" }}
                                        />
                                        <FormikInputGroup
                                          min={0}
                                          formik={formik}
                                          label={`Amount`}
                                          type="number"
                                          name={`product.${index}.amount`}
                                        />
                                      </div>
                                    )}
                                    <div>
                                      <DangerButton
                                        className="mt-3"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                        type="button"
                                      >
                                        Remove
                                      </DangerButton>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {formik.values?.product?.length <= 1 && (
                                <div>
                                  <SecondaryButton
                                    onClick={() => {
                                      arrayHelpers.push("");
                                    }}
                                    type="button"
                                  >
                                    Add More
                                  </SecondaryButton>
                                </div>
                              )}
                            </div>
                          );
                        }}
                      />
                      {formik.errors["product"] &&
                      !Array.isArray(formik.errors["product"]) &&
                      formik.errors["product"] ? (
                        <p className="text-xs text-red-500">
                          {formik.errors["product"]}
                        </p>
                      ) : null}
                    </FormikProvider>
                  </>
                )}
              </>
            )}
            <div>
              <PrimaryButton type="button" onClick={formik.handleSubmit}>
                Submit
              </PrimaryButton>
            </div>
          </form>
        )}
      </PageWithCard>
    </>
  );
};

export default Return;
