import React from "react";

const PrimaryButton = ({ children, className, type = "button", ...props }) => {
  return (
    <button
      className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ${className}`}
      {...props}
      type={type}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
