import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../partials/Header";
import Sidebar from "../../partials/SideBar/Sidebar";
import { useDispatch } from "react-redux";
import { fetchAssets } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";

const RouteWithHeaderAndSidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAssets({ currentFolder: "" }));
  }, []);
  return (
    <div className="flex h-screen overflow-hidden">
      {" "}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />{" "}
      {/*  Site header */}
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="relative">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default RouteWithHeaderAndSidebar;
