import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "finishedDamage";

export const fetchTransferToDamage = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const transferToDamageSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchTransferToDamage, SLICE_NAME),
  },
});

export const getTransferToDamage = (state) => state.transferToDamage;
export const { setEditId } = transferToDamageSlice.actions;
export default transferToDamageSlice.reducer;
