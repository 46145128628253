import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/Auth/authSlice";
import CustomerSlice from "./reducers/Customers/CustomerSlice";
import userSlice from "./reducers/Users/userSlice";
import greyQualitySlice from "./reducers/GreyQuality/greyQualitySlice";
import greyPurchaseOrderSlice from "./reducers/GreyPurchaseOrder/greyPOSlice";
import locationSlice from "./reducers/Location/locationSlice";
import jobWorkSlice from "./reducers/JobWork/jobWorkSlice";
import catalogSlice from "./reducers/Catalog/catalogSlice";
import productSlice from "./reducers/Product/productSlice";
import categorySlice from "./reducers/Category/categorySlice";
import printPurchaseSlice from "./reducers/PrintPurchaseOrder/printPurchaseSlice";
import purchaseOrderSlice from "./reducers/PurchaseOrder/purchaseOrderSlice";
import jobWorkPOSlice from "./reducers/SendJobWork/SendJobWork";
import cashbookSlice from "./reducers/Cashbook/cashbookSlice";
import expenseCategorySlice from "./reducers/ExpenseCategory/expenseCategorySlice";
import embroiderySlice from "./reducers/Embroidery/embroiderySlice";
import directPOSlice from "./reducers/DirectPO/DirectPOSlice";
import saleSlice from "./reducers/Sale/saleSlice";
import creditBookSlice from "./reducers/CreditBook/creditBookSlice";
import stockTransferSlice from "./reducers/StockTransfer/stockTransferSlice";
import greyColorSlice from "./reducers/GreyColor/greyColorSlice";
import greyEmbroiderySlice from "./reducers/GreyEmbroidery/greyEmbroiderySlice";
import stockAdjustmentSlice from "./reducers/StockAdjustment/stockAdjustmentSlice";
import unfinishedSlice from "./reducers/Unfinished/unfinishedSlice";
import mediaLibrarySlice from "./reducers/MediaLibrary/mediaLibrarySlice";
import transferToDamageSlice from "./reducers/TransferToDamage/transferToDamageSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: CustomerSlice,
    users: userSlice,
    greyQuality: greyQualitySlice,
    greyColor: greyColorSlice,
    greyEmbroidery: greyEmbroiderySlice,
    greyPurchaseOrder: greyPurchaseOrderSlice,
    location: locationSlice,
    jobWork: jobWorkSlice,
    catalog: catalogSlice,
    product: productSlice,
    categories: categorySlice,
    printPO: printPurchaseSlice,
    productPO: purchaseOrderSlice,
    jobWorkPO: jobWorkPOSlice,
    cashbook: cashbookSlice,
    expenseCategory: expenseCategorySlice,
    embroideryPO: embroiderySlice,
    directPO: directPOSlice,
    sale: saleSlice,
    creditBook: creditBookSlice,
    stockTransfer: stockTransferSlice,
    stockAdjustment: stockAdjustmentSlice,
    unfinished: unfinishedSlice,
    mediaLibrary: mediaLibrarySlice,
    transferToDamage: transferToDamageSlice,
  },
});
