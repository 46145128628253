import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchGreyQualities,
  getGreyQualities,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import { useMemo } from "react";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { createDirectPO } from "../../app/reducers/DirectPO/DirectPOSlice";
import { X } from "react-feather";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import DirectFinishedContainer from "./DirectFinishedContainer";

const DirectFinishedOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchGreyQualities());
    dispatch(fetchLocations());
  }, []);

  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const { loading: greyLoading, greyQuality } = useSelector(getGreyQualities);
  const { loading: catalogLoading, catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);

  const data = useMemo(() => (catalog?.docs ? catalog.docs : []), [catalog]);
  const locationInfoId = localStorage.getItem("locationInfoId");
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );
  // console.log(currentItem, "index")

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      manufacturer: "",
      location: locationInfoId,
      products: [],
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      location: Yup.string().required(),
      products: Yup.array()
        .of(Yup.object({}))
        .required()
        .min(1, "Please Add atleast one product"),
    }),
    onSubmit: async (values) => {
      console.log(values, "check");
      setProgressLoading(true);
      setTotalItem(formik.values.products.length);
      for (let i = 0; i < formik.values.products.length; i++) {
        const product = formik.values.products[i];
        const data = {
          catalog: product.catalog,
          location: values.location,
          manufacturer: values.manufacturer,
          product: product.product,
          quantity: product.quantity,
        };
        await dispatch(createDirectPO({ ...data, type: "finished" }));
        setCurrentItem((prevState) => prevState + 1);
      }

      formik.resetForm();
    },
  });

  return (
    <>
      <PageWithCard heading="Direct Finished Order">
        <div className="text-slate-800 font-semibold mb-4">Purchase Order</div>
        {/* {loading ? (
                <ClipLoader />
            ) : ( */}
        {progressLoading ? (
          <ProgressBar currentItem={currentItem} totalItem={totalItem} />
        ) : (
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
            <FormikSelectGroup
              formik={formik}
              label="Manufacturer"
              name="manufacturer"
              required
              onChange={(data) => {
                dispatch(fetchCatalogs({ manufacturer: data.value }));
                formik.setFieldValue("manufacturer", data.value);
              }}
              onBlur={formik.handleBlur}
              options={generateOptions({
                array: users ? users.docs : [],
                valueField: "_id",
                labelField: "name",
              })}
            />

            {formik.values.manufacturer && (
              <>
                <FormikSelectGroup
                  formik={formik}
                  label="Location"
                  name="location"
                  required
                  options={generateOptions({
                    array: location ? location.docs : [],
                    valueField: "_id",
                    labelField: "name",
                  })}
                />
                {/* <FormikSelectGroup
                  formik={formik}
                  label="Select Catalog"
                  name={`catalog`}
                  onChange={async (selectedOption) => {
                    dispatch(
                      fetchProducts({
                        catalog: selectedOption.value,
                      })
                    );
                    formik.setFieldValue(`catalog`, selectedOption.value);
                  }}
                  options={generateOptions({
                    array: data,
                    valueField: "_id",
                    labelField: "name",
                  })}
                  required
                /> */}
                <FormikProvider value={formik}>
                  <FieldArray
                    name="products"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {formik.values.products.map((ele, i) => (
                            // <div
                            //   className="border border-black p-4 flex flex-col gap-4"
                            //   key={i}
                            // >
                            //   <FormikAsyncSelect
                            //     formik={formik}
                            //     label="Select Catalog"
                            //     getOptions={async (search) => {
                            //       const resp = await dispatch(
                            //         fetchCatalogs({ search, manufacturer: formik.values.manufacturer })
                            //       );
                            //       return resp.payload.data.docs.map((ele) => {
                            //         return {
                            //           label: ele.name,
                            //           value: ele._id
                            //         }
                            //       });
                            //     }}
                            //     onChange={async (selectedOption) => {
                            //       dispatch(fetchProducts({ catalog: selectedOption.value }))
                            //       formik.setFieldValue(
                            //         `products.${i}.catalog`,
                            //         selectedOption.value
                            //       )
                            //     }}
                            //     name={`products.${i}.catalog`}
                            //     required
                            //   />
                            //   {formik.values.products[i].catalog &&
                            //     <>
                            //       <FormikSelectGroup
                            //         formik={formik}
                            //         label="Select Color"
                            //         name={`products.${i}.product`}
                            //         onChange={(option) => {
                            //           const selectedProducts =
                            //             formik.values.products.map(
                            //               (ele) => ele.product
                            //             );
                            //           if (selectedProducts.includes(option.value)) {
                            //             alert("Product Already Selected");
                            //           } else {
                            //             formik.setFieldValue(
                            //               `products.${i}.product`,
                            //               option.value
                            //             );
                            //           }
                            //         }}
                            //         options={generateOptions({
                            //           array: productData,
                            //           valueField: "_id",
                            //           labelField: "color",
                            //         })}
                            //         required
                            //       />
                            //       <FormikInputGroup
                            //         formik={formik}
                            //         label="Quantity"
                            //         type="number"
                            //         name={`products.${i}.quantity`}
                            //         required
                            //       />
                            //     </>
                            //   }
                            //   <div>
                            //     <DangerButton
                            //       type="button"
                            //       onClick={() => {
                            //         arrayHelpers.remove(i);
                            //       }}
                            //     >
                            //       Remove
                            //     </DangerButton>
                            //   </div>
                            // </div>
                            <DirectFinishedContainer
                              key={i}
                              formik={formik}
                              arrayHelpers={arrayHelpers}
                              i={i}
                            />
                          ))}
                          <SecondaryButton
                            onClick={() => {
                              arrayHelpers.push({
                                product: "",
                                quantity: 0,
                              });
                            }}
                            type="button"
                          >
                            Add More
                          </SecondaryButton>
                        </>
                      );
                    }}
                  />
                </FormikProvider>
              </>
            )}
            <div>
              {formik.values.products.length > 0 && (
                <PrimaryButton type="submit" onClick={formik.handleSubmit}>
                  Submit
                </PrimaryButton>
              )}
            </div>
          </form>
        )}
      </PageWithCard>
    </>
  );
};

export default DirectFinishedOrder;
