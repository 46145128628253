import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import { useMemo } from "react";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import {
  fetchJobWorks,
  getJobWork,
} from "../../app/reducers/JobWork/jobWorkSlice";
import moment from "moment";
import { toast } from "react-toastify";
import QueryString from "qs";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import { JobWorkContainer } from "./JobWorkContainer";
import { CATALOG_TYPES } from "../../utils/dropdownOptions";

const CreateJobWork = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchUsers({ role: "job_work", __t: "vendor" }));
    dispatch(fetchLocations());
    dispatch(fetchJobWorks());
  }, []);
  const locationInfoId = localStorage.getItem("locationInfoId");
  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const { loading: catalogLoading, catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);
  const { loading: jobworkloading, jobWork } = useSelector(getJobWork);
  const [errorData, setErrorData] = useState([]);

  const data = useMemo(() => (catalog?.docs ? catalog.docs : []), [catalog]);
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );
  const jobWorkData = useMemo(
    () => (jobWork?.docs ? jobWork.docs : []),
    [jobWork]
  );

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      manufacturer: "",
      catalogType: "",
      location: locationInfoId ? locationInfoId : "",
      expected_delivery_date: moment(new Date()).add(7, "days"),
      notes: "",
      catalog: [
        {
          catalog: "",
          product: "",
          quantity: 0,
        },
      ],
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      location: Yup.string().required(),
      expected_delivery_date: Yup.string(),
      catalogType: Yup.string().required(),
      notes: Yup.string(),
      catalog: Yup.array()
        .of(
          Yup.object({
            catalog: Yup.string().required("Please select catalog"),
            product: Yup.string().required("Please select color"),
            quantity: Yup.number()
              .min(1, "Please enter minimum one number")
              .required("quantity is required"),
          })
        )
        .required()
        .min(1, "Please Add atleast one quantity"),
    }),
    onSubmit: async (values) => {
      if (values.catalog.length > 0) {
        setProgressLoading(true);
        setTotalItem(values.catalog.length);
        for (let i = 0; i < values.catalog.length; i++) {
          try {
            let data = {
              manufacturer: values.manufacturer,
              expected_delivery_date: values.expected_delivery_date,
              location: values.location,
              remaining: values.catalog[i].quantity,
              catalogType: values.catalogType,
              ...values.catalog[i],
            };
            const response = await authAxiosInstance.post(`jobWorkPO`, data);
            if (response.data) {
              setCurrentItem((prevState) => prevState + 1);
            }
          } catch (error) {
            console.log(error, "new error hu mei");
            setCurrentItem((prevState) => prevState + 1);
            setErrorData((prev) => [
              ...prev,
              {
                ...values.catalog[i],
                error: JSON.stringify(error.response?.data),
              },
            ]);
          }
        }
      }
      formik.resetForm();
    },
  });

  return (
    <>
      <PageWithCard heading="Create Job Work">
        <div className="text-slate-800 font-semibold mb-4">Purchase Order</div>
        {/* {loading ? (
                <ClipLoader />
            ) : ( */}
        {progressLoading ? (
          <ProgressBar
            currentItem={currentItem}
            totalItem={totalItem}
            errorData={errorData}
          />
        ) : (
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
            <FormikSelectGroup
              formik={formik}
              label="Catalog Type"
              name="catalogType"
              required
              onBlur={formik.handleBlur}
              onChange={(data) => {
                formik.resetForm();
                formik.setFieldValue("catalogType", data.value);
              }}
              options={generateOptions({
                array: CATALOG_TYPES,
                valueField: "value",
                labelField: "label",
              })}
            />
            {formik.values.catalogType && (
              <>
                <FormikSelectGroup
                  formik={formik}
                  label="Manufacturer"
                  name="manufacturer"
                  required
                  onChange={(data) => {
                    dispatch(
                      fetchCatalogs({
                        isEmbroidery:
                          formik.values.catalogType == "normal" ? false : true,
                      })
                    );
                    formik.setFieldValue("manufacturer", data.value);
                  }}
                  onBlur={formik.handleBlur}
                  options={generateOptions({
                    array: users ? users.docs : [],
                    valueField: "_id",
                    labelField: "name",
                  })}
                />
                <FormikInputDateGroup
                  label="Expected Delivery Date"
                  name="expected_delivery_date"
                  formik={formik}
                />
                <FormikSelectGroup
                  formik={formik}
                  label="Location"
                  name="location"
                  required
                  options={generateOptions({
                    array: location ? location.docs : [],
                    valueField: "_id",
                    labelField: "name",
                  })}
                />
                <FormikTextareaGroup
                  formik={formik}
                  label="Notes"
                  name="notes"
                />
                {formik.values.manufacturer && (
                  <FormikProvider value={formik}>
                    <FieldArray
                      name="catalog"
                      render={(arrayHelpers) => {
                        return (
                          <div className="flex flex-col gap-2">
                            {/* <p>Address</p> */}
                            <div>
                              {formik.values.catalog.map((ele, index) => (
                                <JobWorkContainer
                                  index={index}
                                  formik={formik}
                                  arrayHelpers={arrayHelpers}
                                  key={index}
                                  jobWorkData={jobWorkData}
                                />
                              ))}
                            </div>
                            <div>
                              <SecondaryButton
                                onClick={() => {
                                  arrayHelpers.push("");
                                }}
                                type="button"
                              >
                                Add More
                              </SecondaryButton>
                            </div>
                          </div>
                        );
                      }}
                    />
                    {formik.errors["catalog"] &&
                    !Array.isArray(formik.errors["catalog"]) &&
                    formik.errors["catalog"] ? (
                      <p className="text-xs text-red-500">
                        {formik.errors["catalog"]}
                      </p>
                    ) : null}
                  </FormikProvider>
                )}

                <div>
                  <PrimaryButton type="submit" onClick={formik.handleSubmit}>
                    Submit
                  </PrimaryButton>
                </div>
              </>
            )}
          </form>
        )}
      </PageWithCard>
    </>
  );
};

export default CreateJobWork;
