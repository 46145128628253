import React, { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import { fetchCatalogs } from "../../app/reducers/Catalog/catalogSlice";
import { toast } from "react-toastify";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import { useDispatch } from "react-redux";
import { generateOptions } from "../../utils/Utils";

const DirectFinishedContainer = ({ formik, i, arrayHelpers }) => {
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  return (
    <div className="border border-black p-4 flex flex-col gap-4">
      <FormikAsyncSelect
        formik={formik}
        label="Select Catalog"
        getOptions={async (search) => {
          console.log(search);
          const resp = await dispatch(
            fetchCatalogs({ search, manufacturer: formik.values.manufacturer })
          );
          console.log(resp);
          return resp.payload.data.docs.map((ele) => {
            return {
              label: ele.name,
              value: ele._id,
            };
          });
        }}
        onChange={async (selectedOption) => {
          try {
            const string = QueryString.stringify({
              catalog: selectedOption.value,
            });
            const resp = await authAxiosInstance.get(`${"product"}?${string}`);
            setProducts(resp.data.data.docs);
            formik.setFieldValue(`products.${i}.catalog`, selectedOption.value);
          } catch (error) {
            console.error(error);
            if (error?.response?.data?.message) {
              toast.error(error?.response?.data?.message);
            }
            toast.error("failed to fetch data");
          }
        }}
        name={`products.${i}.catalog`}
        required
      />
      {formik.values.products[i].catalog && (
        <>
          <FormikSelectGroup
            formik={formik}
            label="Select Color"
            name={`products.${i}.product`}
            onChange={(option) => {
              const selectedProducts = formik.values.products.map(
                (ele) => ele.product
              );
              if (selectedProducts.includes(option.value)) {
                alert("Product Already Selected");
              } else {
                formik.setFieldValue(`products.${i}.product`, option.value);
              }
            }}
            options={generateOptions({
              array: products,
              valueField: "_id",
              labelField: "color",
            })}
            required
          />
          <FormikInputGroup
            formik={formik}
            label="Quantity"
            type="number"
            name={`products.${i}.quantity`}
            required
          />
        </>
      )}
      <div>
        <DangerButton
          type="button"
          onClick={() => {
            arrayHelpers.remove(i);
          }}
        >
          Remove
        </DangerButton>
      </div>
    </div>
  );
};

export default DirectFinishedContainer;
