import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "creditBook";

export const fetchCreditBooks = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editCreditBook = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCreditBook = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteCreditBook = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createCreditBook = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const getCreditBookSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchCreditBooks, SLICE_NAME),
    ...createData.generateExtraReducers(createCreditBook, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteCreditBook, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchCreditBook, SLICE_NAME),
    ...editData.generateExtraReducers(fetchEditData, SLICE_NAME)
  },
});

export const getCreditBook = (state) => state.creditBook;
export const { setEditId } = getCreditBookSlice.actions;
export default getCreditBookSlice.reducer;
