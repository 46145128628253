import { FieldArray, FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import EditModal from "../../components/infrastructure/Modals/EditModal";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { getProduct, setEditId, fetchProduct, fetchProducts, editProductWithFile } from "../../app/reducers/Product/productSlice";
import { useMemo } from "react";
import FormikFileInput from "../../components/formik/FormikFileInput";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";

const EditProductModal = (props) => {
  const { catalogId } = props
  const dispatch = useDispatch();
  const {
    elementEditId,
    editDataLoading,
    elementEditData,
  } = useSelector(getProduct);
  const {
    product, loading: productLoading
  } = useSelector(getProduct);
  const data = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  )
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
    },
    onSubmit: async (values) => {
      let updateData = {
        _id: values._id,
        color: values.color
      }
      if(values.image) {
        updateData['image'] = values.image
      }
      await dispatch(editProductWithFile({ ...updateData }));
      dispatch(fetchProducts({ catalog: catalogId, populate: true }))
    },
  });
  useEffect(() => {
    if (elementEditId) {
      dispatch(fetchProduct({ _id: elementEditId, populate: true }));
    }
  }, [elementEditId]);
  return (
    <EditModal
      editProperty={elementEditId}
      setEditProperty={setEditId}
      getEditData={fetchProduct}
      title="Edit Catalog"
    >
      {editDataLoading ? (
        <ClipLoader />
      ) : (

        <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4 p-4'>
          {
            productLoading && <ClipLoader />
          }
          <FormikInputGroup
            label="SystemId"
            name="_id"
            formik={formik}
            required
            readOnly
          />
          <FormikInputGroup label='Color' formik={formik} name='color' required />
          <FormikDirectFileUpload
            name={`image`}
            formik={formik}
            label="Image"
            location={`trendy_sarees2/catalogs/${formik.values?.catalogData?.name}/`}
          />
          {/* <FormikFileInput name='image' formik={formik} label='Image' /> */}
          <div>
            <PrimaryButton type='submit'>Submit</PrimaryButton>
          </div>
        </form>
      )}
    </EditModal>
  );
};

export default EditProductModal;
