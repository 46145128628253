import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "greyEmbroidery";

export const fetchGreyEmbroideries = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editGreyEmbroidery = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchGreyEmbroidery = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteGreyEmbroidery = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createGreyEmbroidery = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const greyEmbroiderySlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchGreyEmbroideries, SLICE_NAME),
    ...createData.generateExtraReducers(createGreyEmbroidery, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteGreyEmbroidery, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchGreyEmbroidery, SLICE_NAME),
    ...editData.generateExtraReducers(editGreyEmbroidery, SLICE_NAME),
  },
});

export const getGreyEmbroidery = (state) => state.greyEmbroidery;
export const { setEditId } = greyEmbroiderySlice.actions;
export default greyEmbroiderySlice.reducer;
