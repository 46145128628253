export const generateSetEditId = (element) => {
  return (state, action) => {
    state.elementEditId = action.payload;
  };
};

export const generateReset = (slice) => {
  return (state, action) => {
    state[slice] = action.payload;
  };
};
