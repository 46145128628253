import { useFormik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import FormikInputGroup from '../../components/formik/FormikInputGroup'
import FormikCheckbox from '../../components/formik/FormikCheckbox'
import FormikSelectGroup from '../../components/formik/FormikSelectGroup'
import { ClipLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton'
import { generateOptions } from '../../utils/Utils'
import {
  getCatalog,
  createCatalog
} from '../../app/reducers/Catalog/catalogSlice'
import {
  getJobWork,
  fetchJobWorks
} from '../../app/reducers/JobWork/jobWorkSlice'
import {
  getUsers,
  fetchUsers
} from '../../app/reducers/Users/userSlice'
import {
  getAuth
} from '../../app/reducers/Auth/authSlice'
import { fetchCategories, getCategories } from '../../app/reducers/Category/categorySlice'


const CreateJobWork = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(getAuth)
  useEffect(() => {
    dispatch(fetchJobWorks())
    dispatch(fetchUsers({ role:'manufacturer' }))
    dispatch(fetchCategories())
  }, [])
  
  const { loading } = useSelector(getCatalog)
  const { jobWork } = useSelector(getJobWork)
  const { category } = useSelector(getCategories)
  const { users } = useSelector(getUsers)
  const data = useMemo(
    () => (jobWork?.docs ? jobWork.docs : []),
    [jobWork]
  )

  const categorydata = useMemo(
    () => (category?.docs ? category.docs : []),
    [category]
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      price: '',
      manufacturer:'',
      defaultJobName:'',
      defaultJobPrice: '',
      isEmbroidery: false
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      price: Yup.number().required(),
      manufacturer:Yup.string().required(),
      defaultJobName:Yup.string().required(),
      defaultJobPrice: Yup.number().required()
    }),
    onSubmit: async (values, { resetForm }) => {
      await dispatch(createCatalog({ ...values,createdBy: { creator:user.name, createdRole: user.role } }))
      resetForm()
    },
  })

  return (
    <PageWithCard heading='Create Catalog'>
      <div className='text-slate-800 font-semibold mb-4'>Catalog</div>
      {loading ? (
        <ClipLoader />
      ) : (
      <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4'>
        <FormikInputGroup label='Name' formik={formik} name='name' required />
        <FormikInputGroup label='Price' type="number" formik={formik} name='price' required />
        <FormikSelectGroup
          formik={formik}
          label='Manufacturer'
          name='manufacturer'
          options={generateOptions({
            array: users ? users.docs : [],
            valueField: '_id',
            labelField: 'name',
          })}
          required
        />
        <FormikSelectGroup
          formik={formik}
          label='Category'
          name='category'
          options={generateOptions({
            array: categorydata,
            valueField: '_id',
            labelField: 'name',
          })}
          required
        />
        <FormikSelectGroup
          formik={formik}
          label='Default Job Name'
          name='defaultJobName'
          onChange={(optionData) => {
            console.log(optionData)
            let getDetail = data.find(d => d.name == optionData.value)
            formik.setFieldValue('defaultJobName',optionData.label)
            formik.setFieldValue('defaultJobPrice',getDetail?.price)
          }}
          options={generateOptions({
            array: data ? data : [],
            valueField: 'name',
            labelField: 'name',
          })}
          required
        />{
          formik.values.defaultJobName && 
        <FormikInputGroup label='Price' type="number" formik={formik} name='defaultJobPrice' required />
      }
      <FormikCheckbox label='isEmbroidery' formik={formik} name='isEmbroidery' required />
        <div>
          <PrimaryButton type='submit'>Submit</PrimaryButton>
        </div>
      </form>
      )}
    </PageWithCard>
  )
}

export default CreateJobWork
