import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "unfinished";

export const fetchUnfinished = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const unfinishedSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchUnfinished, SLICE_NAME),
  },
});

export const getUnfinished = (state) => state.unfinished;
export const { setEditId } = unfinishedSlice.actions;
export default unfinishedSlice.reducer;
