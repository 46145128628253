import { useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { fetchSales, getSale } from "../../app/reducers/Sale/saleSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import { useEffect, useState, useMemo } from "react";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { generateOptions } from "../../utils/Utils";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import { useCallback } from "react";
import ViewSalePanel from "./ViewSalePanel";
import moment from "moment/moment";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import { Link, useNavigate } from "react-router-dom";
import { ExternalLink } from "react-feather";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";

export default function ViewSale() {
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [salePanelOpen, setSalePanelOpen] = useState(false);
  const [viewingSale, setViewingSale] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sale, loading } = useSelector(getSale);
  const { location, loading: locationsLoading } = useSelector(getLocation);
  const { users: customer, loading: usersLoading } = useSelector(getUsers);
  const { catalog, loading: catalogLoading } = useSelector(getCatalog);
  const locationInfoId = localStorage.getItem("locationInfoId");

  const filterFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: [locationInfoId],
      customer: "",
      catalog: [],
      dateFrom: moment().startOf("day").valueOf(),
      dateTo: moment().endOf("day").valueOf(),
    },
    onSubmit: (values) => {
      const filters = {
        "location._id": { $in: values.location },
        "product.catalog_id._id": { $in: values.catalog },
        createdAt: { $gt: values.dateFrom, $lt: values.dateTo },
        populate: true,
      };
      if (values.customer) {
        filters.customerId = values.customer;
      }
      dispatch(fetchSales(filters));
    },
  });

  useEffect(() => {
    const filters = {
      "location._id": { $in: filterFormik.values.location },
      "product.catalog_id._id": { $in: filterFormik.values.catalog },
      createdAt: {
        $gt: filterFormik.values.dateFrom,
        $lt: filterFormik.values.dateTo,
      },
      populate: true,
    };
    if (filterFormik.values.customer) {
      filters.customerId = filterFormik.values.customer;
    }
    dispatch(fetchSales({ populate: true, page, ...filters }));
    dispatch(fetchLocations());
    dispatch(fetchCatalogs());
  }, [page]);

  const cols = [
    {
      Header: "Select",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          checked={selectedRows
            .map((ele) => ele._id)
            .includes(row.original._id)}
          onChange={(e) => {
            e.preventDefault();
            if (e.target.checked) {
              setSelectedRows([...selectedRows, row.original]);
            } else {
              const data = selectedRows.filter(
                (item) => item._id !== row.original._id
              );
              setSelectedRows(data);
            }
          }}
        />
      ),
    },
    {
      Header: "SRNO",
      Cell: ({ row }) => {
        return (
          <span
            onClick={(e) => {
              e.stopPropagation();
              setSalePanelOpen(true);
              setViewingSale(row.original);
            }}
            className="text-indigo-500 underline cursor-pointer"
          >
            {sale.limit * (page - 1) + row.index + 1}
          </span>
        );
      },
    },
    {
      Header: "Sale Number",
      Cell: ({ row }) => {
        return (
          <Link
            to={`/viewBill/${row.original._id}`}
            className="text-indigo-500 underline cursor-pointer flex items-center gap-1"
            target="_blank"
            rel="noopener"
          >
            {row.original.saleNumber}
            <ExternalLink className="h-4 w-4" />
          </Link>
        );
      },
    },
    {
      Header: "Total Amount",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.receivedAmount.reduce(
              (acc, val) => acc + val.amount,
              0
            )}
          </span>
        );
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return (
          <span>{moment(row.original.createdAt).format("Do MMM YYYY")}</span>
        );
      },
    },
    {
      Header: "Customer",
      Cell: ({ row }) => {
        return <span>{row.original.customerName}</span>;
      },
    },
    {
      Header: "Location",
      Cell: ({ row }) => {
        return <span>{row.original.location.name}</span>;
      },
    },
  ];

  const colsMemo = useMemo(() => cols, [cols]);
  const data = useMemo(() => (sale?.docs ? sale.docs : []), [sale]);
  const locationData = useMemo(
    () => (location?.docs ? location.docs : []),
    [location]
  );
  const customerData = useMemo(
    () => (customer?.docs ? customer.docs : []),
    [customer]
  );
  const catalogData = useMemo(
    () => (catalog?.docs ? catalog.docs : []),
    [catalog]
  );

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchSales({ search: search, populate: true }));
    }, 300),
    []
  );

  return (
    <PageWithCard heading="View Sale">
      <ViewSalePanel
        salePanelOpen={salePanelOpen}
        setSalePanelOpen={setSalePanelOpen}
        sale={viewingSale}
      />
      <div className="p-2">
        <h1>Filters</h1>
        <form
          className="flex flex-col gap-2"
          onSubmit={filterFormik.handleSubmit}
        >
          <FormikMultiSelect
            label="Location"
            formik={filterFormik}
            name="location"
            options={[
              ...generateOptions({
                array: locationData,
                valueField: "_id",
                labelField: "name",
              }),
            ]}
          />
          <FormikAsyncSelect
            label="Customer"
            formik={filterFormik}
            name="customer"
            getOptions={async (search) => {
              const data = await dispatch(
                fetchUsers({ __t: "customer", search })
              );
              console.log(data);
              return data?.payload?.data?.docs
                ? data?.payload?.data?.docs.map((ele) => ({
                    value: ele._id,
                    label: `${ele.name} / ${ele.username}`,
                  }))
                : [];
            }}
          />
          <FormikMultiSelect
            label="Catalog"
            formik={filterFormik}
            name="catalog"
            options={[
              ...generateOptions({
                array: catalogData,
                valueField: "_id",
                labelField: "name",
              }),
            ]}
          />
          <FormikInputDateGroup
            formik={filterFormik}
            name="dateFrom"
            label="Date From"
          />
          <FormikInputDateGroup
            formik={filterFormik}
            name="dateTo"
            label="Date To"
          />
          <div>
            <SecondaryButton type="submit">Submit</SecondaryButton>
          </div>
        </form>
      </div>
      <TableWithHeadingAndGlobalSearch
        heading="Sales"
        data={data}
        loading={loading}
        columns={colsMemo}
        searchFunction={(search) => {
          debouncedSearch(search);
        }}
      />
      <PaginationClassic paginationDetails={sale} setPage={setPage} />
    </PageWithCard>
  );
}
