import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import { useMemo } from "react";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import {
    fetchLocations,
    getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
    fetchCatalogs,
    getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
    fetchProducts,
    getProduct,
} from "../../app/reducers/Product/productSlice";
import moment from "moment";
import { toast } from "react-toastify";
import QueryString from "qs";
import customId from "custom-id";
import Select from "react-select/async";
import { X } from "react-feather";
import { TRANSFER_TYPES } from "../../utils/dropdownOptions";

const TableHeader = ({ headers }) => {
    return (
        <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
            <tr>
                {headers.map((header, i) => (
                    <th
                        key={i}
                        className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap break-words"
                    >
                        <div
                            className="font-semibold text-left break-words"
                            style={header.style}
                        >
                            {header.name}
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    );
};

const Row = function Row({ formik, index, element, arrayHelpers }) {
    return (
        <>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <X
                    onClick={(e) => {
                        // formik.setFieldValue("flatDiscount", 0);
                        arrayHelpers.remove(index);
                    }}
                />
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <FormikInputGroup
                    name={`product.${index}.sku`}
                    formik={formik}
                    type="text"
                    readOnly
                />
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <FormikInputGroup
                    name={`product.${index}.catalogName`}
                    formik={formik}
                    type="text"
                    readOnly
                />
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <FormikInputGroup
                    name={`product.${index}.color`}
                    formik={formik}
                    type="text"
                    readOnly
                />
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <FormikInputGroup
                    name={`product.${index}.stock`}
                    formik={formik}
                    type="text"
                    readOnly
                />
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <FormikInputGroup
                    name={`product.${index}.damage`}
                    formik={formik}
                    type="text"
                    readOnly
                />
            </td>
           
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <FormikInputGroup
                    name={`product.${index}.quantity`}
                    formik={formik}
                    type="number"
                    max={formik.values.product[index].stock}
                    min={0}
                />
            </td>
        </>
    );
};

const TransferToDamage = () => {
    const [currentItem, setCurrentItem] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [progressLoading, setProgressLoading] = useState(false);
    const [errorData, setErrorData] = useState([]);

    const locationInfoId = localStorage.getItem("locationInfoId");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLocations());
        dispatch(fetchCatalogs());
    }, []);
    const { location } = useSelector(getLocation);
    const { loading: catalogLoading, catalog } = useSelector(getCatalog);
    const { loading: productLoading, product } = useSelector(getProduct);

    const data = useMemo(() => (catalog?.docs ? catalog.docs : []), [catalog]);
    const productData = useMemo(
        () => (product?.docs ? product.docs : []),
        [product]
    );
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            location: locationInfoId ? locationInfoId : "",
            type: "",
            product: [],
        },
        validationSchema: Yup.object({
            location: Yup.string().required(),
            product: Yup.array()
                .of(Yup.object({}))
                .required()
                .min(1, "Please Add atleast one product"),
        }),
        onSubmit: async (values) => {
            let filterProduct = values.product
            if (filterProduct.length > 0) {
                setProgressLoading(true);
                setTotalItem(filterProduct.length);
                for (let i = 0; i < filterProduct.length; i++) {
                    try {
                        let data = {
                            catalog: filterProduct[i].catalog,
                            product: filterProduct[i].product,
                            quantity: filterProduct[i].quantity,
                            location: values.location,
                            type: values.type
                        };
                        const response = await authAxiosInstance.post(`finishedDamage`, data);
                        if (response.data) {
                            setCurrentItem((prevState) => prevState + 1);
                        }
                    } catch (error) {
                        console.log(error, "new error hu mei");
                        setCurrentItem((prevState) => prevState + 1);
                        setErrorData((prev) => [
                            ...prev,
                            {
                                ...filterProduct[i],
                                error: JSON.stringify(error.response?.data),
                            },
                        ]);
                    }
                }
            } else {
                return alert("Please add atleast one product");
            }
            formik.resetForm();
        },
    });

    return (
        <>
            <PageWithCard heading="Damage Transfer">
                {progressLoading ? (
                    <ProgressBar
                        currentItem={currentItem}
                        totalItem={totalItem}
                        errorData={errorData}
                    />
                ) : (
                    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
                        <FormikSelectGroup
                            formik={formik}
                            label="Location"
                            name="location"
                            onChange={(selectedOption) => {
                                formik.setFieldValue("product", []);
                                formik.setFieldValue("location", selectedOption.value);
                            }}
                            required
                            options={generateOptions({
                                array: location ? location.docs : [],
                                valueField: "_id",
                                labelField: "name",
                            })}
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label="Select Type"
                            onChange={(selectedOption) => {
                                formik.setFieldValue("product", []);
                                formik.setFieldValue("type", selectedOption.value);
                            }}
                            name={`type`}
                            options={generateOptions({
                                array: TRANSFER_TYPES,
                                valueField: "value",
                                labelField: "label",
                            })}
                            required
                        />
                        {formik.values?.location && formik.values.type && (
                            <FormikProvider value={formik}>
                                <FieldArray
                                    name="product"
                                    render={(arrayHelpers) => {
                                        return (
                                            <>
                                                <div className="flex flex-col gap-2">
                                                    <p>Select Product</p>
                                                    <Select
                                                        loadOptions={async (value) => {
                                                            const data = {
                                                                populate: true,
                                                                search: value,
                                                            };
                                                            const string = QueryString.stringify(data);
                                                            const resp = await authAxiosInstance.get(`/product?${string}`);
                                                            return resp.data.data.docs.map((ele) => ({
                                                                label: `${ele.sku} - ${ele.color}/${ele.catalogData.name}`,
                                                                value: JSON.stringify(ele),
                                                            }));
                                                        }}
                                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                        onChange={async (option) => {
                                                            const product = JSON.parse(option.value);
                                                            let checkExist = formik.values.product.filter(d => d.product == product._id)
                                                            if(checkExist.length > 0) {
                                                                return alert("Product already exist")
                                                            }
                                                            console.log(product, "new")
                                                            const stringInv = QueryString.stringify({
                                                                product: product._id,
                                                                location: formik.values.location,
                                                            });
                                                            const invRsp =
                                                                await authAxiosInstance.get(
                                                                    `productInventory?${stringInv}`
                                                                );
                                                            const stock = invRsp.data.data.docs[0];
                                                            let updateStockType;
                                                            if(formik.values.type == 'finished') {
                                                                updateStockType = stock.finished
                                                            } else if (formik.values.type == 'unfinished') {
                                                                updateStockType = stock.unfinished
                                                            } else {
                                                                updateStockType = stock.unfinishedEmbroidery
                                                            }
                                                            let detail = {
                                                                ...product,
                                                                catalogName: product?.catalogData.name,
                                                                catalog: product?.catalog,
                                                                sku: product.sku,
                                                                color: product.color,
                                                                product: product._id,
                                                                stock: updateStockType,
                                                                damage: stock.damaged
                                                            }
                                                            arrayHelpers.insert(0, detail)
                                                        }}
                                                    />
                                                </div>
                                                <table className="table-auto w-full">
                                                    <TableHeader
                                                        headers={[
                                                            { name: "" },
                                                            {
                                                                name: "SKU"
                                                            },
                                                            {
                                                                name: "Catalog"
                                                            },
                                                            {
                                                                name: "Color"
                                                            },
                                                            
                                                            {
                                                                name: `${formik.values.type} Stock`
                                                            },
                                                            {
                                                                name: "Damaged Stock"
                                                            },
                                                            {
                                                                name: "Damaged Quantity"
                                                            },
                                                        ]}
                                                    />
                                                    <tbody className="text-sm">
                                                        {formik.values.product.map((ele, i) => (
                                                            <React.Fragment key={`${ele.sku}_${i}`}>
                                                                <tr>
                                                                    <Row
                                                                        formik={formik}
                                                                        index={i}
                                                                        element={ele}
                                                                        arrayHelpers={arrayHelpers}
                                                                    />
                                                                </tr>
                                                            </React.Fragment>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </>
                                        );
                                    }}
                                />
                                {/* {formik.errors["product"] &&
                                    !Array.isArray(formik.errors["product"]) &&
                                    formik.errors["product"] ? (
                                    <p className="text-xs text-red-500">
                                        {formik.errors["product"]}
                                    </p>
                                ) : null} */}
                            </FormikProvider>
                        )}

                        <div>
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    </form>
                )}
            </PageWithCard>
        </>
    );
};

export default TransferToDamage;
