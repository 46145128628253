import { FieldArray, FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  editLocation,
  fetchLocation,
  fetchLocations,
  getLocation,
  setEditId,
} from "../../app/reducers/Location/locationSlice";
import EditModal from "../../components/infrastructure/Modals/EditModal";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { X } from "react-feather";
import geo from "../../assets/countries+states+cities.json";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";

import {
  findCitiesInState,
  findStatesInCountry,
  generateOptions,
} from "../../utils/Utils";

const EditLocationModal = () => {
  const dispatch = useDispatch();

  const {
    elementEditId,
    editDataLoading: loading,
    elementEditData,
  } = useSelector(getLocation);

  //   useDispatch()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
    },
    onSubmit: (values) => {
      dispatch(editLocation(values));
      dispatch(fetchLocations());
    },
  });
  useEffect(() => {
    if (elementEditId) {
      dispatch(fetchLocation({ _id: elementEditId }));
    }
  }, [elementEditId]);
  return (
    <EditModal
      editProperty={elementEditId}
      setEditProperty={setEditId}
      getEditData={fetchLocation}
      title="Edit Location"
    >
      {loading ? (
        <ClipLoader />
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-4 p-4"
        >
          <FormikInputGroup
            name="_id"
            required
            label="System Id"
            readOnly
            formik={formik}
          />
          <FormikInputGroup name="name" required label="Name" formik={formik} />
          <FormikTextareaGroup
            name="address"
            required
            label="Address"
            formik={formik}
          />
          <FormikInputGroup
            name="companyName"
            required
            label="Company Name"
            formik={formik}
          />
          <FormikSelectGroup
            formik={formik}
            label="Country"
            name="country"
            required
            onChange={(selectedOption) => {
              formik.setFieldValue("country", selectedOption.value);
            }}
            options={generateOptions({
              array: geo,
              valueField: "name",
              labelField: "name",
            })}
          />
          <FormikSelectGroup
            formik={formik}
            label="State"
            name="state"
            required
            options={generateOptions({
              array: findStatesInCountry(formik.values.country),
              valueField: "name",
              labelField: "name",
            })}
          />

          <FormikSelectGroup
            formik={formik}
            label="City"
            name="city"
            required
            options={generateOptions({
              array: findCitiesInState(
                formik.values.country,
                formik.values.state
              ),
              valueField: "name",
              labelField: "name",
            })}
          />
          <FormikInputGroup
            name="pincode"
            required
            label="Pincode"
            formik={formik}
          />
          <FormikProvider value={formik}>
            <FieldArray name="emails">
              {(arrayHelpers) => (
                <>
                  <div className="flex items-center gap-4">
                    <h1 className="text-md">Emails</h1>
                    <PrimaryButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.push("");
                      }}
                    >
                      Add
                    </PrimaryButton>
                  </div>
                  <div className="w-full">
                    {formik?.values?.emails?.map((email, index) => (
                      <div
                        className="flex items-center gap-4"
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <FormikInputGroup
                          name={`emails.${index}`}
                          formik={formik}
                          value={email}
                          fullWidth
                        />
                        <X
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </FieldArray>
          </FormikProvider>
          <FormikProvider value={formik}>
            <FieldArray name="phones">
              {(arrayHelpers) => (
                <>
                  <div className="flex items-center gap-4">
                    <h1 className="text-md">Phones</h1>
                    <PrimaryButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.push("91");
                      }}
                    >
                      Add
                    </PrimaryButton>
                  </div>
                  <div className="w-full">
                    {formik?.values?.phones?.map((email, index) => (
                      <div
                        className="flex items-center gap-4"
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <FormikPhoneInputGroup
                          name={`phones.${index}`}
                          formik={formik}
                          value={email}
                          fullWidth
                        />
                        <X
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </FieldArray>
          </FormikProvider>
          <div className="mt-4">
            <PrimaryButton type="submit">Edit</PrimaryButton>
          </div>
        </form>
      )}
    </EditModal>
  );
};

export default EditLocationModal;
