import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "greyColor";

export const fetchGreyColors = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editGreyColor = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchGreyColor = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteGreyColor = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createGreyColor = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

export const editProductWithFile = createAsyncThunk(
  "product",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await authAxiosInstance.patch("greyColor", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if(resp.data) {
        toast.success(`Grey Color edited successfully`);
      }
      return resp.data;
    } catch (error) {
      rejectWithValue(error)
    }
  }
);

const greyColorSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchGreyColors, SLICE_NAME),
    ...createData.generateExtraReducers(createGreyColor, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteGreyColor, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchGreyColor, SLICE_NAME),
    ...editData.generateExtraReducers(editGreyColor, SLICE_NAME),
    [editProductWithFile.pending]: (state, action) => {
      state.loading = true;
    },
    [editProductWithFile.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [editProductWithFile.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const getGreyColor = (state) => state.greyColor;
export const { setEditId } = greyColorSlice.actions;
export default greyColorSlice.reducer;
