import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikFileInput from "../../components/formik/FormikFileInput";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import {
  createUser,
  fetchUsers,
  getUsers,
} from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchGreyQualities,
  createGreyQualityWithFile,
  getGreyQualities,
  deleteGreyQuality,
  setEditId,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import { Edit2, Trash } from "react-feather";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { useCallback } from "react";
import { useMemo } from "react";
import { async } from "regenerator-runtime";
import EditGreyModal from "./EditGreyModal";
import { GREY_QUALITY_UNITS } from "../../utils/dropdownOptions";

const AddGrey = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchGreyQualities({ populate: true }));
  }, []);
  const { loading, users } = useSelector(getUsers);
  const { loading: greyLoading, greyQuality } = useSelector(getGreyQualities);

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Name",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <a
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/viewGreyColor/${row.original._id}`);
            }}
          >
            {" "}
            <span style={{ color: "blue", textDecoration: "underline" }}>
              {row.original.name}
            </span>
          </a>
        );
      },
    },
    {
      Header: "Manufacturer",
      accessor: "manufacturerData.name",
    },
    {
      Header: "Unit",
      accessor: "unit",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Edit2
              color="blue"
              onClick={() => {
                dispatch(setEditId(row.original._id));
              }}
            />

            <Trash
              color="red"
              onClick={async () => {
                await dispatch(deleteGreyQuality({ id: row.original._id }));
                dispatch(fetchGreyQualities({ populate: true }));
              }}
            />
          </div>
        );
      },
    },
  ];
  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchGreyQualities({ search: search, populate: true }));
    }, 300),
    []
  );
  const data = useMemo(
    () => (greyQuality?.docs ? greyQuality.docs : []),
    [greyQuality]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      unit: "meter",
      manufacturer: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      unit: Yup.string().required(),
      manufacturer: Yup.string().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      await dispatch(createGreyQualityWithFile({ ...values }));
      dispatch(fetchGreyQualities({ populate: true }));
      formik.values.photo = "";
      resetForm();
    },
  });

  return (
    <PageWithCard heading="Add Grey">
      <div className="text-slate-800 font-semibold mb-4">Grey Detail</div>
      {loading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup label="Name" formik={formik} name="name" required />
          <FormikSelectGroup
            formik={formik}
            label="Unit"
            name="unit"
            required
            options={generateOptions({
              array: GREY_QUALITY_UNITS,
              valueField: "value",
              labelField: "label",
            })}
          />
          <FormikSelectGroup
            formik={formik}
            label="Manufacturer"
            name="manufacturer"
            options={generateOptions({
              array: users ? users.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
            required
          />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
      <EditGreyModal />
      <TableWithHeadingAndGlobalSearch
        heading="Grey Quality"
        data={data}
        columns={columns}
        loading={greyLoading}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
      />
    </PageWithCard>
  );
};

export default AddGrey;
