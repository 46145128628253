const ADMIN_ROLES = {
  ALL: [
    "admin",
    "customer",
    "manufacturer",
    "store_manager",
    "godown_manager",
    "job_work",
  ],
  EMPLOYEE: [
    "admin",
    "store_manager",
    "godown_manager",
    "employee",
    "stock_transfer",
  ],
  MANAGEMENT: ["admin", "store_manager", "godown_manager"],
  TRANSFER: ["admin", "store_manager", "godown_manager", "stock_transfer"],
};

module.exports = ADMIN_ROLES;
