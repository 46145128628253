import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateReset, generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "productPO";

export const fetchPurchaseOrders = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editPurchaseOrder = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchPurchaseOrder = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deletePurchaseOrder = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createPurchaseOrder = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

export const updatePurchaseOrder = createAsyncThunk(
  "productPO",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const resp = await authAxiosInstance.patch("productPO", data);
      if(resp.data){
        return resp.data
      }
    } catch (error) {
      if(error.response.data.message) {
        toast.error(error.response.data.message);
        return rejectWithValue({error : error.response.data.message})
      }
      toast.error("Something went wrong");
      return rejectWithValue({error : "Something went wrong"})
    }
  }
);

export const updateStatusPurchaseOrder = createAsyncThunk(
  "productPO",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await authAxiosInstance.patch("productPO/status", data);
      return resp.data;
    } catch (error) {
      if(error.response.data.message) {
        toast.error(error.response.data.message);
        return rejectWithValue({error : error.response.data.message})
      }
      toast.error("Something went wrong");
      return rejectWithValue({error : "Something went wrong"})
    }
  }
);

const PurchaseOrderSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    reset: generateReset(SLICE_NAME),
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchPurchaseOrders, SLICE_NAME),
    ...createData.generateExtraReducers(createPurchaseOrder, SLICE_NAME),
    ...deleteData.generateExtraReducers(deletePurchaseOrder, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchPurchaseOrder, SLICE_NAME),
    ...editData.generateExtraReducers(editPurchaseOrder, SLICE_NAME),

    [updatePurchaseOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePurchaseOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updatePurchaseOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error
    },
    [updateStatusPurchaseOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [updateStatusPurchaseOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateStatusPurchaseOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error
    },
  },
});

export const getProductPO = (state) => state.productPO;
export const { setEditId,reset } = PurchaseOrderSlice.actions;
export default PurchaseOrderSlice.reducer;
