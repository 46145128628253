import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchPurchaseOrders,
  updateStatusPurchaseOrder,
  getProductPO,
  setEditId,
  reset,
  updatePurchaseOrder,
} from "../../app/reducers/PurchaseOrder/purchaseOrderSlice";
import { Edit2, Eye, Trash } from "react-feather";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { useCallback } from "react";
import { useMemo } from "react";
import EditPurchaseModel from "./EditPurchaseModel";
import { useState } from "react";
import ViewPurchasePanel from "./ViewPurchasePanel";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import { updateGreyPurchaseOrder } from "../../app/reducers/GreyPurchaseOrder/greyPOSlice";
import moment from "moment";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";

const ViewNewPurchaseOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [viewPurchaseModel, setViewPurchaseModalOpen] = useState(false);
  const [viewingParty, setViewingParty] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(reset({}));
  }, []);

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchPurchaseOrders({ populate: true }));
    dispatch(fetchLocations());
  }, []);

  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const { productPO, loading: productPoLoading } = useSelector(getProductPO);

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      Header: "Catalog",
      accessor: "catalogData.name",
    },
    {
      Header: "Location",
      accessor: "locationData.name",
    },
    {
      Header: "Manufacturer",
      accessor: "manufacturerData.name",
    },
    {
      Header: "Color",
      accessor: "productData.color",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Ordered Total",
      accessor: "quantity",
    },
    {
      Header: "Ordered Remaining",
      accessor: "remaining",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Eye
              size={18}
              color="green"
              onClick={() => {
                setViewPurchaseModalOpen(true);
                setViewingParty(row.original);
              }}
            />
            {/* <Edit2
                            color='blue'
                            onClick={() => {
                                dispatch(setEditId(row.original._id));
                            }}
                        /> */}
            {row.original.status == "settled" ? (
              <div>Settled</div>
            ) : row.original.status == "completed" ? (
              ""
            ) : (
              <PrimaryButton onClick={() => handleSettled(row.original)}>
                Settled
              </PrimaryButton>
            )}
          </div>
        );
      },
    },
  ];
  const debouncedSearch = useCallback(
    _.debounce((search, values) => {
      let data = {};
      if (values.location) {
        data["location"] = values.location;
      }
      if (values.manufacturer) {
        data["manufacturer"] = values.manufacturer;
      }
      dispatch(
        fetchPurchaseOrders({
          ...data,
          search: search,
          populate: true,
          status: { $in: values.status },
        })
      );
    }, 300),
    []
  );

  const locationdata = useMemo(
    () => (location?.docs ? location.docs : []),
    [location]
  );

  const data = useMemo(
    () => (productPO?.docs ? productPO.docs : []),
    [productPO]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: "",
      status: [],
      manufacturer: "",
      from: moment().startOf("day").valueOf(),
      to: moment().endOf("day").valueOf(),

    },
    validationSchema: Yup.object({
      location: Yup.string().required(),
      status: Yup.array().required(),
      manufacturer: Yup.string().required(),

    }),
    onSubmit: async (values, { resetForm }) => {
      const filterData = {
        location: values.location,
        manufacturer: values.manufacturer,
        status: { $in: values.status },
        createdAt: {
          $gte: values.from,
          $lte: values.to,
        },

      }
      dispatch(
        fetchPurchaseOrders({
          // ...values,
          ...filterData,
          populate: true,
        })
      );
    },
  });

  const handleSettled = async (rowData) => {
    let data = {
      _id: rowData._id,
      product: rowData.product,
      location: rowData.location,
      remaining: rowData.remaining,
      status: "settled",
    };
    await dispatch(updatePurchaseOrder(data)).then((d) => {
      let data = {};
      if (formik.values.location) {
        data["location"] = values.location;
      }
      if (formik.values.manufacturer) {
        data["manufacturer"] = values.manufacturer;
      }
      dispatch(
        fetchPurchaseOrders({ status: formik.values.status, populate: true })
      );
    });
  };

  useEffect(() => {
    let data = {};
    if (formik.values.location) {
      data["location"] = formik.values.location;
    }
    if (formik.values.manufacturer) {
      data["manufacturer"] = formik.values.manufacturer;
    }
    dispatch(
      fetchPurchaseOrders({
        ...data,
        status: { $in: formik.values.status },
        populate: true,
        page,
      })
    );
  }, [page]);

  return (
    <>
      <ViewPurchasePanel
        open={viewPurchaseModel}
        setOpen={setViewPurchaseModalOpen}
        party={viewingParty}
      />
      <EditPurchaseModel values={formik.values} />
      <PageWithCard heading="View Purchase Order">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikSelectGroup
            formik={formik}
            label="Manufacturer"
            name="manufacturer"
            options={generateOptions({
              array: users ? users.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
            required
          />

          <FormikSelectGroup
            formik={formik}
            label="Location"
            name="location"
            required
            options={generateOptions({
              array: locationdata,
              valueField: "_id",
              labelField: "name",
            })}
          />

          <FormikMultiSelect
            formik={formik}
            label="Status"
            name="status"
            required
            options={generateOptions({
              array: [
                { label: "Pending", value: "pending" },
                { label: "Completed", value: "completed" },
                { label: "Settled", value: "settled" },
                { label: "Incomplete", value: "incomplete" },
              ],
              valueField: "value",
              labelField: "label",
            })}
          />
          <FormikInputDateGroup
            formik={formik}
            label="From"
            name="from"
            required
          />
          <FormikInputDateGroup formik={formik} label="To" name="to" required />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
        {/* // )} */}
        {/* <EditGreyModal /> */}
        <TableWithHeadingAndGlobalSearch
          heading="Purchase Orders"
          data={data}
          columns={columns}
          loading={productPoLoading}
          searchFunction={(value) => {
            debouncedSearch(value, formik.values);
          }}
        />
        <PaginationClassic paginationDetails={productPO} setPage={setPage} />
      </PageWithCard>
    </>
  );
};

export default ViewNewPurchaseOrder;
