import React from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import _ from "lodash";
import { useFormik } from "formik";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import {
  fetchCashbooks,
  createCashbook,
  deleteCashbook,
  setEditId,
  getCashbooks,
} from "../../app/reducers/Cashbook/cashbookSlice";
import {
  fetchExpenseCategories,
  getExpenseCategories,
} from "../../app/reducers/ExpenseCategory/expenseCategorySlice";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import * as Yup from "yup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import { PAYMENT_METHODS, PAYMENT_TYPE } from "../../utils/dropdownOptions";
import { getAuth } from "../../app/reducers/Auth/authSlice";
import { toast } from "react-toastify";

const CashBook = () => {
  const dispatch = useDispatch();
  const { expenseCategory, loading } = useSelector(getExpenseCategories);
  const { location, loading: locationLoading } = useSelector(getLocation);
  const locationInfoId = localStorage.getItem("locationInfoId");
  const { user } = useSelector(getAuth);

  useEffect(() => {
    dispatch(fetchExpenseCategories());
    dispatch(fetchLocations());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      store: locationInfoId ? locationInfoId : "",
      expenseCategory: "",
      amount: null,
      mode: "cash",
      notes: "",
      type: "debit",
    },
    validationSchema: Yup.object({
      store: Yup.string().required(),
      expenseCategory: Yup.string().required(),
      amount: Yup.number().required(),
      mode: Yup.string().required(),
      type: Yup.string().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        ...values,
      };
      await dispatch(createCashbook(data));
      resetForm();
    },
  });
  const data = useMemo(
    () => (expenseCategory?.docs ? expenseCategory.docs : []),
    [expenseCategory]
  );

  return (
    <PageWithCard heading="Add Expense">
      <div className="w-full mb-8 flex flex-col gap-4">
        <h1>Add Expense</h1>
        {formik.isSubmitting ? (
          <ClipLoader />
        ) : (
          <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
            {locationLoading ? (
              <ClipLoader />
            ) : (
              <FormikSelectGroup
                name="store"
                label="Stores"
                formik={formik}
                options={generateOptions({
                  array: location?.docs ? location?.docs : [],
                  valueField: "_id",
                  labelField: "name",
                })}
              />
            )}
            {loading ? (
              <ClipLoader />
            ) : (
              <FormikSelectGroup
                name="expenseCategory"
                label="Expense Category"
                formik={formik}
                options={generateOptions({
                  array: data,
                  valueField: "name",
                  labelField: "name",
                })}
              />
            )}
            <FormikInputGroup
              formik={formik}
              type="number"
              name="amount"
              label="Amount"
              required
              step="0.001"
            />
            <FormikSelectGroup
              name="mode"
              label="mode"
              formik={formik}
              options={generateOptions({
                array: PAYMENT_METHODS,
                labelField: "label",
                valueField: "value",
              })}
            />
            <FormikSelectGroup
              name="type"
              label="Type"
              formik={formik}
              options={generateOptions({
                array: PAYMENT_TYPE,
                valueField: "value",
                labelField: "label",
              })}
            />
            <FormikInputGroup name="notes" label="Notes" formik={formik} />
            <div>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
          </form>
        )}
      </div>
    </PageWithCard>
  );
};

export default CashBook;
