import { FieldArray, FormikProvider, useFormik } from "formik";
import numeral from "numeral";
import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import {
  BILL_NUMBER_PADDING,
  PAYMENT_METHODS,
} from "../../utils/dropdownOptions";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import { X } from "react-feather";
import { generateOptions } from "../../utils/Utils";
import { useEffect } from "react";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { createSale } from "../../app/reducers/Sale/saleSlice";
import { useDispatch, useSelector } from 'react-redux'

const ReceivedModal = ({
  showReceivedModal,
  setShowReceivedModal,
  saleData,
  saleFormik
}) => {
  
  const dispatch = useDispatch()

console.log(saleData,"newhu mein")
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      receivedAmount: [],
      finalAmount: saleData?.product?.reduce((acc, ele) => acc + (ele.sellPrice * ele.qty), 0),
      amountDue: saleData?.product?.reduce((acc, ele) => acc + (ele.sellPrice * ele.qty), 0),
    },
    onSubmit: async (values) => {
    let receivedAmount = values?.receivedAmount?.reduce((acc, ele) => acc + ele.amount, 0)
    if(receivedAmount > values.finalAmount) {
      return alert("Received amount should not be gretater than final amount")
    }
    if(receivedAmount < values.finalAmount) {
      return alert("Please pay the full amount")
    }
    dispatch(createSale({ ...saleData, receivedAmount: values.receivedAmount }))
    saleFormik?.setFieldValue('product',[])
    formik.resetForm()
    setShowReceivedModal(false)
    },
  });


  return (
    <ModalBasic
      modalOpen={showReceivedModal}
      setModalOpen={setShowReceivedModal}
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 p-4">
        <FormikInputGroup
          label="Final Amount"
          formik={formik}
          readOnly
          name="finalAmount"
        />
        <FormikInputGroup
          label="Amount Due"
          formik={formik}
          readOnly
          name="amountDue"
        />
        <FormikProvider value={formik}>
          <FieldArray
            name="receivedAmount"
            render={(arrayHelpers) => {
              return (
                <>
                  <div>
                    <SecondaryButton
                    type="button"
                      onClick={(e) => {
                        e.stopPropagation()
                        arrayHelpers.push({
                          method: "cash",
                          amount: 0,
                          date: new Date(),
                          reference: "",
                        });
                      }}
                    >
                      Add
                    </SecondaryButton>
                    {formik?.values?.receivedAmount?.map((ele, i) => (
                      <div className="grid grid-cols-4 gap-2" key={i}>
                        <div className="flex items-center">
                          <X
                            onClick={(e) => {
                                e.stopPropagation()
                              arrayHelpers.remove(i);
                            }}
                          />
                          <FormikSelectGroup
                            formik={formik}
                            name={`receivedAmount.${i}.method`}
                            options={generateOptions({
                              array: PAYMENT_METHODS,
                              labelField: "label",
                              valueField: "value",
                            })}
                            label="Method"
                          />
                        </div>
                        <FormikInputGroup
                          name={`receivedAmount.${i}.amount`}
                          formik={formik}
                          type="number"
                          label="Amount"
                        />
                        <FormikInputDateGroup
                          name={`receivedAmount.${i}.date`}
                          formik={formik}
                          label="Date"
                        />
                        <FormikInputGroup
                          formik={formik}
                          name={`receivedAmount.${i}.reference`}
                          label="Reference"
                        />
                      </div>
                    ))}
                  </div>
                </>
              );
            }}
          />
        </FormikProvider>
        <div style={{display:"flex",gap:"10px"}}>
        {formik?.values?.receivedAmount?.reduce((acc, ele) => acc + ele.amount, 0) > 0 &&
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
        }
        </div>
      </form>
    </ModalBasic>
  );
};

export default ReceivedModal;
