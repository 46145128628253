import _ from "lodash";
import moment from "moment";
import QueryString from "qs";
import { useMemo } from "react";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useCallback } from "react";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import { exportCsv } from "../../utils/Utils";
import { limitVar } from "../../utils/limitVariable";

export const CreditReport = () => {
  const [page, setPage] = useState(1);
  const [creditData, setCreditData] = useState([]);
  const [totalBalance, setTotalBalance] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCreditReportData();
  }, [page]);

  const formik = useFormik({
    initialValues: {
      startDate: moment().startOf("day").valueOf(),
      endDate: moment().endOf("day").valueOf(),
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const data = {
          createdAt: {
            $gte: values.startDate,
            $lte: values.endDate,
          },
          populate: true,
          page,
        };
        const string = QueryString.stringify(data);
        const resp = await authAxiosInstance.get(`report/credit?${string}`);
        if (resp) {
          setCreditData(resp?.data?.data?.result);
          const totalCreditBalance = resp?.data?.data?.amount?.forEach((el) =>
            setTotalBalance(el)
          );
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch Credit ");
      } finally {
        setIsLoading(false);
      }
    },
  });

  const getCreditReportData = async (search) => {
    try {
      setIsLoading(true);
      const data = {
        createdAt: {
          $gte: formik.values.startDate,
          $lte: formik.values.endDate,
        },
        populate: true,
        page,
      };
      if (search) {
        data.search = search;
      }
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(`report/credit?${string}`);
      if (resp) {
        setCreditData(resp?.data?.data?.result);
        const totalCreditBalance = resp?.data?.data?.amount?.forEach((el) =>
          setTotalBalance(el)
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch Credit ");
    } finally {
      setIsLoading(false);
    }
  };

  const downloadExportCsv = async () => {
    try {
      setIsLoading(true);
      const data = {
        createdAt: {
          $gte: formik.values.startDate,
          $lte: formik.values.endDate,
        },
        limit: limitVar,
        populate: true,
      };
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(`report/credit?${string}`);
      if (resp?.data?.data?.result.docs?.length > 0) {
        const csvData = resp?.data?.data?.result?.docs?.map((el) => {
          return {
            Customer: el?.user?.name || "",
            Balance: el?.lastBalance,
          };
        });
        exportCsv(csvData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to Download");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + creditData?.pagingCounter;
      },
    },
    {
      Header: "Customer Name",
      accessor: "user.name",
    },
    {
      Header: "Balance",
      accessor: "lastBalance",
    },
  ];

  const dataMemo = useMemo(
    () => (creditData?.docs ? creditData?.docs : []),
    [creditData?.docs]
  );
  const columnsMemo = useMemo(() => columns, [columns]);

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      console.log("searching");
      getCreditReportData(search);
    }, 300),
    [JSON.stringify(creditData)]
  );

  return (
    <PageWithCard heading="Credit Report">
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-2 gap-2 m-2"
      >
        <FormikInputDateGroup
          label="Start Date"
          name="startDate"
          formik={formik}
        />
        <FormikInputDateGroup label="End Date" name="endDate" formik={formik} />
        <div className="flex flex-row gap-2">
          <PrimaryButton type="submit">Submit</PrimaryButton>
          <PrimaryButton type="button" onClick={downloadExportCsv}>
            Download
          </PrimaryButton>
        </div>
      </form>
      {totalBalance && (
        <div className="text font-semibold mb-2 flex flex-row gap-2">
          {totalBalance?.totalBalance && (
            <>
              <p>
                Total Credit Balance :{" "}
                <span>{`₹ ${totalBalance?.totalBalance}`}</span>
              </p>
            </>
          )}
        </div>
      )}
      <TableWithHeadingAndGlobalSearch
        heading="Credit Report"
        data={dataMemo}
        columns={columnsMemo}
        loading={isLoading}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
      />
      <PaginationClassic
        paginationDetails={{
          ...creditData,
        }}
        setPage={setPage}
      />
    </PageWithCard>
  );
};
