import React, { useState } from "react";
import { useRef } from "react";
import { bytesToMegaBytes } from "../../utils/Utils";
import { useDispatch } from "react-redux";
import { createObject, deleteSingleObject } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { X } from "react-feather";

const FormikDirectFileUpload = ({
  name,
  formik,
  label = "",
  required,
  location,
  fileName,
  ...props
}) => {
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [finalString, setFinalString] = useState(_.at(formik.values, name)[0]);
  const dispatch = useDispatch();

  return (
    <div>
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {label} {required && <span className="text-rose-500">*</span>}
      </label>

      {finalString ? (
        <div className="flex">
          <p className="truncate">{finalString}</p>
          <X
            color="red"
            onClick={async () => {
              await dispatch(
                deleteSingleObject({
                  keys: [finalString],
                })
              );

              setFinalString("");
              formik.setFieldValue(name, "");
            }}
          />
        </div>

      ) : (
        <>
          {loading ? (
            <ClipLoader />
          ) : (
            <input
              ref={ref}
              type="file"
              onChange={async (e) => {
                console.log(e.currentTarget.files[0].size);
                console.log(bytesToMegaBytes(e.currentTarget.files[0].size));
                if (bytesToMegaBytes(e.currentTarget.files[0].size) < 30) {
                  setLoading(true);
                  try {
                    const dataObj = {
                      location: location,
                      file: e.currentTarget.files[0],
                    }
                    if (fileName) {
                      dataObj.fileName = fileName
                    }
                    const resp = await dispatch(
                      createObject(dataObj)
                    );
                    console.log(resp);
                    if (resp?.payload?.data?.[0]?.Location) {
                      setFinalString(resp.payload.data[0].Key);
                      formik.setFieldValue(name, resp.payload.data[0].Key);
                    } else {
                      throw new Error("Payload error");
                    }
                  } catch (error) {
                    if (error.message) {
                      toast.error(error.message);
                    } else {
                      toast.error("error uploading file");
                    }
                  } finally {
                    setLoading(false);
                  }
                } else {
                  formik.setFieldValue(name, null);
                  ref.current.value = "";
                  alert("file size too large");
                }
              }}
              {...props}
              className="w-full form-input"
              onBlur={formik.handleBlur}
            />
          )}
        </>
      )}

      {formik.errors[name] && formik.touched[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikDirectFileUpload;
