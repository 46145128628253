import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "location";

export const fetchLocations = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editLocation = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchLocation = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteLocation = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createLocation = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);


const locationSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchLocations, SLICE_NAME),
    ...createData.generateExtraReducers(createLocation, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteLocation, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchLocation, SLICE_NAME),
    ...editData.generateExtraReducers(editLocation, SLICE_NAME),
  },
});

export const getLocation = (state) => state.location;
export const { setEditId } = locationSlice.actions;
export default locationSlice.reducer;
