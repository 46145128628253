import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikFileInput from "../../components/formik/FormikFileInput";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useState } from "react";
import { exportCsv, generateOptions } from "../../utils/Utils";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice"
import { fetchCategories, getCategories } from "../../app/reducers/Category/categorySlice"
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from '../../components/progressBar/ProgressBar'
import * as Yup from "yup"
import { useMemo } from "react";
import { getAuth } from "../../app/reducers/Auth/authSlice";

const UploadCatalog = () => {
    const [errors, setErrors] = useState([]);
    const [progressLoading, setProgressLoading] = useState(false)
    const [currentItem, setCurrentItem] = useState(0)
    const [totalItem, setTotalItem] = useState(0)
    const [errorData,setErrorData] = useState([])
    const dispatch = useDispatch()
    const { users, loading: userLoading } = useSelector(getUsers)
    const { category, loading: categoryLoading } = useSelector(getCategories)
    const { user } = useSelector(getAuth)
    useEffect(() => {
        dispatch(fetchUsers({ role: 'manufacturer'}))
        dispatch(fetchCategories())
    }, [])

    const userData = useMemo(
        () => (users?.docs ? users.docs : []),
        [users]
    )

    const categoryData = useMemo(
        () => (category?.docs ? category.docs : []),
        [category]
    )

    const formik = useFormik({
        initialValues: {
            manufacturer: "",
            category: "",
            bulkUploadFile: null,
        },
        validationSchema: Yup.object({
            manufacturer: Yup.string().required(),
            category: Yup.string().required(),
            bulkUploadFile: Yup.mixed().required()
        }),
        onSubmit: async (values) => {
            const resp = await authAxiosInstance.post(
                "catalog/upload/bulk-upload-3",
                values,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (resp.data?.data) {
                let csvData = resp.data?.data.docs
                if (csvData.length > 0) {
                    setProgressLoading(true)
                    setTotalItem(csvData.length)
                    for (let i = 0; i < csvData.length; i++) {
                        try {
                            let data = { ...csvData[i],
                                manufacturer:values.manufacturer,
                                category: values.category,
                                type:'Old',
                                isEmbroidery: false,
                                createdBy: { creator:user.name, createdRole: user.role }
                            }
                            const response = await authAxiosInstance.post(`catalog`,data)
                            if (response.data) {
                                setCurrentItem((prevState) => prevState + 1)
                            }
                        }
                        catch (error) {
                            if (error.response.data) {
                                setCurrentItem((prevState) => prevState + 1)
                                setErrorData(prev => [...prev, { ...csvData[i], error: error.response?.data?.error }])
                            }
                        }
                    }
                }
            }
        },
    });
    return (
        <PageWithCard heading="Upload Old Catalog">
            {errors.length > 0 && (
                <p
                    className="text-red-400 underline mb-4"
                    onClick={async () => {
                        exportCsv(errors);
                    }}
                >
                    Errors
                </p>
            )}
            {progressLoading ?
                <ProgressBar currentItem={currentItem} totalItem={totalItem} errorData={errorData} />
                : (
                    <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
                        {userLoading ? (
                            <ClipLoader />
                        ) : (
                            <FormikSelectGroup
                                name="manufacturer"
                                label="Manufacturer"
                                formik={formik}
                                options={generateOptions({
                                    array: userData,
                                    valueField: "_id",
                                    labelField: "name",
                                })}
                            />
                        )}
                        {categoryLoading ? (
                            <ClipLoader />
                        ) : (
                            <FormikSelectGroup
                                name="category"
                                label="Category"
                                formik={formik}
                                options={generateOptions({
                                    array: categoryData,
                                    valueField: "_id",
                                    labelField: "name",
                                })}
                            />
                        )}
                        <FormikFileInput name="bulkUploadFile" label="File" formik={formik} />
                        <div>
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    </form>
                )}
        </PageWithCard>
    );
};

export default UploadCatalog;
