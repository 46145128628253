import React from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { useState } from 'react';
import { useCallback } from 'react';
import { generateOptions } from '../../utils/Utils';
import PaginationClassic from '../../components/pagination/PaginationClassic';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { fetchUsers, getUsers } from '../../app/reducers/Users/userSlice';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { useMemo } from 'react';
import { fetchGreyEmbroideries, getGreyEmbroidery } from '../../app/reducers/GreyEmbroidery/greyEmbroiderySlice';
import ViewPurchasePanel from '../Grey/ViewPurchasePanel';
import EditPurchaseModel from '../Grey/EditPurchaseModel';
import { Eye } from 'react-feather';
import moment from "moment";
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import * as Yup from "yup";
import { fetchCatalogs, getCatalog } from '../../app/reducers/Catalog/catalogSlice';
import { fetchProducts } from '../../app/reducers/Product/productSlice';
import ImageComponent from '../../components/ImageComponent';

const ViewGreyEmbroidery = () => {
  const [page, setPage] = useState(1);
  const [viewPurchaseModel, setViewPurchaseModalOpen] = useState(false);
  const [viewingParty, setViewingParty] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { users } = useSelector(getUsers);
  const { greyEmbroidery, loading } = useSelector(getGreyEmbroidery)

  const data = useMemo(() => (greyEmbroidery?.docs ? greyEmbroidery.docs : []), [greyEmbroidery])
  const { catalog } = useSelector(getCatalog)

  const catalogData = useMemo(
    () => (catalog?.docs ? catalog.docs : []),
    [catalog]
  )
  const { location } = useSelector(getLocation);
  const locationdata = useMemo(
    () => (location?.docs ? location.docs : []),
    [location]
  );
  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchLocations());
    dispatch(fetchCatalogs())

  }, []);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: [],
      status: [],
      manufacturer: [],
      from: moment().startOf("day").valueOf(),
      to: moment().endOf("day").valueOf(),
      type: [],
      catalog: []
    },
    validationSchema: Yup.object({
      from: Yup.number(),
      to: Yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      const checkData = {
        location: { $in: values.location },
        manufacturer: { $in: values.manufacturer },
        status: { $in: values.status },
        type: { $in: values.type },
        catalog: { $in: values.catalog },
        createdAt: {
          $gte: values.from,
          $lte: values.to,
        },
      };
      dispatch(fetchGreyEmbroideries({ ...checkData, populate: true, page }));
      // formik.values.photo = ''
      // resetForm()
    },
  })



  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        // console.log(row);
        return (
          <ImageComponent src={row.original?.greyColorData?.image}
            style={{ width: "120px" }} />
          // <img
          //   src={row.original?.greyColorData?.image}
          //   style={{ width: "120px" }}
          // />
        );
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      Header: "Grey Quality",
      accessor: "greyQualityData.name",
    },
    {
      Header: "Grey Color",
      accessor: "greyColorData.color",
    },
    {
      Header: "Location",
      accessor: "locationData.name",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Manufacturer",
      accessor: "manufacturerData.name",
    },

    {
      Header: "Ordered Total",
      accessor: "quantity",
    },
    {
      Header: "Ordered Remaining",
      accessor: "remaining",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Eye
              size={18}
              color="green"
              onClick={() => {
                setViewPurchaseModalOpen(true);
                setViewingParty(row.original);
              }}
            />
            {/* <Edit2
                            color='blue'
                            onClick={() => {
                                dispatch(setEditId(row.original._id));
                            }}
                        /> */}
            {/* {row.original.status == "settled" ? (
              <div>Settled</div>
            ) : row.original.status == "completed" ? (
              ""
            ) : (
              <PrimaryButton onClick={() => handleSettled(row.original)}>
                Settled
              </PrimaryButton>
            )} */}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    let data = {
      location: { $in: formik.values.location },
      manufacturer: { $in: formik.values.manufacturer },
      status: { $in: formik.values.status },
      type: { $in: formik.values.type },
      catalog: { $in: formik.values.catalog },
      createdAt: {
        $gte: formik.values.from,
        $lte: formik.values.to,
      },
    };

    dispatch(
      fetchGreyEmbroideries({
        ...data,

        populate: true,
        page,
      })
    );
  }, [page]);




  const debouncedSearch = useCallback(
    _.debounce((search) => {
      let data = {
        location: { $in: formik.values.location },
        manufacturer: { $in: formik.values.manufacturer },
        status: { $in: formik.values.status },
      };
      dispatch(
        fetchGreyEmbroideries({
          ...data,

          search: search,
          populate: true,
        })
      );
    }, 300),
    []
  );
  return (
    <>
      <ViewPurchasePanel
        open={viewPurchaseModel}
        setOpen={setViewPurchaseModalOpen}
        party={viewingParty}
      />
      <EditPurchaseModel values={formik.values} />
      <PageWithCard heading='View Grey Embroidery'>
        <form onSubmit={formik.handleSubmit}>
          <FormikMultiSelect
            formik={formik}
            label="Manufacturer"
            name="manufacturer"
            options={generateOptions({
              array: users ? users.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
            required
          />
          <FormikMultiSelect
            formik={formik}
            label="Location"
            name="location"
            required
            options={generateOptions({
              array: locationdata,
              valueField: "_id",
              labelField: "name",
            })}
          />
          <FormikMultiSelect
            formik={formik}
            label="Status"
            name="status"
            required
            options={generateOptions({
              array: [
                { label: "Pending", value: "pending" },
                { label: "Completed", value: "completed" },
                { label: "Incomplete", value: "incomplete" },
              ],
              valueField: "value",
              labelField: "label",
            })}
          />
          <FormikMultiSelect
            formik={formik}
            label="Type"
            name="type"
            required
            options={generateOptions({
              array: [
                { label: 'finished', value: 'finished' }, { label: 'unfinished', value: 'unfinished' }, { label: 'unfinishedEmbroidery', value: 'unfinished embroidery' }
              ],
              valueField: "value",
              labelField: "label",
            })}
          />
          <FormikMultiSelect
            formik={formik}
            label="Catalog"
            name="catalog"

            required
            options={generateOptions({
              array: catalogData,
              valueField: "name",
              labelField: "name",
            })}
          />
          <FormikInputDateGroup
            formik={formik}
            label="From"
            name="from"
            required
          />
          <FormikInputDateGroup formik={formik} label="To" name="to" required />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
        <TableWithHeadingAndGlobalSearch
          heading="Grey Embroidery Orders"
          data={data}
          columns={columns}
          loading={loading}
          searchFunction={(value) => {
            debouncedSearch(value);
          }}
        />
        <PaginationClassic paginationDetails={greyEmbroidery} setPage={setPage} />
      </PageWithCard>
    </>
  )
}

export default ViewGreyEmbroidery