import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "greyQuality";

export const fetchGreyQualities = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editGreyQuality = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchGreyQuality = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteGreyQuality = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createGreyQuality = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

export const createGreyQualityWithFile = createAsyncThunk(
  "greyQuality",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const resp = await authAxiosInstance.post("greyQuality", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(resp,"cehck")
      return resp.data;
    } catch (error) {}
  }
);

const greyQualitySlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchGreyQualities, SLICE_NAME),
    ...createData.generateExtraReducers(createGreyQuality, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteGreyQuality, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchGreyQuality, SLICE_NAME),
    ...editData.generateExtraReducers(editGreyQuality, SLICE_NAME),
    [createGreyQualityWithFile.pending]: (state, action) => {
      state.loading = true;
    },
    [createGreyQualityWithFile.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createGreyQualityWithFile.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const getGreyQualities = (state) => state.greyQuality;
export const { setEditId } = greyQualitySlice.actions;
export default greyQualitySlice.reducer;
