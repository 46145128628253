import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import {
  fetchCreditBooks,
  getCreditBook,
} from "../../app/reducers/CreditBook/creditBookSlice";
import QueryString from "qs";
import { useMemo } from "react";
import { Edit2 } from "react-feather";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import CustomerReceiveModal from "./CustomerReceiveModal";
import CustomerAddCreditNote from "./CustomerAddCreditNote";
import CustomerAddOpening from "./CustomerAddOpening";
import CustomerAddDebitNote from "./CustomerAddDebitNote";
import { authAxiosInstance } from "../../utils/axiosConfig";
import PaginationClassic from "../../components/pagination/PaginationClassic";

const CustomerDetail = () => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [showReceivedModal, setShowReceivedModal] = useState(false);
  const [showAddCreditModal, setShowAddCreditModal] = useState(false);
  const [showAddCreditNote, setShowAddCreditNote] = useState(false);
  const [showAddOpening, setShowAddOpening] = useState(false);
  const [showDebitNote, setShowDebitNote] = useState(false);
  const [saleData, setSaleData] = useState("");
  const [currentCustomerBalance, setCurrentCustomerBalance] = useState(null);

  const { loading, creditBook } = useSelector(getCreditBook);

  useEffect(async () => {
    if (customerId) {
      dispatch(
        fetchCreditBooks({
          user: customerId,
          populate: true,
          sort: { createdAt: 1 },
          page,
        })
      );

      const query = {
        user: customerId,
      };
      const string = QueryString.stringify(query);
      const response = await authAxiosInstance.get(
        `creditBook/currentBalance?${string}`
      );
      if (response) {
        const currentData = response?.data?.data?.currentCustomerCreditBalance;
        setCurrentCustomerBalance(currentData);
      }
    }
  }, [customerId, page]);

  const data = useMemo(
    () => (creditBook?.docs ? creditBook.docs : []),
    [creditBook]
  );

  const debouncedSearch = useCallback(
    _.debounce(async (search) => {
      dispatch(
        fetchCreditBooks({
          user: customerId,
          search: search,
          populate: true,
          page,
        })
      );
    }, 300),
    []
  );

  const colsMemo = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "amount",
      accessor: "amount",
    },
    {
      Header: "balance",
      accessor: "balance",
    },
    {
      Header: "Notes",
      accessor: "notes",
    },
  ];
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="mb-8">
        {/* Title */}
        <h2 className="text-xl md:text-3xl text-slate-800 font-bold">
          {data && data.length > 0 && data[0]?.userData?.name}
        </h2>
        {currentCustomerBalance &&
          currentCustomerBalance?.map((el) => (
            <h4 className="mb-4">{el?.currentBalance}</h4>
          ))}
        <div className="flex gap-4">
          <PrimaryButton
            onClick={(e) => {
              e.stopPropagation();
              setSaleData(
                data && data.length > 0 ? data[0] : { user: customerId }
              );
              setShowReceivedModal(true);
            }}
          >
            Receive Amount
          </PrimaryButton>
          {/* <PrimaryButton
          onClick={(e) => {
            e.stopPropagation();
            setSaleData(data && data.length > 0 ? data[0] : { user: customerId,balance: 0})
            setShowAddCreditModal(true);
          }}
        >
          Add Entry
        </PrimaryButton> */}
          <PrimaryButton
            onClick={(e) => {
              e.stopPropagation();
              setSaleData(
                data && data.length > 0 ? data[0] : { user: customerId }
              );
              setShowAddCreditNote(true);
            }}
          >
            Add Credit Note
          </PrimaryButton>
          <PrimaryButton
            onClick={(e) => {
              e.stopPropagation();
              setSaleData(
                data && data.length > 0 ? data[0] : { user: customerId }
              );
              setShowAddOpening(true);
            }}
          >
            Add Opening Balance
          </PrimaryButton>
          <PrimaryButton
            onClick={(e) => {
              e.stopPropagation();
              setSaleData(
                data && data.length > 0 ? data[0] : { user: customerId }
              );
              setShowDebitNote(true);
            }}
          >
            Add Debit Note
          </PrimaryButton>
        </div>
      </div>
      <CustomerReceiveModal
        setShowReceivedModal={setShowReceivedModal}
        showReceivedModal={showReceivedModal}
        saleData={saleData}
      />
      {/* <CustomerAddCredit
        setShowAddCreditModal={setShowAddCreditModal}
        showAddCreditModal={showAddCreditModal}
        saleData={saleData}
      /> */}
      <CustomerAddCreditNote
        setShowAddCreditNote={setShowAddCreditNote}
        showAddCreditNote={showAddCreditNote}
        saleData={saleData}
      />
      <CustomerAddOpening
        setShowAddOpening={setShowAddOpening}
        showAddOpening={showAddOpening}
        saleData={saleData}
      />
      <CustomerAddDebitNote
        setShowDebitNote={setShowDebitNote}
        showDebitNote={showDebitNote}
        saleData={saleData}
      />
      {data && (
        <>
          <TableWithHeadingAndGlobalSearch
            heading="Credit Book"
            data={data}
            columns={colsMemo}
            searchFunction={debouncedSearch}
          />
        </>
      )}
      {/* <div className="bg-white shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px"></div>
      </div> */}
      <PaginationClassic paginationDetails={creditBook} setPage={setPage} />
    </div>
  );
};

export default CustomerDetail;
