import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikFileInput from "../../components/formik/FormikFileInput";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useState } from "react";
import { exportCsv, generateOptions } from "../../utils/Utils";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice"
import { fetchCategories, getCategories } from "../../app/reducers/Category/categorySlice"
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from '../../components/progressBar/ProgressBar'
import * as Yup from "yup"
import { useMemo } from "react";
import QueryString from "qs";

const UploadProduct = () => {
    const [errors, setErrors] = useState([]);
    const [progressLoading, setProgressLoading] = useState(false)
    const [currentItem, setCurrentItem] = useState(0)
    const [totalItem, setTotalItem] = useState(0)
    const [errorData,setErrorData] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchUsers({ role: 'manufacturer'}))
        dispatch(fetchCategories())
    }, [])


    const formik = useFormik({
        initialValues: {
            bulkUploadFile: null,
        },
        validationSchema: Yup.object({
            bulkUploadFile: Yup.mixed().required()
        }),
        onSubmit: async (values) => {
            const resp = await authAxiosInstance.post(
                "catalog/upload/bulk-upload-3",
                values,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (resp.data?.data) {
                let csvData = resp.data?.data.docs
                if (csvData.length > 0) {
                    setProgressLoading(true)
                    setTotalItem(csvData.length)
                    for (let i = 0; i < csvData.length; i++) {
                        try {
                            let catalog = ''
                            let category= ''
                            const string = QueryString.stringify({name: csvData[i].name});
                            const catalogDetail = await authAxiosInstance.get(`catalog?${string}`)
                            if (catalogDetail?.data?.data?.docs.length > 0) {
                                console.log(catalogDetail?.data?.data?.docs[0])
                                catalog = catalogDetail?.data?.data?.docs[0]._id
                                category = catalogDetail?.data?.data?.docs[0].category
                            }else {
                                throw new Error('Catalog Not found')
                            }
                            let data = { ...csvData[i],
                                catalog: catalog,
                                category: category,
                                catalogId: csvData[i].sku.split('/')[0],
                                type:'Old',
                            }
                            const response = await authAxiosInstance.post(`product`,data)
                            if (response.data) {
                                setCurrentItem((prevState) => prevState + 1)
                            }
                        }
                        catch (error) {
                            console.log(error)
                            if (error?.response?.data) {
                                setCurrentItem((prevState) => prevState + 1)
                                setErrorData(prev => [...prev, { ...csvData[i], error: error.response?.data?.error }])
                            }
                        }
                    }
                }
            }
        },
    });
    return (
        <PageWithCard heading="Upload Old Product">
            {errors.length > 0 && (
                <p
                    className="text-red-400 underline mb-4"
                    onClick={async () => {
                        exportCsv(errors);
                    }}
                >
                    Errors
                </p>
            )}
            {progressLoading ?
                <ProgressBar currentItem={currentItem} totalItem={totalItem} errorData={errorData} />
                : (
                    <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
                        <FormikFileInput name="bulkUploadFile" label="File" formik={formik} />
                        <div>
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    </form>
                )}
        </PageWithCard>
    );
};

export default UploadProduct;
