import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikFileInput from "../../components/formik/FormikFileInput";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { getUsers, fetchUsers } from "../../app/reducers/Users/userSlice";
import { generateOptions } from "../../utils/Utils";
import {
  fetchPrintPOs,
  getPrintPO,
  updatePrintPurchaseOrder,
  reset,
} from "../../app/reducers/PrintPurchaseOrder/printPurchaseSlice";
import { useMemo } from "react";
import { useState } from "react";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import moment from "moment";
import ImageComponent from "../../components/ImageComponent";

const inputStyle = {
  width: "137px",
  borderColor: "#e4e6fc",
  borderRadius: "7px",
};

const ReceivePrintOrder = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset({}));
  }, []);

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
  }, []);

  const { users } = useSelector(getUsers);
  const { printPO } = useSelector(getPrintPO);
  const [manufacturer, setManufacturere] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const printPurchaseOrderData = useMemo(
    () =>
      printPO?.docs
        ? printPO.docs.filter(
          (d) => d.status != "completed" && d.status != "settled"
        )
        : [],
    [printPO]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      manufacturer: manufacturer ? manufacturer : "",
      poData: printPurchaseOrderData
        ? printPurchaseOrderData.map((d) => {
          return {
            ...d,
            activate: false,
            finished: 0,
            damaged: 0,
            shortage: 0,
            returned: 0,
          };
        })
        : [],
    },
    onSubmit: async (values, { resetForm }) => {
      resetForm();
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, []);

  const submitDetails = async (e, index) => {
    try {
      setIsSubmitting(true);
      e.preventDefault();
      let detail = formik.values.poData[index];
      let received = [
        {
          date: new Date(),
          finished: detail.finished,
          damaged: detail.damaged,
          shortage: detail.shortage,
          returned: detail.returned,
        },
      ];
      let data = {
        ...detail,
        received: [...detail.received, ...received],
        finished: detail.finished,
        damaged: detail.damaged,
        shortage: detail.shortage,
        returned: detail.returned,
      };
      await dispatch(updatePrintPurchaseOrder(data));
      await dispatch(
        fetchPrintPOs({
          manufacturer: formik.values.manufacturer,
          populate: true,
          status: { $in: ["pending", "incomplete"] },
        })
      );
      setManufacturere(manufacturer);
      formik.setFieldValue("manufacturer", formik.values.manufacturer);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="p-10">
      <h1 className="text-3xl font-bold">Receive Print Order</h1>
      <br></br>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <FormikSelectGroup
          formik={formik}
          label="Manufacturer"
          name="manufacturer"
          // value={formik.values.manufacturer}
          onChange={async (data) => {
            await dispatch(
              fetchPrintPOs({
                manufacturer: data.value,
                populate: true,
                status: { $in: ["pending", "incomplete"] },
              })
            );
            setManufacturere(data.value);
            formik.setFieldValue("manufacturer", data.value);
          }}
          required
          options={generateOptions({
            array: users ? users.docs : [],
            valueField: "_id",
            labelField: "name",
          })}
        />
        {formik.values.manufacturer && (
          <>
            <FormikInputGroup
              label="Total Pieces Pending"
              name=""
              value={formik.values.poData.reduce((acc, ele) => {
                if (ele.greyData.unit === "pcs") {
                  acc = acc + ele.remaining;
                }
                return acc;
              }, 0)}
              formik={formik}
              readOnly
            />
            <FormikInputGroup
              label="Total Meters Pending"
              name=""
              value={formik.values.poData.reduce((acc, ele) => {
                if (ele.greyData.unit === "meter") {
                  const sum = parseFloat(acc + ele.remaining);
                  acc = sum.toFixed(2);
                }
                return acc;
              }, 0)}
              formik={formik}
              readOnly
            />
          </>
        )}
        {/* border-collapse border border-slate-1000 table-auto w-full divide-y divide-slate-400 */}
        <div className="relative overflow-x-auto">
          <table
            className="table-auto w-full border text-center"
            style={{ padding: "10px" }}
          >
            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200 p-2">
              <tr>
                <th scope="col" style={{ padding: "15px" }}>
                  Chalan NO
                </th>
                <th scope="col">Grey Quality</th>
                <th scope="col">Grey Color</th>
                <th scope="col" style={{ width: "170px" }}>
                  Photo
                </th>
                <th scope="col">Unit</th>
                <th scope="col">Ordered Total</th>
                <th scope="col">Ordered Remaining</th>
                <th scope="col">Finished</th>
                <th scope="col">Damaged</th>
                <th scope="col">Shortage</th>
                <th scope="col">Return</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-200">
              {formik.values.poData &&
                formik.values.poData.length > 0 &&
                formik.values.poData.map((grey, index) => (
                  <tr>
                    <td>{grey.chalan}.</td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={{
                          ...inputStyle,
                          color:
                            moment(new Date()).unix() >
                            moment(grey.expected_delivery_date).unix() &&
                            "red",
                        }}
                        value={grey?.greyData?.name}
                        name={`greyQuality.${index}`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={{
                          ...inputStyle,
                          color:
                            moment(new Date()).unix() >
                            moment(grey.expected_delivery_date).unix() &&
                            "red",
                        }}
                        value={grey?.greyColorData?.color}
                        name={`greyQuality.${index}`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <div
                        style={{
                          width: "170px",
                          height: "auto",
                          padding: "10px",
                        }}
                      >
                        <ImageComponent src={grey?.greyColorData?.image} alt="nothing" />
                      </div>
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={grey?.greyData?.unit}
                        name={`unit.${index}`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={grey?.quantity}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        type="number"
                        formik={formik}
                        value={formik.values.poData[index]?.remaining}
                        style={inputStyle}
                        name={`poData.${index}.remaining`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        type="number"
                        min={0}
                        formik={formik}
                        style={{
                          width: "137px",
                          backgroundColor:
                            !formik.values.poData[index].activate &&
                            "#D8D8D880",
                          borderColor: "#e4e6fc",
                          borderRadius: "7px",
                        }}
                        disabled={!formik.values.poData[index].activate}
                        name={`poData.${index}.finished`}
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        type="number"
                        formik={formik}
                        min={0}
                        style={{
                          width: "137px",
                          backgroundColor:
                            !formik.values.poData[index].activate &&
                            "#D8D8D880",
                          borderColor: "#e4e6fc",
                          borderRadius: "7px",
                        }}
                        disabled={!formik.values.poData[index].activate}
                        name={`poData.${index}.damaged`}
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        type="number"
                        min={0}
                        formik={formik}
                        style={{
                          width: "137px",
                          backgroundColor:
                            !formik.values.poData[index].activate &&
                            "#D8D8D880",
                          borderColor: "#e4e6fc",
                          borderRadius: "7px",
                        }}
                        disabled={!formik.values.poData[index].activate}
                        name={`poData.${index}.shortage`}
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        type="number"
                        min={0}
                        formik={formik}
                        style={{
                          width: "137px",
                          backgroundColor:
                            !formik.values.poData[index].activate &&
                            "#D8D8D880",
                          borderColor: "#e4e6fc",
                          borderRadius: "7px",
                        }}
                        disabled={!formik.values.poData[index].activate}
                        name={`poData.${index}.returned`}
                      />
                    </td>
                    <td className="p-3">
                      <div style={{ display: "flex", gap: "10px" }}>
                        {!formik.values.poData[index].activate && (
                          <PrimaryButton
                            onClick={() =>
                              formik.setFieldValue(
                                `poData.${index}.activate`,
                                true
                              )
                            }
                          >
                            Activate
                          </PrimaryButton>
                        )}
                        {formik.values.poData[index].activate && (
                          <>
                            <DangerButton
                              onClick={() =>
                                formik.setFieldValue(
                                  `poData.${index}.activate`,
                                  false
                                )
                              }
                            >
                              Deactivate
                            </DangerButton>
                            <PrimaryButton
                              disabled={isSubmitting}
                              onClick={(e) => submitDetails(e, index)}
                            >
                              Submit
                            </PrimaryButton>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
};

export default ReceivePrintOrder;
