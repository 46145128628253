import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import qs from "qs";
import { generateStateSingle } from "../../generators/generateState";
import {
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
import { generateSetEditId } from "../../generators/generateReducers";

const initialState = generateStateSingle("users");

export const createUser = createAsyncThunk("users/createUser", async (data) => {
  try {
    const resp = await authAxiosInstance.post("user/register", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toast.success("user created");
    return resp.data;
  } catch (error) {
    console.error(error);
    if(error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    }
    toast.error("failed to create user");
  }
});

export const fetchUsers = fetchData.generateThunk("users", "user");
export const deleteUser = deleteData.generateThunk("users", "user");
export const fetchUser = fetchEditData.generateThunk("users", "user");
export const editUser = editData.generateThunk("users", "user");

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    [createUser.pending]: (state) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createUser.rejected]: (state) => {
      state.loading = false;
    },
    ...fetchData.generateExtraReducers(fetchUsers, "users"),
    ...fetchEditData.generateExtraReducers(fetchUser, "users"),
    ...editData.generateExtraReducers(editUser, "users"),
    ...deleteData.generateExtraReducers(deleteUser, "users"),
  },
});

export const getUsers = (state) => state.users;
export const { setEditId } = userSlice.actions;
export default userSlice.reducer;
