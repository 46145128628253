import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikFileInput from "../../components/formik/FormikFileInput";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { getUsers, fetchUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchGreyQualities,
  getGreyQualities,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import {
  fetchGreyPurchaseOrders,
  getGreyPo,
  updateGreyPurchaseOrder,
  upd,
} from "../../app/reducers/GreyPurchaseOrder/greyPOSlice";
import { Edit2, Trash } from "react-feather";
import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  createDirectPurchaseOrder,
  getDirectPo,
} from "../../app/reducers/DirectPurchaseOrder/directPOSlice";
import {
  getGreyColor,
  fetchGreyColors,
} from "../../app/reducers/GreyColor/greyColorSlice";
const inputStyle = {
  width: "137px",
  borderColor: "#e4e6fc",
  borderRadius: "7px",
};

const DirectPurchaseOrder = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchLocations());
  }, []);

  const { greyQuality } = useSelector(getGreyQualities);
  const { greyColor } = useSelector(getGreyColor);
  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const locationData = useMemo(
    () => (location?.docs ? location.docs : []),
    [location]
  );
  const greyQualityData = useMemo(
    () => (greyQuality?.docs ? greyQuality.docs : []),
    [greyQuality]
  );
  const greyColorData = useMemo(
    () => (greyColor?.docs ? greyColor.docs : []),
    [greyColor]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      manufacturer: "",
      grey: "",
      greyColor: "",
      location: "",
      quantity: "",
      damaged: "",
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      location: Yup.string().required(),
      grey: Yup.string().required(),
      greyColor: Yup.string().required(),
      quantity: Yup.number().required(),
      damaged: Yup.number().required(),
    }),
    onSubmit: async (values) => {
      let findGrey = greyQualityData.find((d) => d._id == values.grey);
      await dispatch(
        createDirectPurchaseOrder({ ...values, grey_unit: findGrey.unit })
      );
      formik.resetForm();
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, []);

  return (
    <div className="p-10">
      <h1 className="text-3xl font-bold">Direct Purchase Order</h1>
      <br></br>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <FormikSelectGroup
          formik={formik}
          label="Manufacturer"
          name="manufacturer"
          onChange={async (data) => {
            dispatch(
              fetchGreyQualities({
                manufacturer: data.value,
                populate: true,
              })
            );
            formik.setFieldValue("manufacturer", data.value);
          }}
          required
          options={generateOptions({
            array: users ? users.docs : [],
            valueField: "_id",
            labelField: "name",
          })}
        />
        {formik.values.manufacturer && (
          <>
            <FormikSelectGroup
              formik={formik}
              label="Location"
              name="location"
              required
              options={generateOptions({
                array: locationData,
                valueField: "_id",
                labelField: "name",
              })}
            />
            <FormikSelectGroup
              formik={formik}
              label="Grey Quality"
              name="grey"
              onChange={(selectedOption) => {
                dispatch(
                  fetchGreyColors({ greyQuality: selectedOption.value })
                );
                formik.setFieldValue("grey", selectedOption.value);
              }}
              required
              options={generateOptions({
                array: greyQualityData,
                valueField: "_id",
                labelField: "name",
              })}
            />
            {formik.values.grey && (
              <>
                <FormikSelectGroup
                  formik={formik}
                  label="Grey Color"
                  name="greyColor"
                  required
                  options={generateOptions({
                    array: greyColorData,
                    valueField: "_id",
                    labelField: "color",
                  })}
                />
                <FormikInputGroup
                  label="Quantity"
                  type="number"
                  formik={formik}
                  name="quantity"
                  required
                />
                <FormikInputGroup
                  label="Damaged"
                  type="number"
                  formik={formik}
                  name="damaged"
                  required
                />
              </>
            )}
          </>
        )}
        <div className="mt-4">
          {
            <PrimaryButton disabled={formik.isSubmitting} type="submit">
              Submit
            </PrimaryButton>
          }
        </div>
      </form>
    </div>
  );
};

export default DirectPurchaseOrder;
