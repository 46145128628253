import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikFileInput from "../../components/formik/FormikFileInput";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { createUser, getUsers } from "../../app/reducers/Users/userSlice";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";

const AddCustomer = () => {
  const { loading } = useSelector(getUsers);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      companyName: "",
      username: "",
      email: "",
      role: "",
      gstNumber: "",
      address: "",
      __t: "customer",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      companyName: Yup.string(),
      username: Yup.string().required(),
      email: Yup.string().email(),
      address: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(
        createUser({
          ...values,
          role: "customer",
        })
      ).then((res) => {
        if (res?.payload?.data?.user?._id) {
          navigate("/sale", { state: { customer: res.payload.data.user } });
        }
      });
    },
  });
  return (
    <PageWithCard heading="Add Users">
      <div className="text-slate-800 font-semibold mb-4">User Detail</div>
      {loading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup label="Name" formik={formik} name="name" required />
          <FormikInputGroup
            label="Company Name"
            formik={formik}
            name="companyName"
          />
          <FormikInputGroup
            label="Phone"
            type="number"
            formik={formik}
            name="username"
            required
          />
          <FormikInputGroup label="Email" formik={formik} name="email" />
          <FormikTextareaGroup label="Address" formik={formik} name="address" />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default AddCustomer;
