import moment from "moment";
import TransactionPanel from "../../components/infrastructure/Panels/TransactionPanel";
import Tab from "../../components/infrastructure/Tabs/Tab";
import Tabs from "../../components/infrastructure/Tabs/Tabs";
import ImageComponent from "../../components/ImageComponent";

export default function ViewSalePanel({
  salePanelOpen,
  setSalePanelOpen,
  sale,
}) {
  return (
    <TransactionPanel
      setTransactionPanelOpen={setSalePanelOpen}
      transactionPanelOpen={salePanelOpen}
    >
      <div className="max-w-md mx-auto lg:max-w-none">
        {sale.saleNumber && (
          <div className="text-slate-800 font-semibold  mb-1">
            <p className="mb-3 capitalize">Sale Number - {sale.saleNumber}</p>
          </div>
        )}
        <Tabs headers={["Sale Info", "Products"]}>
          <Tab>
            <div className="bg-white p-4 rounded-lg text-md my-2">
              <p>Date</p>
              <p className="text-xs">
                {moment(sale.createdAt).format("Do MMMM YYYY")}
              </p>
            </div>
            <div className="bg-white p-4 rounded-lg text-md my-2">
              <p>Location</p>
              <p className="text-xs">{sale.location?.name}</p>
            </div>
            <div className="bg-white p-4 rounded-lg text-md my-2">
              <p>Customer Details</p>
              <p className="text-xs">Name : {sale.customerName}</p>
              <p className="text-xs">Phone : {sale.customerPhone}</p>
            </div>
            <div className="bg-white p-4 rounded-lg text-md my-2">
              <p>Received Amount Details</p>
              {sale.receivedAmount?.map((ele, i) => (
                <p className="text-xs">
                  {i + 1}. {ele.method} - {ele.amount}
                </p>
              ))}
              <p className="mt-2 text-xs">
                Total -{" "}
                {sale.receivedAmount?.reduce((acc, val) => acc + val.amount, 0)}
              </p>
            </div>
          </Tab>
          <Tab>
            {sale.product?.map((prod) => (
              <div className="bg-white p-4 rounded-lg text-md my-2">
                {prod?.product_id?.image?.length > 0 ? (
                  <ImageComponent src={prod.product_id.image} className="h-32 mb-4" />
                ) : (
                  <p className="mb-4 text-center">----No Image----</p>
                )}
                <p className="text-xs">Catalog Name : {prod.catalog_id.name}</p>
                <p className="text-xs">Color : {prod.product_id.color}</p>
                <p className="text-xs">Quantity : {prod.qty}</p>
                <p className="text-xs">Selling Price : {prod.sellPrice}</p>
              </div>
            ))}
          </Tab>
        </Tabs>
      </div>
    </TransactionPanel>
  );
}
