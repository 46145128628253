import React from 'react'
import { useSelector } from 'react-redux'
import { getMediaLibrary } from '../app/reducers/MediaLibrary/mediaLibrarySlice'
import { useState } from 'react'
import ViewPhotoModal from './infrastructure/Modals/ViewPhotoModal'

const ImageComponent = ({ src, style, className, alt, baseUrlRequired = true }) => {
    const [photoModalOpen, setPhotoModalOpen] = useState(false)
    const [photoData, setPhotoData] = useState()
    const openImagePreview = (src, e) => {
        e.stopPropagation()
        setPhotoData(src)
        setPhotoModalOpen(true)
    }
    const { baseUrl } = useSelector(getMediaLibrary)
    return (
        <>
            <ViewPhotoModal
                modalOpen={photoModalOpen}
                setModalOpen={setPhotoModalOpen}
                photoData={photoData}
                baseUrlRequired
            />
            <img className={className ? className : ''} onClick={(e) => openImagePreview(src, e)} src={baseUrlRequired ? baseUrl + src : src} alt={alt ? alt : ""} style={style ? style : { width: '150px', height: "150px" }} />
        </>
    )
}

export default ImageComponent