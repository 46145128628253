import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "catalog";

export const fetchCatalogs = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editcatalog = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCatalog = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteCatalog = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createCatalog = createData.generateThunk(SLICE_NAME, SLICE_NAME);

export const discontinueCatalog = createAsyncThunk(
  "discontinueCatalog/catalog",
  async (data) => {
    try {
      console.log("Data", data);
      const discontinueCatalogRes = await authAxiosInstance.get(
        `productInventory/product-discontinue?catalog_id=${data}`
      );
      console.log("discontinueCatalogRes", discontinueCatalogRes);
    } catch (error) {
      console.log("Error discontinueCatalog==>", error);
    }
  }
);
const catalogSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchCatalogs, SLICE_NAME),
    ...createData.generateExtraReducers(createCatalog, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteCatalog, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchCatalog, SLICE_NAME),
    ...editData.generateExtraReducers(fetchEditData, SLICE_NAME),
  },
});

export const getCatalog = (state) => state.catalog;
export const { setEditId } = catalogSlice.actions;
export default catalogSlice.reducer;
