import { FieldArray, FormikProvider, useFormik } from "formik";
import numeral from "numeral";
import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import {
  BILL_NUMBER_PADDING,
  CASHBOOK_PAYMENT_METHODS,
  PAYMENT_METHODS,
  PAYMENT_TYPE,
} from "../../utils/dropdownOptions";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import { X } from "react-feather";
import { generateOptions } from "../../utils/Utils";
import { useEffect } from "react";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { createSale } from "../../app/reducers/Sale/saleSlice";
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { fetchCreditBooks } from "../../app/reducers/CreditBook/creditBookSlice";
import { fetchLocations, getLocation } from "../../app/reducers/Location/locationSlice";
import * as Yup from 'yup'

const CustomerAddOpening = ({
  showAddOpening,
  setShowAddOpening,
  saleData
}) => {


  const dispatch = useDispatch()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: 0,
      user: saleData && saleData.user,
      note: "",
      type: ""
    },
    validationSchema: Yup.object({
      user: Yup.string().required(),
      note: Yup.string().required(),
      type: Yup.string().required(),
      amount: Yup.number().min(1).required(),
  }),
    onSubmit: async (values) => {
      const data = {
        user: values.user,
        amount: values.amount,
        type: values.type,
        note: values.note
      }
      const productData = await authAxiosInstance.post(`creditBook/addOpeningBalance`, data);
      if (productData) {
        toast.success("Amount Added Successfully...")
        dispatch(fetchCreditBooks({ user: values.user, populate: true, sort: { createdAt: 1 } }))
        formik.resetForm()
        setShowAddOpening(false)
      }
    }
  });


  return (
    <ModalBasic
      modalOpen={showAddOpening}
      setModalOpen={setShowAddOpening}
    >
      {/* <h1 className="p-10 font-bold">Under Development</h1> */}
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 p-4">
        <FormikInputGroup
          label="User"
          formik={formik}
          readOnly
          name="user"
        />
        <FormikInputGroup
          label="Amount"
          formik={formik}
          type="number"
          name="amount"
        />
        
        <FormikSelectGroup
          name="type"
          label="Payment type"
          formik={formik}
          options={generateOptions({
            array: PAYMENT_TYPE,
            valueField: "value",
            labelField: "label",
          })}
        />
         <FormikInputGroup
          label="Notes"
          formik={formik}
          name="note"
        />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </ModalBasic>
  );
};

export default CustomerAddOpening;
