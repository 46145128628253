import { FieldArray, FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  fetchUsers, getUsers,
} from "../../app/reducers/Users/userSlice";
import EditModal from "../../components/infrastructure/Modals/EditModal";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import {
  generateOptions,
} from "../../utils/Utils";

import { getCatalog,editcatalog,setEditId, fetchCatalog } from "../../app/reducers/Catalog/catalogSlice";
import { fetchJobWorks,getJobWork } from "../../app/reducers/JobWork/jobWorkSlice";
import { useMemo } from "react";
import FormikCheckbox from "../../components/formik/FormikCheckbox";

const EditCatalogModel = () => {
  const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers({ role: 'manufacturer' }))
    },[])
    const {
        elementEditId,
        editDataLoading,
        elementEditData,
      } = useSelector(getCatalog);
  const {
    users
  } = useSelector(getUsers);

  const {
    jobWork
  } = useSelector(getJobWork);
    const data = useMemo(
    () => (jobWork?.docs ? jobWork.docs : []),
    [jobWork]
  )
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
    },
    onSubmit: async (values,{resetForm}) => {
          await dispatch(editcatalog({...values}));
          dispatch(fetchCatalog({ _id: elementEditId }))
          resetForm();
    },
  });
  useEffect(() => {
    if (elementEditId) {
      dispatch(fetchCatalog({ _id: elementEditId }));
    }
  }, [elementEditId]);

  return (
    <EditModal
      editProperty={elementEditId}
      setEditProperty={setEditId}
      getEditData={fetchCatalog}
      title="Edit Catalog"
    >
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4 p-4'>
         <FormikInputGroup
            label="SystemId"
            name="_id"
            formik={formik}
            required
            readOnly
          />
        <FormikInputGroup label='Name' formik={formik} name='name' required />
        <FormikInputGroup label='Price' type="number" formik={formik} name='price' required />
        <FormikSelectGroup
          formik={formik}
          label='Manufacturer'
          name='manufacturer'
          options={generateOptions({
            array: users ? users.docs : [],
            valueField: '_id',
            labelField: 'name',
          })}
          required
        />
        <FormikSelectGroup
          formik={formik}
          label='Default Job Name'
          name='defaultJobName'
          onChange={(optionData) => {
            console.log(optionData)
            let getDetail = data.find(d => d.name == optionData.value)
            formik.setFieldValue('defaultJobName',optionData.label)
            formik.setFieldValue('defaultJobPrice',getDetail?.price)
          }}
          options={generateOptions({
            array: data ? data : [],
            valueField: 'name',
            labelField: 'name',
          })}
          required
        />{
          formik.values.defaultJobName && 
        <FormikInputGroup label='Price' type="number" formik={formik} name='defaultJobPrice' required />
        }        
        <FormikCheckbox label='isEmbroidery' formik={formik} name='isEmbroidery' required />
        <div>
          <PrimaryButton type='submit'>Submit</PrimaryButton>
        </div>
      </form>
      )}
    </EditModal>
  );
};

export default EditCatalogModel;
