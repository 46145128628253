import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { Edit2, Eye, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  deleteUser,
  fetchUsers,
  getUsers,
  setEditUserId,
} from "../../app/reducers/Users/userSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import ViewPartyPanel from "./ViewUserPanel";

const ViewUsers = () => {
  const { loading, users } = useSelector(getUsers);
  const [viewUserModalOpen, setViewUserModalOpen] = useState(false);
  const [viewingUser, setViewingUser] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchUsers({ __t: { $ne: "customer" } }));
  }, []);
  console.log("users");
  console.log(users.docs);
  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Phone",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.phone ? row.original.phone : row.original.username}
          </span>
        );
      },
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Eye
              size={18}
              color="green"
              onClick={() => {
                setViewUserModalOpen(true);
                setViewingUser(row.original);
              }}
            />
            {/* <Edit2
              size={18}
              color="blue"
              onClick={() => {
                dispatch(setEditId(row.original._id));
                navigate("/editUser");
              }}
            /> */}
            <Trash
              size={18}
              color="red"
              onClick={async () => {
                await dispatch(
                  deleteUser({
                    id: row.original._id,
                  })
                );
                dispatch(fetchUsers());
              }}
            />
          </div>
        );
      },
    },
  ];
  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(() => (users?.docs ? users.docs : []), [users.docs]);
  return (
    <PageWithCard heading="View Users">
      <ViewPartyPanel
        setOpen={setViewUserModalOpen}
        open={viewUserModalOpen}
        user={viewingUser}
      />
      {loading ? (
        <ClipLoader />
      ) : (
        <TableWithHeadingAndSearch
          columns={columnsMemo}
          data={dataMemo}
          heading="Users"
        />
      )}
    </PageWithCard>
  );
};

export default ViewUsers;
