import React from "react";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import { generateOptions } from "../../utils/Utils";
import { fetchCatalogs } from "../../app/reducers/Catalog/catalogSlice";
import { useDispatch } from "react-redux";
import { fetchProducts } from "../../app/reducers/Product/productSlice";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";

export const JobWorkContainer = ({
  formik,
  index,
  arrayHelpers,
  jobWorkData,
}) => {
  console.log("index", index);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  return (
    <div
      className="relative p-4 mb-2"
      style={{
        border: "1px solid #d6c7c7",
        borderRadius: "5px",
      }}
      key={index}
    >
      <FormikAsyncSelect
        formik={formik}
        label="Select Catalog"
        getOptions={async (search) => {
          const resp = await dispatch(
            fetchCatalogs({
              search,
              isEmbroidery: formik.values.catalogType === "embroidery",
            })
          );
          console.log(resp);
          return resp.payload.data.docs.map((ele) => ({
            label: ele.name,
            value: ele._id,
          }));
        }}
        onChange={async (selectedOption) => {
          let id = {
            _id: selectedOption.value,
          };
          const stringify = QueryString.stringify(id);
          const catalogDetail = await authAxiosInstance.get(
            `catalog?${stringify}`
          );
          if (
            catalogDetail?.data &&
            catalogDetail.data?.data &&
            catalogDetail.data?.data?.docs.length > 0
          ) {
            formik.setFieldValue(
              `catalog.${index}.price`,
              catalogDetail.data?.data.docs[0].defaultJobPrice
            );
          }
          dispatch(
            fetchProducts({
              catalog: selectedOption.value,
            })
          )
            .then((result) => {
              console.log(result);
              setProducts(result?.payload?.data?.docs);
            })
            .catch((err) => {
              console.log(err);
            });
          formik.setFieldValue(
            `catalog.${index}.jobWorkName`,
            catalogDetail.data?.data.docs[0].defaultJobName
          );
          formik.setFieldValue(
            `catalog.${index}.catalog`,
            selectedOption.value
          );
        }}
        name={`catalog.${index}.catalog`}
        required
      />

      {formik?.errors?.catalog &&
      Array.isArray(formik?.errors?.catalog) &&
      formik?.errors?.catalog[index]?.catalog &&
      formik?.errors?.catalog[index]?.catalog ? (
        <p className="text-xs text-red-500">
          {formik.errors["catalog"][index].catalog}
        </p>
      ) : null}
      {formik.values.catalog[index].catalog && (
        <>
          <FormikInputGroup
            formik={formik}
            label="Price"
            name={`catalog.${index}.price`}
            type="number"
            required
          />
          <FormikSelectGroup
            formik={formik}
            label="Job Work"
            name={`catalog.${index}.jobWorkName`}
            options={generateOptions({
              array: jobWorkData,
              valueField: "name",
              labelField: "name",
            })}
            required
          />
          <FormikSelectGroup
            formik={formik}
            label="Select Color"
            name={`catalog.${index}.product`}
            onChange={async (selectedOption) => {
              if (!formik.values.location) {
                return alert("Please select location");
              }
              let productExist = formik.values.catalog.find(
                (d) =>
                  d.product == selectedOption.value &&
                  d.catalog == formik.values.catalog[index].catalog
              );
              if (productExist) {
                return toast.error("Product already exist");
              }
              let data = {
                product: selectedOption.value,
                location: formik.values.location,
              };
              const string = QueryString.stringify(data);
              const resp = await authAxiosInstance.get(
                `productInventory?${string}`
              );
              if (
                resp?.data &&
                resp.data?.data &&
                resp.data?.data?.docs.length > 0
              ) {
                if (formik.values.catalogType == "normal") {
                  if (resp.data?.data?.docs[0].unfinished <= 0) {
                    return toast.error("Unfinished orders not available");
                  }
                } else {
                  if (resp.data?.data?.docs[0].unfinishedEmbroidery <= 0) {
                    return toast.error("Unfinished orders not available");
                  }
                }
              } else {
                return toast.error("Data not found");
              }
              formik.setFieldValue(
                `catalog.${index}.product`,
                selectedOption.value
              );
            }}
            options={generateOptions({
              array: products,
              valueField: "_id",
              labelField: "color",
            })}
            required
          />
          {formik?.errors?.catalog &&
          Array.isArray(formik?.errors?.catalog) &&
          formik?.errors?.catalog[index]?.product &&
          formik?.errors?.catalog[index]?.product ? (
            <p className="text-xs text-red-500">
              {formik.errors["catalog"][index].product}
            </p>
          ) : null}
          <FormikInputGroup
            formik={formik}
            label="Quantity"
            type="number"
            value={formik.values.catalog[index].quantity}
            name={`catalog.${index}.quantity`}
            required
          />
          {formik?.errors?.catalog &&
          Array.isArray(formik?.errors?.catalog) &&
          formik?.errors?.catalog[index]?.quantity ? (
            <p className="text-xs text-red-500">
              {formik.errors["catalog"][index].quantity}
            </p>
          ) : null}
        </>
      )}
      <div>
        <DangerButton
          className="mt-3"
          onClick={() => {
            arrayHelpers.remove(index);
          }}
          type="button"
        >
          Remove
        </DangerButton>
      </div>
    </div>
  );
};
