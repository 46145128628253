import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { exportCsv, generateOptions } from "../../utils/Utils";
import { useMemo } from "react";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import { authAxiosInstance } from "../../utils/axiosConfig";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import { toast } from "react-toastify";
import QueryString from "qs";
import { CSVLink, CSVDownload } from "react-csv";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
const GenBarcode = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCatalogs({ populate: true }));
  }, []);

  const { loading: catalogLoading, catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);

  const data = useMemo(() => (catalog?.docs ? catalog.docs : []), [catalog]);
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );
  // console.log(currentItem, "index")
  function repeat(item, times) {
    let rslt = [];
    for (let i = 0; i < times; i++) {
      rslt.push(item);
    }
    return rslt;
  }
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      catalog: [
        {
          catalog: "",
          product: "",
          sku: "",
          quantity: 0,
        },
      ],
    },
    validationSchema: Yup.object({
      catalog: Yup.array()
        .of(
          Yup.object({
            catalog: Yup.string().required("Please select catalog"),
            product: Yup.string().required("Please select color"),
            sku: Yup.string().required("Please select sku"),
            quantity: Yup.number()
              .min(1, "Please enter minimum one number")
              .required("quantity is required"),
          })
        )
        .required()
        .min(1, "Please Add atleast one quantity"),
    }),
    onSubmit: async (values) => {
      if (values.catalog.length > 0) {
        let main = [];
        for (let i = 0; i < values.catalog.length; i++) {
          let arr = [];
          for (let j = 0; j < values.catalog[i].quantity; j++) {
            const string = QueryString.stringify({
              _id: values.catalog[i].product,
              populate: true,
            });
            const resp = await authAxiosInstance.get(`product?${string}`);
            if (resp?.data?.data?.docs) {
              let productDetail = resp?.data?.data?.docs[0];
              let data = {
                catalog_name: productDetail?.catalogData?.name,
                name: productDetail?.color,
                sku: productDetail?.sku,
              };
              arr.push(data);
            }
          }
          main.push(...arr);
        }
        const resp = await exportCsv(main);
        if (resp) {
          toast.success("File Downloaded Successfully...");
        }
      }
      formik.resetForm();
    },
  });

  return (
    <>
      <PageWithCard heading="Generate Barcode">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikProvider value={formik}>
            <FieldArray
              name="catalog"
              render={(arrayHelpers) => {
                return (
                  <div className="flex flex-col gap-2">
                    {/* <p>Address</p> */}
                    <div>
                      {formik.values.catalog.map((ele, index) => (
                        <div
                          className="relative p-4 mb-2"
                          style={{
                            border: "1px solid #d6c7c7",
                            borderRadius: "5px",
                          }}
                          key={index}
                        >
                          <FormikAsyncSelect
                            formik={formik}
                            label="Select Catalog"
                            getOptions={async (search) => {
                              const resp = await dispatch(
                                fetchCatalogs({ search })
                              );
                              console.log(resp);
                              return resp.payload.data.docs.map((ele) => ({
                                label: ele.name,
                                value: ele._id,
                              }));
                            }}
                            onChange={async (selectedOption) => {
                              dispatch(
                                fetchProducts({ catalog: selectedOption.value })
                              );
                              formik.setFieldValue(
                                `catalog.${index}.catalog`,
                                selectedOption.value
                              );
                            }}
                            name={`catalog.${index}.catalog`}
                            options={generateOptions({
                              array: data,
                              valueField: "_id",
                              labelField: "name",
                            })}
                            required
                          />
                          {formik?.errors?.catalog &&
                          Array.isArray(formik?.errors?.catalog) &&
                          formik?.errors?.catalog[index]?.catalog &&
                          formik?.errors?.catalog[index]?.catalog ? (
                            <p className="text-xs text-red-500">
                              {formik.errors["catalog"][index].catalog}
                            </p>
                          ) : null}
                          {formik.values.catalog[index].catalog && (
                            <>
                              <FormikSelectGroup
                                formik={formik}
                                label="Select Color"
                                name={`catalog.${index}.product`}
                                onChange={async (selectedOption) => {
                                  let productExist = formik.values.catalog.find(
                                    (d) =>
                                      d.product == selectedOption.value &&
                                      d.catalog ==
                                        formik.values.catalog[index].catalog
                                  );
                                  if (productExist) {
                                    return toast.error("Product already exist");
                                  }
                                  let data = {
                                    _id: selectedOption.value,
                                  };
                                  const string = QueryString.stringify(data);
                                  const resp = await authAxiosInstance.get(
                                    `product?${string}`
                                  );
                                  if (resp?.data?.data?.docs) {
                                    formik.setFieldValue(
                                      `catalog.${index}.sku`,
                                      resp?.data?.data?.docs[0].sku
                                    );
                                  }
                                  formik.setFieldValue(
                                    `catalog.${index}.product`,
                                    selectedOption.value
                                  );
                                }}
                                options={generateOptions({
                                  array: productData,
                                  valueField: "_id",
                                  labelField: "color",
                                })}
                                required
                              />
                              {formik?.errors?.catalog &&
                              Array.isArray(formik?.errors?.catalog) &&
                              formik?.errors?.catalog[index]?.product &&
                              formik?.errors?.catalog[index]?.product ? (
                                <p className="text-xs text-red-500">
                                  {formik.errors["catalog"][index].product}
                                </p>
                              ) : null}
                              <FormikInputGroup
                                formik={formik}
                                label="Quantity"
                                type="number"
                                value={formik.values.catalog[index].quantity}
                                name={`catalog.${index}.quantity`}
                                required
                              />
                              {formik?.errors?.catalog &&
                              Array.isArray(formik?.errors?.catalog) &&
                              formik?.errors?.catalog[index]?.quantity ? (
                                <p className="text-xs text-red-500">
                                  {formik.errors["catalog"][index].quantity}
                                </p>
                              ) : null}
                            </>
                          )}
                          <div>
                            <DangerButton
                              className="mt-3"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                              type="button"
                            >
                              Remove
                            </DangerButton>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <SecondaryButton
                        onClick={() => {
                          arrayHelpers.push("");
                        }}
                        type="button"
                      >
                        Add More
                      </SecondaryButton>
                    </div>
                  </div>
                );
              }}
            />
            {formik.errors["catalog"] &&
            !Array.isArray(formik.errors["catalog"]) &&
            formik.errors["catalog"] ? (
              <p className="text-xs text-red-500">{formik.errors["catalog"]}</p>
            ) : null}
          </FormikProvider>

          <div>
            <PrimaryButton type="submit" onClick={formik.handleSubmit}>
              Submit
            </PrimaryButton>
          </div>
        </form>
      </PageWithCard>
    </>
  );
};

export default GenBarcode;
