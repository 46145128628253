import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "product";

export const fetchProducts = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editProduct = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchProduct = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteProduct = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createProduct = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

export const createProductWithFile = createAsyncThunk(
  "product",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const resp = await authAxiosInstance.post("product", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(resp,"cehck")
      return resp.data;
    } catch (error) {}
  }
);

export const editProductWithFile = createAsyncThunk(
  "product",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await authAxiosInstance.patch("product", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if(resp.data) {
        toast.success(`Product edited successfully`);
      }
      return resp.data;
    } catch (error) {
      rejectWithValue(error)
    }
  }
);

const productSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchProducts, SLICE_NAME),
    ...createData.generateExtraReducers(createProduct, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteProduct, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchProduct, SLICE_NAME),
    ...editData.generateExtraReducers(editProduct, SLICE_NAME),
    [createProductWithFile.pending]: (state, action) => {
      state.loading = true;
    },
    [createProductWithFile.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createProductWithFile.rejected]: (state, action) => {
      state.loading = false;
    },
    [editProductWithFile.pending]: (state, action) => {
      state.loading = true;
    },
    [editProductWithFile.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [editProductWithFile.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const getProduct = (state) => state.product;
export const { setEditId } = productSlice.actions;
export default productSlice.reducer;
