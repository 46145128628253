import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import { ClipLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { Edit2, Trash, X } from 'react-feather'
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
// import EditProductModal from './EditProductModel'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton'
// import EditProduct from './EditProduct'
import { authAxiosInstance } from '../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { fetchGreyColors, getGreyColor, setEditId, deleteGreyColor, editGreyColor } from '../../app/reducers/GreyColor/greyColorSlice'
import EditColorModal from './EditColorModal'
import EditGreyModal from './EditGreyModal'
import { deleteSingleObject, fetchAssets, getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice'
import ViewPhotoModal from '../../components/infrastructure/Modals/ViewPhotoModal'
import ImageComponent from '../../components/ImageComponent'

const ViewGreyColor = () => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState()
    const [photoIndex, setPhotoIndex] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)
    const [editData, setEditData] = useState(null)
    const { baseUrl } = useSelector(getMediaLibrary);


    const { id } = useParams()

    useEffect(() => {
        dispatch(fetchGreyColors({ greyQuality: id, populate: true }))
        dispatch(fetchAssets({ currentFolder: '' }));
    }, [])

    const { loading, greyColor } = useSelector(getGreyColor)

    const data = useMemo(
        () => (greyColor?.docs ? greyColor.docs : []),
        [greyColor]
    )

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop
                return row.index + 1
            },
        },
        {
            Header: 'Photo',
            Cell: (prop) => {
                const { data, row } = prop
                // console.log("row", row)
                // console.log('data', data)
                return (
                    <>{
                        row.original?.image ?
                            <div style={{ position: "relative" }}>
                                <X
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const resp = await dispatch(
                                            deleteSingleObject({
                                                keys: row.original?.image,
                                            })
                                        );
                                        console.log("deleteSingleObj resp ===> ", resp)
                                        const editResp = await dispatch(editGreyColor({ _id: row.original._id, image: "" }))
                                        console.log("EditGreyColor resp ===> ", editResp)
                                        if (resp.payload.data) {
                                            toast.success("Image Deleted");
                                            dispatch(fetchGreyColors({ greyQuality: id, populate: true }));
                                        }
                                    }}
                                    style={{ position: "absolute", top: "0px", left: "-24px" }}
                                />
                                <ImageComponent src={row.original?.image} />

                            </div>
                            :
                            <PrimaryButton type="button" onClick={(e) => {
                                e.stopPropagation();
                                setEditData(row.original)
                                setModalOpen(true);
                            }}>Add Photo</PrimaryButton>
                    }
                    </>
                )
            },
        },
        {
            Header: 'Color',
            accessor: 'color'
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop
                return (
                    <div
                        className='flex items-center gap-2'
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >

                        <Trash
                            color='red'
                            onClick={async () => {
                                await dispatch(deleteGreyColor({ id: row.original._id }))
                                dispatch(fetchGreyColors({ greyQuality: id, populate: true }))
                            }}
                        />
                    </div>
                )
            },
        },
    ]

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(fetchGreyColors({ search: search }))
        }, 300),
        []
    )

    return (
        <>
            <EditColorModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                editData={editData}
            />
            <PageWithCard heading='View Products'>

                <TableWithHeadingAndSearch
                    heading='Grey Colors'
                    data={data}
                    columns={columns}
                    loading={loading}
                    searchFunction={(value) => {
                        debouncedSearch(value)
                    }}
                />
            </PageWithCard>
        </>
    )
}

export default ViewGreyColor
