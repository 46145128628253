import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId,generateReset } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from "../../generators/generateThunks";
import { toast } from "react-toastify";
const SLICE_NAME = "printPO";

export const fetchPrintPOs = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editPrintPO = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchPrintPO = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deletePrintPO = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const createPrintPO = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

export const updatePrintPurchaseOrder = createAsyncThunk(
    "printPO",
    async (data, { rejectWithValue }) => {
        try {
            console.log(data)
            const resp = await authAxiosInstance.put("printPO/update", data);
            if (resp.data) {
                return resp.data
            }
        } catch (error) {
            console.log(error,"error")
            if (error.response.data.message) {
                toast.error(error.response.data.message);
                return rejectWithValue({ error: error.response.data.message })
            }
            toast.error("Something went wrong");
            return rejectWithValue({ error: "Something went wrong" })
        }
    }
);

const getPrintPOSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        reset: generateReset(SLICE_NAME),
        setEditId: generateSetEditId(),
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchPrintPOs, SLICE_NAME),
        ...createData.generateExtraReducers(createPrintPO, SLICE_NAME),
        ...deleteData.generateExtraReducers(deletePrintPO, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchPrintPO, SLICE_NAME),
        ...editData.generateExtraReducers(editPrintPO, SLICE_NAME),
        [updatePrintPurchaseOrder.pending]: (state, action) => {
            state.loading = true;
        },
        [updatePrintPurchaseOrder.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [updatePrintPurchaseOrder.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.error
        }
    },
});

export const getPrintPO = (state) => state.printPO;
export const { setEditId,reset } = getPrintPOSlice.actions;
export default getPrintPOSlice.reducer;
