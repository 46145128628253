import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import QueryString from "qs";
import {
  fetchGreyQualities,
  getGreyQualities,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import { useMemo } from "react";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { createDirectPO } from "../../app/reducers/DirectPO/DirectPOSlice";
import {
  fetchGreyColors,
  getGreyColor,
} from "../../app/reducers/GreyColor/greyColorSlice";
import {
  createProductStockAdjustment,
  createStockAdjustment,
} from "../../app/reducers/StockAdjustment/stockAdjustmentSlice";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";

const StockAdjustment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchGreyQualities());
    dispatch(fetchCatalogs());
    dispatch(fetchLocations());
  }, []);

  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const { loading: catalogLoading, catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);

  const { loading: greyLoading, greyQuality } = useSelector(getGreyQualities);
  const { loading: greyColorLoading, greyColor } = useSelector(getGreyColor);

  const catalogData = useMemo(
    () => (catalog?.docs ? catalog.docs : []),
    [catalog]
  );
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );
  const greyQualityData = useMemo(
    () => (greyQuality?.docs ? greyQuality.docs : []),
    [greyQuality]
  );
  const greyColorData = useMemo(
    () => (greyColor?.docs ? greyColor.docs : []),
    [greyColor]
  );
  // console.log(currentItem, "index")

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      location: "",
      type: "",
      greyQuality: "",
      greyColor: "",
      oldFinished: "",
      quantity: "",
      catalog: "",
      product: "",
      oldData: "",
      productType: "",
      productQuantity: "",
    },
    validationSchema: Yup.object({
      location: Yup.string().required(),
      type: Yup.string().required(),
      greyQuality: Yup.string().when("type", {
        is: "grey",
        then: (schema) => schema.required(),
      }),
      greyColor: Yup.string().when("type", {
        is: "grey",
        then: (schema) => schema.required(),
      }),
      oldFinished: Yup.number().when("type", {
        is: "grey",
        then: (schema) => schema.required(),
      }),
      quantity: Yup.number().when("type", {
        is: "grey",
        then: (schema) => schema.min(1).required(),
      }),
      catalog: Yup.string().when("type", {
        is: "product",
        then: (schema) => schema.required(),
      }),
      product: Yup.string().when("type", {
        is: "product",
        then: (schema) => schema.required(),
      }),
      oldData: Yup.string().when("type", {
        is: "product",
        then: (schema) => schema.required(),
      }),
      productType: Yup.string().when("type", {
        is: "product",
        then: (schema) => schema.required(),
      }),
      productQuantity: Yup.number().when("type", {
        is: "product",
        then: (schema) => schema.min(0).required(),
      }),
    }),
    onSubmit: async (values) => {
      console.log(values, "check nw");
      let data;
      if (values.type == "grey") {
        data = {
          greyQuality: values.greyQuality,
          greyColor: values.greyColor,
          location: values.location,
          oldFinished: values.oldFinished,
          quantity: values.quantity,
          __t: "grey",
        };
        dispatch(createStockAdjustment(data));
      } else {
        data = {
          catalog: values.catalog,
          product: values.product,
          location: values.location,
          oldData: values.oldData,
          quantity: values.productQuantity,
          productType: values.productType,
          __t: "productAdjust",
        };
        dispatch(createProductStockAdjustment(data));
      }

      formik.resetForm();
    },
  });

  return (
    <>
      <PageWithCard heading="Stock Adjustment">
        <div className="text-slate-800 font-semibold mb-4">
          Stock Adjustment
        </div>
        {/* {loading ? (
                <ClipLoader />
            ) : ( */}
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikSelectGroup
            formik={formik}
            label="Select Type"
            name="type"
            onChange={(selectedOption) => {
              formik.setFieldValue("type", selectedOption.value);
              formik.setFieldValue("location", "");
              formik.setFieldValue("greyQuality", "");
              formik.setFieldValue("catalog", "");
              formik.setFieldValue("product", "");
              formik.setFieldValue("greyColor", "");
              formik.setFieldValue("oldFinished", "");
              formik.setFieldValue("oldData", "");
              formik.setFieldValue("quantity", "");
              formik.setFieldValue("productQuantity", "");
            }}
            required
            options={generateOptions({
              array: [
                { label: "Grey", value: "grey" },
                { label: "Product", value: "product" },
              ],
              valueField: "value",
              labelField: "label",
            })}
          />
          <FormikSelectGroup
            formik={formik}
            label="Location"
            name="location"
            onChange={(selectedOption) => {
              formik.setFieldValue("location", selectedOption.value);
              if (formik.values.type == "grey") {
                formik.setFieldValue("greyQuality", "");
                formik.setFieldValue("greyColor", "");
                formik.setFieldValue("oldFinished", "");
                formik.setFieldValue("quantity", "");
              } else {
                formik.setFieldValue("catalog", "");
                formik.setFieldValue("product", "");
                formik.setFieldValue("productType", "");
                formik.setFieldValue("productQuantity", "");
              }
            }}
            required
            options={generateOptions({
              array: location ? location.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
          />
          {formik.values.type == "grey" && (
            <>
              <FormikSelectGroup
                formik={formik}
                label="Select Grey Quality"
                name={`greyQuality`}
                onChange={async (selectedOption) => {
                  dispatch(
                    fetchGreyColors({ greyQuality: selectedOption.value })
                  );
                  formik.setFieldValue(`greyQuality`, selectedOption.value);
                  formik.setFieldValue("greyColor", "");
                }}
                options={generateOptions({
                  array: greyQualityData,
                  valueField: "_id",
                  labelField: "name",
                })}
                required
              />
              {formik.values.greyQuality && (
                <>
                  <FormikSelectGroup
                    formik={formik}
                    label="Select Color"
                    name="greyColor"
                    onChange={async (selectedOption) => {
                      let data = {
                        greyColor: selectedOption.value,
                        location: formik.values.location,
                      };
                      const string = QueryString.stringify(data);
                      const resp = await authAxiosInstance.get(
                        `greyInventory?${string}`
                      );
                      if (
                        resp?.data &&
                        resp.data?.data &&
                        resp.data?.data?.docs.length > 0
                      ) {
                        formik.setFieldValue(
                          "oldFinished",
                          resp.data?.data?.docs[0].received
                        );
                      } else {
                        return toast.error("Data not found");
                      }
                      formik.setFieldValue(`greyColor`, selectedOption.value);
                    }}
                    options={generateOptions({
                      array: greyColorData,
                      valueField: "_id",
                      labelField: "color",
                    })}
                    required
                  />
                  {formik.values.greyColor && (
                    <>
                      <FormikInputGroup
                        formik={formik}
                        label="Finished Orders"
                        type="number"
                        name={`oldFinished`}
                        readOnly
                        required
                      />
                      <FormikInputGroup
                        formik={formik}
                        label="Quantity"
                        type="number"
                        max={formik.values.oldFinished}
                        name={`quantity`}
                        required
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {formik.values.type == "product" && (
            <>
              <FormikAsyncSelect
                formik={formik}
                label="Select Catalog"
                getOptions={async (search) => {
                  const resp = await dispatch(
                    fetchCatalogs({ search })
                  );
                  console.log(resp);
                  return resp.payload.data.docs.map((ele) => ({
                    label: ele.name,
                    value: ele._id,
                  }));
                }}
                onChange={async (selectedOption) => {
                  dispatch(fetchProducts({ catalog: selectedOption.value }));
                  formik.setFieldValue(`catalog`, selectedOption.value);
                  formik.setFieldValue(`productType`, "");
                  formik.setFieldValue(`product`, "");
                }}
                name={`catalog`}
                options={generateOptions({
                  array: catalogData,
                  valueField: "_id",
                  labelField: "name",
                })}
                required
              />
              {/* <FormikSelectGroup
                formik={formik}
                label="Select Catalog"
                name={`catalog`}
                onChange={async (selectedOption) => {
                  dispatch(fetchProducts({ catalog: selectedOption.value }));
                  formik.setFieldValue(`catalog`, selectedOption.value);
                  formik.setFieldValue(`productType`, "");
                  formik.setFieldValue(`product`, "");
                }}
                options={generateOptions({
                  array: catalogData,
                  valueField: "_id",
                  labelField: "name",
                })}
                required
              /> */}
              {formik.values.catalog && (
                <>
                  <FormikSelectGroup
                    formik={formik}
                    label="Select Type"
                    name="productType"
                    onChange={(selectedOption) => {
                      formik.setFieldValue("productType", selectedOption.value);
                    }}
                    options={generateOptions({
                      array: [
                        { label: "finished", value: "finished" },
                        { label: "unfinished", value: "unfinished" },
                        {
                          label: "unfinishedEmbroidery",
                          value: "unfinished embroidery",
                        },
                      ],
                      valueField: "label",
                      labelField: "value",
                    })}
                    required
                  />
                  <FormikSelectGroup
                    formik={formik}
                    label="Select Color"
                    name="product"
                    onChange={async (selectedOption) => {
                      let data = {
                        product: selectedOption.value,
                        location: formik.values.location,
                      };
                      const string = QueryString.stringify(data);
                      const resp = await authAxiosInstance.get(
                        `productInventory?${string}`
                      );
                      if (
                        resp?.data &&
                        resp.data?.data &&
                        resp.data?.data?.docs.length > 0
                      ) {
                        let orderData;
                        if (formik.values.productType == "finished") {
                          orderData = resp.data?.data?.docs[0].finished;
                        } else if (formik.values.productType == "unfinished") {
                          orderData = resp.data?.data?.docs[0].unfinished;
                        } else if (
                          formik.values.productType == "unfinishedEmbroidery"
                        ) {
                          orderData =
                            resp.data?.data?.docs[0].unfinishedEmbroidery;
                        }

                        formik.setFieldValue(`oldData`, orderData);
                      } else {
                        return toast.error("Data not found");
                      }
                      formik.setFieldValue(`product`, selectedOption.value);
                    }}
                    options={generateOptions({
                      array: productData,
                      valueField: "_id",
                      labelField: "color",
                    })}
                    required
                  />
                  {formik.values.product && (
                    <>
                      <FormikInputGroup
                        formik={formik}
                        label={`${formik.values.productType} Orders`}
                        type="number"
                        name={`oldData`}
                        required
                        readOnly
                      />
                      <FormikInputGroup
                        formik={formik}
                        label="Quantity"
                        type="number"
                        name={`productQuantity`}
                        required
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          <div>
            <PrimaryButton type="submit" onClick={formik.handleSubmit}>
              Submit
            </PrimaryButton>
          </div>
        </form>
      </PageWithCard>
    </>
  );
};

export default StockAdjustment;
