import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateReset, generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "greyPO";

export const fetchGreyPurchaseOrders = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editGreyPurchaseOrder = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchGreyPurchaseOrder = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteGreyPurchaseOrder = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createGreyPurchaseOrder = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

export const updateGreyPurchaseOrder = createAsyncThunk(
  "greyPO",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const resp = await authAxiosInstance.post("greyPO/update", data);
      if(resp.data){
        return resp.data
      }
    } catch (error) {
      console.log(error,"new check")
      if(error.response.data.message) {
        toast.error(error.response.data.message);
        return rejectWithValue({error : error.response.data.message})
      }
      toast.error("Something went wrong");
      return rejectWithValue({error : "Something went wrong"})
    }
  }
);

export const updateStatusGreyPurchaseOrder = createAsyncThunk(
  "greyPO",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await authAxiosInstance.patch("greyPO/status", data);
      return resp.data;
    } catch (error) {
      if(error.response.data.message) {
        toast.error(error.response.data.message);
        return rejectWithValue({error : error.response.data.message})
      }
      toast.error("Something went wrong");
      return rejectWithValue({error : "Something went wrong"})
    }
  }
);

const greyPurchaseOrderSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    reset: generateReset(SLICE_NAME),
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchGreyPurchaseOrders, SLICE_NAME),
    ...createData.generateExtraReducers(createGreyPurchaseOrder, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteGreyPurchaseOrder, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchGreyPurchaseOrder, SLICE_NAME),
    ...editData.generateExtraReducers(editGreyPurchaseOrder, SLICE_NAME),

    [updateGreyPurchaseOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [updateGreyPurchaseOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateGreyPurchaseOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error
    },
    [updateStatusGreyPurchaseOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [updateStatusGreyPurchaseOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateStatusGreyPurchaseOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error
    },
  },
});

export const getGreyPo = (state) => state.greyPurchaseOrder;
export const { setEditId,reset } = greyPurchaseOrderSlice.actions;
export default greyPurchaseOrderSlice.reducer;
