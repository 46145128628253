import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikFileInput from "../../components/formik/FormikFileInput";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { createUser, getUsers } from "../../app/reducers/Users/userSlice";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import { VENDOR_ROLES } from "../../utils/dropdownOptions";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";

const AddVendor = () => {
  const { loading } = useSelector(getUsers);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      companyName: "",
      username: "",
      email: "",
      role: "",
      gstNumber: "",
      address: "",
      __t: "vendor",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      companyName: Yup.string().required(),
      username: Yup.string().required(),
      role: Yup.string().required(),
      email: Yup.string().email(),
      gstNumber: Yup.string(),
      address: Yup.string(),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(
        createUser({
          ...values,
        })
      ).then((res) => {
        // formik.resetForm();
        // console.log(res.payload.data.enrollmentUrl);
      });
    },
  });
  return (
    <PageWithCard heading="Add Vendor">
      <div className="text-slate-800 font-semibold mb-4">Vendor Detail</div>
      {loading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup label="Name" formik={formik} name="name" required />
          <FormikInputGroup
            label="Company Name"
            formik={formik}
            name="companyName"
            required
          />
          <FormikInputGroup
            label="Phone"
            name="username"
            formik={formik}
            required
          />
          <FormikInputGroup label="Email" formik={formik} name="email" />
          <FormikSelectGroup
            label="Role"
            formik={formik}
            name="role"
            required
            options={generateOptions({
              array: VENDOR_ROLES,
              labelField: "label",
              valueField: "value",
            })}
          />
          <FormikInputGroup
            label="GST Number"
            formik={formik}
            name="gstNumber"
          />

          <FormikTextareaGroup label="Address" formik={formik} name="address" />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default AddVendor;
