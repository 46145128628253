import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchGreyQualities,
  getGreyQualities,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import { useMemo } from "react";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchGreyColors,
  getGreyColor,
} from "../../app/reducers/GreyColor/greyColorSlice";
import { createGreyEmbroidery } from "../../app/reducers/GreyEmbroidery/greyEmbroiderySlice";
import { toast } from "react-toastify";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import FormikFileInput from "../../components/formik/FormikFileInput";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import AddProgram from "./AddProgram";

const CreateGreyEmbroidery = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchGreyQualities({ unit: "pcs" }));
    dispatch(fetchLocations());
    dispatch(fetchCatalogs());
  }, []);

  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const { loading: greyLoading, greyQuality } = useSelector(getGreyQualities);
  const { loading: greyColorLoading, greyColor } = useSelector(getGreyColor);

  const data = useMemo(
    () => (greyQuality?.docs ? greyQuality.docs : []),
    [greyQuality]
  );

  const greyColordata = useMemo(
    () => (greyColor?.docs ? greyColor.docs : []),
    [greyColor]
  );

  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      manufacturer: "",
      location: "",
      greyQuality: "",
      greyColor: "",

      products: [],
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      location: Yup.string().required(),
      greyQuality: Yup.string().required(),
      greyColor: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      // dispatch(createGreyEmbroidery({ ...values, remaining: values.quantity }));
      // formik.resetForm();
      if (values.products.length > 0) {
        setProgressLoading(true);
        setTotalItem(values.products.length);
        console.log("formTesting1", values);
        for (let index = 0; index < values.products.length; index++) {
          const item = values.products[index];
          const catalogDetail = catalogData.find((d) => d._id == item.catalog);
          const data = {
            manufacturer: values.manufacturer,
            greyQuality: values.greyQuality,
            location: values.location,
            greyColor: values.greyColor.split("/")[0],
            catalog: catalogDetail.name,
            product: item.color,
            quantity: item.quantity,
            type: item.type,
          };
          console.log("formTesting2", data);
          const resp = await dispatch(
            createGreyEmbroidery({ ...data, remaining: data.quantity })
          );

          // const response = await authAxiosInstance.post(`greyEmbroidery`, data, {
          //   headers: { "Content-Type": "multipart/form-data" },
          // })

          // console.log("create testing resp", resp)
          if (resp.payload.data) {
            setCurrentItem((prevState) => prevState + 1);
          }
        }
      } else {
        alert("Please add at least one Product");
      }
    },
  });

  const { catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);

  const catalogData = useMemo(
    () => (catalog?.docs ? catalog.docs : []),
    [catalog]
  );
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );
  const [typeData, setTypeData] = useState([]);

  const [fetchProductDocs, setFetchProductDocs] = useState([]);

  const fetchProductColor = async (data) => {
    const string = QueryString.stringify(data);
    const resp = await authAxiosInstance.get(`product?${string}`);
    console.log("fetchProductColor", resp);
    setFetchProductDocs(resp?.data?.data?.docs ? resp.data.data.docs : []);
  };

  return (
    <>
      <PageWithCard heading="Grey Embroidery">
        {progressLoading ? (
          <ProgressBar currentItem={currentItem} totalItem={totalItem} />
        ) : (
          <div>
            <div className="text-slate-800 font-semibold mb-4">
              Create Grey Embroidery
            </div>
            {/* {loading ? (
                <ClipLoader />
            ) : ( */}
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-4"
            >
              <FormikSelectGroup
                formik={formik}
                label="Manufacturer"
                name="manufacturer"
                required
                onChange={(data) => {
                  formik.setFieldValue("manufacturer", data.value);
                }}
                onBlur={formik.handleBlur}
                options={generateOptions({
                  array: users ? users.docs : [],
                  valueField: "_id",
                  labelField: "name",
                })}
              />
              <FormikSelectGroup
                formik={formik}
                label="Location"
                name="location"
                required
                options={generateOptions({
                  array: location ? location.docs : [],
                  valueField: "_id",
                  labelField: "name",
                })}
              />
              {formik.values.manufacturer && (
                <>
                  <FormikSelectGroup
                    formik={formik}
                    label="Select Quality"
                    onChange={(selectedOption) => {
                      dispatch(
                        fetchGreyColors({ greyQuality: selectedOption.value })
                      );
                      formik.setFieldValue(`greyQuality`, selectedOption.value);
                    }}
                    name={`greyQuality`}
                    options={generateOptions({
                      array: data,
                      valueField: "_id",
                      labelField: "name",
                    })}
                    required
                  />
                  {formik.values.greyQuality && (
                    <>
                      <FormikSelectGroup
                        formik={formik}
                        label="Select Grey Color"
                        name={`greyColor`}
                        options={greyColordata.map((ele) => {
                          return {
                            label: ele.color,
                            value: `${ele._id}/${ele.color}`,
                          };
                        })}
                        required
                      />
                      {/* <FormikSelectGroup
                        formik={formik}
                        label="Select Grey Color"
                        name={`greyColor`}
                        options={generateOptions({
                          array: greyColordata,
                          valueField: "_id",
                          labelField: "color",
                        })}
                        required
                      /> */}
                      {/* <FormikInputGroup
                    formik={formik}
                    label="Quantity"
                    type="number"
                    name={`quantity`}
                    required
                  /> */}
                      {formik.values.greyColor && (
                        <PageWithCard heading="Add Program">
                          <FormikProvider value={formik}>
                            <FieldArray
                              name="products"
                              render={(arrayHelpers) => {
                                return (
                                  <div className="flex flex-col gap-2">
                                    {/* <p>Address</p> */}
                                    <div>
                                      {formik.values.products.map(
                                        (ele, index) => (
                                          <div
                                            className="relative p-4 mb-2"
                                            style={{
                                              border: "1px solid #d6c7c7",
                                              borderRadius: "5px",
                                            }}
                                            key={index}
                                          >
                                            <AddProgram
                                              arrayHelpers={arrayHelpers}
                                              key={index}
                                              formik={formik}
                                              catalogData={catalogData}
                                              index={index}
                                            />
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div>
                                      <SecondaryButton
                                        onClick={() => {
                                          arrayHelpers.push({
                                            color: "",
                                            type: "",
                                            quantity: 0,
                                          });
                                        }}
                                        type="button"
                                      >
                                        Add More
                                      </SecondaryButton>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                          </FormikProvider>
                        </PageWithCard>
                      )}
                    </>
                  )}
                  <div>
                    <PrimaryButton type="submit" onClick={formik.handleSubmit}>
                      Submit
                    </PrimaryButton>
                  </div>
                </>
              )}
            </form>
          </div>
        )}
      </PageWithCard>
    </>
  );
};

export default CreateGreyEmbroidery;
