import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import { ClipLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import { Edit2, Trash, X } from 'react-feather'
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch'
import { useCallback } from 'react'
import { useMemo } from 'react'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton'
import { authAxiosInstance } from '../../utils/axiosConfig'
import { toast } from 'react-toastify'
import { fetchStocks, getStockTransfer, editStock } from '../../app/reducers/StockTransfer/stockTransferSlice'
import * as Yup from "yup";
import FormikSelectGroup from '../../components/formik/FormikSelectGroup'
import { generateOptions } from '../../utils/Utils'
import { STOCK_TRANSFER_STATUS } from '../../utils/dropdownOptions'
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch'
import PaginationClassic from '../../components/pagination/PaginationClassic'
import ImageComponent from '../../components/ImageComponent'

const ViewOutStockTransfer = () => {
    const dispatch = useDispatch()

    const locationInfoId = localStorage.getItem("locationInfoId")
    const [page, setPage] = useState(1)


    useEffect(() => {
        dispatch(fetchStocks({ from: locationInfoId, status: 'pending', page: page, populate: true }))
    }, [page])

    const { loading, stockTransfer } = useSelector(getStockTransfer)


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: 'pending',
        },
        validationSchema: Yup.object({
            status: Yup.string().required(),
        }),
        onSubmit: async (values) => {
            const filters = {
                from: locationInfoId,
                status: values.status,
                page: 1,
                populate: true,
            };
            setPage(1)
            dispatch(fetchStocks(filters));
        },
    });


    const data = useMemo(
        () => (stockTransfer?.docs ? stockTransfer.docs : []),
        [stockTransfer]
    )

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop
                return row.index + 1
            },
        },
        {
            Header: "Photo",
            Cell: (prop) => {
                const { data, row } = prop;
                console.log(row);
                return (
                    <>
                        {
                            row.original?.productData?.image &&
                            <ImageComponent
                                src={row.original?.productData?.image}
                                style={{ width: "120px" }}
                            />
                        }
                    </>
                );
            },
        },
        {
            Header: 'From',
            accessor: 'fromData.name'
        },
        {
            Header: 'To',
            accessor: 'toData.name'
        },
        {
            Header: 'Catalog',
            accessor: 'catalogData.name'
        },
        {
            Header: 'Product',
            accessor: 'productData.color'
        },
        {
            Header: 'Quantity',
            accessor: 'quantity'
        },
        {
            Header: 'Status',
            accessor: 'status'
        }
    ]

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log(search, "check", page)
            let data = []
            if (formik.values.status) {
                data['status'] = formik.values.status
            }
            dispatch(fetchStocks({ search: search, from: locationInfoId, status: 'pending', populate: true }))
        }, 300),
        []
    )


    return (
        <>
            <PageWithCard heading='View Stock Transfer'>
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 mb-2"
                >
                    <FormikSelectGroup
                        formik={formik}
                        label="Status"
                        name="status"
                        required
                        options={generateOptions({
                            array: STOCK_TRANSFER_STATUS,
                            valueField: "value",
                            labelField: "label",
                        })}
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
                {
                    locationInfoId ?
                        <>
                            <TableWithHeadingAndGlobalSearch
                                heading='Products'
                                data={data}
                                columns={columns}
                                loading={loading}
                                searchFunction={(value) => {
                                    console.log("check")
                                    debouncedSearch(value)
                                }}
                            />
                            <PaginationClassic
                                paginationDetails={stockTransfer}
                                setPage={setPage}
                            />
                        </>
                        : <h2>Please Assign Location To See Stock Transfer</h2>
                }
            </PageWithCard>
        </>
    )
}

export default ViewOutStockTransfer
