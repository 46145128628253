import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "./components/infrastructure/Layout";
import PersistLogin from "./components/infrastructure/PersistLogin";

import "./css/style.scss";
// import Dashboard from "./pages/Dashboard";
import Login from "./pages/Auth/Login";
// import AddEmployee from "./pages/Users/AddEmployee";
import PageNotFound from "./pages/PageNotFound";
import Logout from "./pages/Auth/Logout";

import RouteWithHeaderAndSidebar from "./components/infrastructure/RouteWithHeaderAndSidebar";
import "react-datepicker/dist/react-datepicker.css";
// import ViewVendors from "./pages/Users/ViewVendors";
// import EditUser from "./pages/Users/EditUser";
// import AddGrey from "./pages/Grey/AddGrey";
// import GreyPurchaseOrder from "./pages/Grey/GreyPurchaseOrder";
// import AddLocation from "./pages/Location/AddLocation";
// import ViewLocation from "./pages/Location/ViewLocation";
// import ReceivePurchaseOrder from "./pages/Grey/ReceivePurchaseOrder";
// import ViewPurchaseOrder from "./pages/Grey/ViewPurchaseOrder";
// import ViewNewPurchaseOrder from "./pages/PurchaseOrders/ViewNewPurchaseOrder";
// import DirectPurchaseOrder from "./pages/Grey/DirectPurchaseOrder";
// import CreateCatalog from "./pages/Catalog/CreateCatalog";
// import ViewCatalog from "./pages/Catalog/ViewCatalog";
// import CreateProduct from "./pages/Product/CreateProduct";
// import CreateJobWork from "./pages/JobWork/CreateJobWork";
// import CreateNewJobWork from "./pages/Out/CreateJobWork";
// import ViewProduct from "./pages/Product/ViewProduct";
// import CreateCategory from "./pages/Category/CreateCategory";
// import AddVendor from "./pages/Users/AddVendor";
// import SendPrintOrder from "./pages/PrintPurchaseOrder/SendPrintOrder";
// import ReceivePrintOrder from "./pages/PrintPurchaseOrder/ReceivePrintOrder";
// import ViewPrintOrder from "./pages/PrintPurchaseOrder/ViewPrintOrder";
// import InventoryFilter from "./pages/InventoryFilter";
// import CreatePurchaseOrder from "./pages/PurchaseOrders/CreatePurchaseOrder";
// import ReceiveProductPurchaseOrder from "./pages/In/ReceiveProductPurchaseOrder";
// import ReceiveJobWork from "./pages/In/ReceiveJobWork";
// import ViewJobWork from "./pages/JobWork/ViewJobWork";
// import CashBook from "./pages/Cashbook/Cashbook";
// import ViewCashbook from "./pages/Cashbook/ViewCashbook";
// import ExpenseCategory from "./pages/ExpenseCategory/ExpenseCategory";
// import ViewBankBook from "./pages/Cashbook/ViewBankBook";
// import CreateEmbroidery from "./pages/Embroidery/CreateEmbroidery";
// import ReceiveEmbroidery from "./pages/In/ReceiveEmbroidery";
// import DirectFinishedOrder from "./pages/DirectPurchaseOrder/DirectFinishedOrder";
// import DirectUnfinishedOrder from "./pages/DirectPurchaseOrder/DirectUnfinishedOrder";
// import DirectUnfinishedEmbroidery from "./pages/DirectPurchaseOrder/DirectUnfinishedEmbroidery";
// import Sale from "./pages/Sale/Sale";
// import CatalogStock from "./pages/Catalog/CatalogStock";
// import AddCustomer from "./pages/Users/AddCustomer";
// import ViewCustomers from "./pages/Users/ViewCustomers";
// import CustomerDetail from "./pages/Users/CustomerDetail";
// import ViewDirectProductPO from "./pages/DirectPurchaseOrder/VIewDirectProductPO";
// import GenBarcode from "./pages/Barcode/GenBarcode";
// import StockTransfer from "./pages/StockTransfer/StockTransfer";
// import ViewStockTransfer from "./pages/StockTransfer/ViewStockTransfer";
// import AddGreyColor from "./pages/Grey/AddGreyColor";
// import ViewGreyColor from "./pages/Grey/ViewGreyColor";
// import CreateGreyEmbroidery from "./pages/GreyEmbroidery/CreateGreyEmbroidery";
// import ReceiveGreyEmbroidery from "./pages/GreyEmbroidery/ReceiveGreyEmbroidery";
// import ViewEmbroideryPurchasePanel from "./pages/Embroidery/ViewEmbroideryPurchasePanel";
// import ViewEmbroidery from "./pages/Embroidery/ViewEmbroidery";
// import StockAdjustment from "./pages/StockAdjustment/StockAdjustment";
// import AssignLocation from "./pages/Location/AssignLocation";
// import ViewStockAdjustment from "./pages/StockAdjustment/ViewStockAdjustment";
// import ViewOutStockTransfer from "./pages/StockTransfer/ViewOutStockTransfer";
// import ADMIN_ROLES from "./utils/roles";
import RoleAccess from "./pages/RoleAccess";
// import Return from "./pages/In/Return";
// import UploadCatalog from "./pages/Catalog/UploadCatalog";
// import UploadProduct from "./pages/Product/UploadProduct";
// import ViewSale from "./pages/Sale/ViewSale";
import ViewBill from "./pages/Sale/ViewBill";
import ViewBillPage from "./pages/Sale/ViewBillPage";
// import LowStock from "./pages/Notification/LowStock";
// import IncompletePurchase from "./pages/Notification/IncompletePurchase";
// import GreyInventoryInternal from "./pages/Grey/GreyInventoryInternal";
// import ViewGreyEmbroidery from "./pages/GreyEmbroidery/ViewGreyEmbroidery";
// import ReturnDamage from "./pages/In/ReturnDamage";
// import { SalesReport } from "./pages/Reports/SalesReport";
// import UnfinishedTransfer from "./pages/PurchaseOrders/UnfinishedTransfer";
// import ViewUnfinishedTransfer from "./pages/PurchaseOrders/ViewUnfinishedTransfer";
// import { ReturnReport } from "./pages/Reports/ReturnReport";
// import { CreditReport } from "./pages/Reports/CreditReport";
// import { CashReport } from "./pages/Reports/CashReport";
// import { ProductSalesReport } from "./pages/Reports/ProductSalesReport";
import { routes } from "./utils/routes";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<PersistLogin />}>
            <Route exact path="/logout" element={<Logout />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<RouteWithHeaderAndSidebar />}>
              {routes.map((ele) => (
                <Route element={<RoleAccess roles={ele.allowedRoles} />}>
                  <Route path={ele.route} element={ele.element} />
                </Route>
              ))}
              {/* Admin Level Routes */}
              {/* <Route element={<RoleAccess roles={["admin"]} />}>
                <Route exact path="/addEmployee" element={<AddEmployee />} />
                <Route exact path="/addVendor" element={<AddVendor />} />
                <Route
                  exact
                  path="/directPurchaseOrder"
                  element={<DirectPurchaseOrder />}
                />
                <Route path="/direct">
                  <Route
                    exact
                    path="finishedOrder"
                    element={<DirectFinishedOrder />}
                  />
                  <Route
                    exact
                    path="unfinishedOrder"
                    element={<DirectUnfinishedOrder />}
                  />
                  <Route
                    exact
                    path="unfinishedEmbroidery"
                    element={<DirectUnfinishedEmbroidery />}
                  />
                  <Route
                    exact
                    path="viewPO"
                    element={<ViewDirectProductPO />}
                  />
                </Route>
                <Route
                  exact
                  path="/createStockAdjustment"
                  element={<StockAdjustment />}
                />
                <Route
                  exact
                  path="/viewStockAdjustment"
                  element={<ViewStockAdjustment />}
                />

                <Route exact path="/saleReport" element={<SalesReport />} />
                <Route exact path="/returnReport" element={<ReturnReport />} />
                <Route exact path="/creditReport" element={<CreditReport />} />
                <Route exact path="/cashReport" element={<CashReport />} />
                <Route
                  exact
                  path="/product-saleReport"
                  element={<ProductSalesReport />}
                />

                <Route exact path="/addLocation" element={<AddLocation />} />
              </Route> */}

              {/* Added Stock Transfer with All Level Routes */}
              {/* <Route
                element={
                  <RoleAccess
                    roles={[...ADMIN_ROLES["EMPLOYEE"], "stock_transfer"]}
                  />
                }
              >
                <Route exact path="/" element={<Dashboard />} />
                <Route
                  exact
                  path="/stockTransfer"
                  element={<StockTransfer />}
                />
                <Route
                  exact
                  path="/view/stockTransfer"
                  element={<ViewStockTransfer />}
                />
                <Route
                  exact
                  path="/assignLocation"
                  element={<AssignLocation />}
                />
              </Route> */}

              {/* ALL Level Roles */}
              {/* <Route element={<RoleAccess roles={ADMIN_ROLES["EMPLOYEE"]} />}>
                <Route path="/in">
                  <Route exact path="return" element={<Return />} />
                  <Route exact path="returnDamage" element={<ReturnDamage />} />
                </Route>
                <Route exact path="/viewCatalog" element={<ViewCatalog />} />
                <Route
                  exact
                  path="/viewProduct/:id"
                  element={<ViewProduct />}
                />
                <Route
                  exact
                  path="/view-stock/:lid/:page"
                  element={<CatalogStock />}
                />
                <Route path="/addCashbook" element={<CashBook />} />
                <Route
                  path="/addExpenseCategory"
                  element={<ExpenseCategory />}
                />
                <Route exact path="/viewLocation" element={<ViewLocation />} />
                <Route exact path="/addCustomer" element={<AddCustomer />} />
                <Route exact path="/viewVendors" element={<ViewVendors />} />
                <Route exact path="/viewCustomer" element={<ViewCustomers />} />
                <Route
                  exact
                  path="/customer/:customerId"
                  element={<CustomerDetail />}
                />
                <Route exact path="/editUser" element={<EditUser />} />
                <Route path="/sale">
                  <Route exact index element={<Sale />} />
                  <Route path="view" element={<ViewSale />} />
                </Route>
                <Route exact path="/generateBarcode" element={<GenBarcode />} />
              </Route> */}

              {/* Added Stock Transfer with Store Manager And Godown Manager */}
              {/* <Route
                element={
                  <RoleAccess
                    roles={[
                      "admin",
                      "store_manager",
                      "stock_transfer",
                      "godown_manager",
                    ]}
                  />
                }
              >
                <Route
                  exact
                  path="/view/out/stockTransfer"
                  element={<ViewOutStockTransfer />}
                />
              </Route> */}

              {/* Store Manager */}
              {/* <Route
                element={<RoleAccess roles={["admin", "store_manager"]} />}
              >
                <Route path="/viewCashbook" element={<ViewCashbook />} />
                <Route path="/viewBankbook" element={<ViewBankBook />} />

                <Route path="/lowStock" element={<LowStock />} />
                <Route
                  path="/incompletePurchase"
                  element={<IncompletePurchase />}
                />
              </Route> */}

              {/* Godown Manager */}
              {/* <Route
                element={<RoleAccess roles={["godown_manager", "admin"]} />}
              >
                <Route
                  exact
                  path="/greyInventory/:unit"
                  element={<InventoryFilter />}
                />
                <Route
                  exact
                  path="/unfinishedTransfer"
                  element={<UnfinishedTransfer />}
                />
                <Route
                  exact
                  path="/viewUnfinished"
                  element={<ViewUnfinishedTransfer />}
                />
                <Route
                  exact
                  path="/greyInventory/internal/:quality/:location"
                  element={<GreyInventoryInternal />}
                />
                <Route exact path="/addGrey" element={<AddGrey />} />
                <Route exact path="/addGreyColor" element={<AddGreyColor />} />
                <Route
                  exact
                  path="/viewGreyColor/:id"
                  element={<ViewGreyColor />}
                />
                <Route
                  exact
                  path="/greyEmbroidery"
                  element={<CreateGreyEmbroidery />}
                />
                <Route
                  exact
                  path="/receive/greyEmbroidery"
                  element={<ReceiveGreyEmbroidery />}
                />
                <Route
                  exact
                  path="/ViewGreyEmbroidery"
                  element={<ViewGreyEmbroidery />}
                />
                <Route
                  exact
                  path="/createCatalog"
                  element={<CreateCatalog />}
                />
                <Route
                  exact
                  path="/uploadCatalog"
                  element={<UploadCatalog />}
                />

                <Route
                  exact
                  path="/createProduct"
                  element={<CreateProduct />}
                />
                <Route
                  exact
                  path="/uploadProduct"
                  element={<UploadProduct />}
                />
                <Route
                  exact
                  path="/createJobWork"
                  element={<CreateJobWork />}
                />
                <Route
                  exact
                  path="/viewJobWorkOrders"
                  element={<ViewJobWork />}
                />
                <Route
                  exact
                  path="/createCategory"
                  element={<CreateCategory />}
                />
                <Route
                  exact
                  path="/createEmbroidery"
                  element={<CreateEmbroidery />}
                />
                <Route
                  exact
                  path="/viewEmbroidery"
                  element={<ViewEmbroidery />}
                />
                <Route
                  exact
                  path="/createPurchaseOrder"
                  element={<GreyPurchaseOrder />}
                />
                <Route
                  exact
                  path="/receivePurchaseOrder"
                  element={<ReceivePurchaseOrder />}
                />
                <Route
                  exact
                  path="/viewPurchaseOrder"
                  element={<ViewPurchaseOrder />}
                />
                <Route
                  exact
                  path="/createNewPurchaseOrder"
                  element={<CreatePurchaseOrder />}
                />
                <Route
                  exact
                  path="/viewNewPurchaseOrder"
                  element={<ViewNewPurchaseOrder />}
                />
                <Route path="/in">
                  <Route
                    exact
                    path="receivePurchaseOrder"
                    element={<ReceiveProductPurchaseOrder />}
                  />
                  <Route
                    exact
                    path="receiveEmbroideryOrder"
                    element={<ReceiveEmbroidery />}
                  />
                  <Route
                    exact
                    path="receiveJobOrder"
                    element={<ReceiveJobWork />}
                  />
                </Route>
                <Route path="/out">
                  <Route
                    exact
                    path="createjobWork"
                    element={<CreateNewJobWork />}
                  />
                </Route>
                <Route path="/sendOrder">
                  <Route exact index element={<SendPrintOrder />} />
                  <Route exact path=":id" element={<SendPrintOrder />} />
                </Route>
                <Route
                  exact
                  path="/receiveOrder"
                  element={<ReceivePrintOrder />}
                />
                <Route exact path="/viewOrder" element={<ViewPrintOrder />} />
              </Route> */}
            </Route>
          </Route>
          <Route exact path="/login" element={<Login />} />
        </Route>
        <Route path="/viewBill">
          <Route index element={<ViewBill />} />
          <Route path=":id" element={<ViewBillPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
