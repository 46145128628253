import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import { useMemo } from "react";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import moment from "moment";
import { toast } from "react-toastify";
import QueryString from "qs";
import customId from "custom-id";

const UnfinishedTransfer = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);
  const [errorData, setErrorData] = useState([]);

  const locationInfoId = localStorage.getItem("locationInfoId");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchCatalogs());
  }, []);
  const { location } = useSelector(getLocation);
  const { loading: catalogLoading, catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);

  const data = useMemo(() => (catalog?.docs ? catalog.docs : []), [catalog]);
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: locationInfoId ? locationInfoId : "",
      product: [""],
    },
    validationSchema: Yup.object({
      location: Yup.string().required(),
      product: Yup.array()
        .of(Yup.object({}))
        .required()
        .min(1, "Please Add atleast one product"),
    }),
    onSubmit: async (values) => {
      let filterProduct = values.product?.filter((d) =>
        d?.sku ? d.sku : d?.catalog
      );
      let referenceId = customId({});
      if (filterProduct.length > 0) {
        setProgressLoading(true);
        setTotalItem(filterProduct.length);
        for (let i = 0; i < filterProduct.length; i++) {
          try {
            let data = {
              catalog: filterProduct[i].catalog,
              product: filterProduct[i].product,
              quantity: filterProduct[i].quantity,
              location: values.location,
            };
            const response = await authAxiosInstance.post(`unfinished`, data);
            if (response.data) {
              setCurrentItem((prevState) => prevState + 1);
            }
          } catch (error) {
            console.log(error, "new error hu mei");
            setCurrentItem((prevState) => prevState + 1);
            setErrorData((prev) => [
              ...prev,
              {
                ...filterProduct[i],
                error: JSON.stringify(error.response?.data),
              },
            ]);
          }
        }
      } else {
        return alert("Please add atleast one product");
      }
      formik.resetForm();
    },
  });

  return (
    <>
      <PageWithCard heading="Unfinished To Damage Transfer">
        {progressLoading ? (
          <ProgressBar
            currentItem={currentItem}
            totalItem={totalItem}
            errorData={errorData}
          />
        ) : (
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
            <FormikSelectGroup
              formik={formik}
              label="Location"
              name="location"
              onChange={(selectedOption) => {
                formik.setFieldValue("product", [""]);
                formik.setFieldValue("location", selectedOption.value);
              }}
              required
              options={generateOptions({
                array: location ? location.docs : [],
                valueField: "_id",
                labelField: "name",
              })}
            />
            {formik.values.location && (
              <FormikProvider value={formik}>
                <FieldArray
                  name="product"
                  render={(arrayHelpers) => {
                    return (
                      <div className="flex flex-col gap-2">
                        {/* <p>Address</p> */}
                        <div>
                          {formik.values.product.map((ele, index) => (
                            <div
                              className="relative p-4 mb-2"
                              style={{
                                border: "1px solid #d6c7c7",
                                borderRadius: "5px",
                              }}
                              key={index}
                            >
                              <FormikAsyncSelect
                                formik={formik}
                                label="Select Catalog"
                                getOptions={async (search) => {
                                  const resp = await dispatch(
                                    fetchCatalogs({ search })
                                  );
                                  return resp.payload.data.docs.map((ele) => {
                                    return {
                                      label: ele.name,
                                      value: ele._id,
                                    };
                                  });
                                }}
                                onChange={async (selectedOption) => {
                                  dispatch(
                                    fetchProducts({
                                      catalog: selectedOption.value,
                                    })
                                  );
                                  formik.setFieldValue(
                                    `product.${index}.catalog`,
                                    selectedOption.value
                                  );
                                }}
                                name={`product.${index}.catalog`}
                                required
                              />
                              {formik?.errors?.product &&
                              Array.isArray(formik?.errors?.product) &&
                              formik?.errors?.product[index]?.catalog &&
                              formik?.errors?.product[index]?.catalog ? (
                                <p className="text-xs text-red-500">
                                  {formik.errors["product"][index].catalog}
                                </p>
                              ) : null}
                              {formik.values.product[index].catalog && (
                                <>
                                  <FormikSelectGroup
                                    formik={formik}
                                    label="Select Color"
                                    name={`product.${index}.product`}
                                    onChange={async (selectedOption) => {
                                      let productExist =
                                        formik.values.product.find(
                                          (d) =>
                                            d.product == selectedOption.value &&
                                            d.product ==
                                              formik.values.product[index]
                                                .product
                                        );
                                      if (productExist) {
                                        return toast.error(
                                          "Product already exist"
                                        );
                                      }
                                      const stringInv = QueryString.stringify({
                                        product: selectedOption.value,
                                        location: formik.values.location,
                                      });
                                      const invRsp =
                                        await authAxiosInstance.get(
                                          `productInventory?${stringInv}`
                                        );
                                      const stock = invRsp.data.data.docs[0];
                                      if (stock?.unfinished <= 0) {
                                        return alert(
                                          "Return Quantity Exeeded Sale"
                                        );
                                      }
                                      if (!stock) {
                                        return alert("Stock not found");
                                      }
                                      formik.setFieldValue(
                                        `product.${index}.product`,
                                        selectedOption.value
                                      );
                                      formik.setFieldValue(
                                        `product.${index}.unfinishedQuantity`,
                                        stock.unfinished
                                      );
                                    }}
                                    options={generateOptions({
                                      array: productData,
                                      valueField: "_id",
                                      labelField: "color",
                                    })}
                                    required
                                  />
                                  {formik?.errors?.product &&
                                  Array.isArray(formik?.errors?.product) &&
                                  formik?.errors?.product[index]?.product &&
                                  formik?.errors?.product[index]?.product ? (
                                    <p className="text-xs text-red-500">
                                      {formik.errors["product"][index].product}
                                    </p>
                                  ) : null}
                                  <FormikInputGroup
                                    readOnly
                                    formik={formik}
                                    label="Unfinised Quantity"
                                    name={`product.${index}.unfinishedQuantity`}
                                  />
                                  <FormikInputGroup
                                    formik={formik}
                                    label="Quantity"
                                    type="number"
                                    value={
                                      formik.values.product[index].quantity
                                    }
                                    name={`product.${index}.quantity`}
                                    required
                                  />
                                  {formik?.errors?.product &&
                                  Array.isArray(formik?.errors?.product) &&
                                  formik?.errors?.product[index]?.quantity ? (
                                    <p className="text-xs text-red-500">
                                      {formik.errors["product"][index].quantity}
                                    </p>
                                  ) : null}
                                </>
                              )}

                              <div>
                                <DangerButton
                                  className="mt-3"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                  type="button"
                                >
                                  Remove
                                </DangerButton>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div>
                          <SecondaryButton
                            onClick={() => {
                              arrayHelpers.push("");
                            }}
                            type="button"
                          >
                            Add More
                          </SecondaryButton>
                        </div>
                      </div>
                    );
                  }}
                />
                {formik.errors["product"] &&
                !Array.isArray(formik.errors["product"]) &&
                formik.errors["product"] ? (
                  <p className="text-xs text-red-500">
                    {formik.errors["product"]}
                  </p>
                ) : null}
              </FormikProvider>
            )}

            <div>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
          </form>
        )}
      </PageWithCard>
    </>
  );
};

export default UnfinishedTransfer;
