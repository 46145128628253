import { FieldArray, FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import EditModal from "../../components/infrastructure/Modals/EditModal";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { getProduct, setEditId, fetchProduct, fetchProducts, editProductWithFile } from "../../app/reducers/Product/productSlice";
import { useMemo } from "react";
import FormikFileInput from "../../components/formik/FormikFileInput";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";

const EditProduct = ({ modalOpen, setModalOpen, editData, setEditData }) => {
    console.log(modalOpen, "check", editData?.catalogData?.name)
    const folderName = editData?.catalogData?.name
    const dispatch = useDispatch()





    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            _id: editData?._id,
            catalog: editData?.catalog,
            color: editData?.color,
            image: ''
        },
        onSubmit: async (values, { resetForm }) => {
            await dispatch(editProductWithFile({ ...values }));
            dispatch(fetchProducts({ catalog: values.catalog, populate: true }))
            setModalOpen(false)
            window.location.reload()
            // resetForm()
        },
    });
    // useEffect(() => {

    //     if (!modalOpen) {
    //         // pending 
    //     }


    // }, [modalOpen])
    return (
        <ModalBasic modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Edit Product"}>
            <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4 p-4'>
                <FormikInputGroup
                    label="SystemId"
                    name="_id"
                    formik={formik}
                    required
                    readOnly
                />
                <FormikInputGroup label='Color' formik={formik} name='color' required />
                <FormikDirectFileUpload
                    name={`image`}
                    formik={formik}
                    label="Image"
                    location={`trendy_sarees2/catalogs/${folderName}/`}
                />
                {/* <FormikFileInput name='image' formik={formik} label='Image' /> */}
                <div>
                    <PrimaryButton type='submit'>Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default EditProduct;
