import { FieldArray, FormikProvider, useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { createLocation,fetchLocation,fetchLocations,getLocation  } from "../../app/reducers/Location/locationSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import geo from "../../assets/countries+states+cities.json";
import { X } from "react-feather";
import {
  findCitiesInState,
  findStatesInCountry,
  generateOptions,
} from "../../utils/Utils";
import { useEffect } from "react";

const AddLocation = () => {
//   const { loading } = useSelector(getLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations())
  },[])

  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      address: "",
      pincode: "",
      country:"",
      state:"",
      city:"",
      emails: [],
      phones: [],
    },
    onSubmit: (values, { resetForm }) => {
      dispatch(createLocation(values)).then((res) => {
        resetForm();
      });
    },
  });
  return (
    <PageWithCard heading="Add Stores">
      {/* {loading ? (
        <ClipLoader />
      ) : ( */}
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikInputGroup
            label="Location Name"
            name="name"
            required
            formik={formik}
          />
          <FormikTextareaGroup
            label="Location Address"
            name="address"
            required
            formik={formik}
          />
          <FormikInputGroup
            label="Company Name"
            name="companyName"
            formik={formik}
          />
          <FormikInputGroup
            formik={formik}
            label="Country"
            name="country"
            
          />
          <FormikInputGroup
            formik={formik}
            label="State"
            name="state"
            
          />

          <FormikInputGroup
            formik={formik}
            label="City"
            name="city"
            
          />
          <FormikInputGroup label="Pincode" name="pincode" formik={formik} />
          {/* <FormikInputGroup
            label="GST Number"
            name="GSTNumber"
            formik={formik}
          /> */}
          <FormikProvider value={formik}>
            <FieldArray name="emails">
              {(arrayHelpers) => (
                <>
                  <div className="flex items-center gap-4">
                    <h1 className="text-md">Emails</h1>
                    <PrimaryButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.push("");
                      }}
                    >
                      Add
                    </PrimaryButton>
                  </div>
                  <div className="w-full">
                    {formik.values.emails.map((email, index) => (
                      <div className="flex items-center gap-4" key={index}>
                        <FormikInputGroup
                          name={`emails.${index}`}
                          formik={formik}
                          value={email}
                          fullWidth
                        />
                        <X
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </FieldArray>
          </FormikProvider>
          <FormikProvider value={formik}>
            <FieldArray name="phones">
              {(arrayHelpers) => (
                <>
                  <div className="flex items-center gap-4">
                    <h1 className="text-md">Phones</h1>
                    <PrimaryButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.push("+91");
                      }}
                    >
                      Add
                    </PrimaryButton>
                  </div>
                  <div>
                    {formik.values.phones.map((phone, index) => (
                      <div className="flex items-center gap-4" key={index}>
                        <FormikPhoneInputGroup
                          name={`phones.${index}`}
                          formik={formik}
                          value={phone}
                          fullWidth
                        />
                        <X
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </FieldArray>
          </FormikProvider>
          <div>
            <PrimaryButton type="submit">Create</PrimaryButton>
          </div>
        </form>
      {/* )} */}
    </PageWithCard>
  );
};

export default AddLocation;
