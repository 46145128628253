import numeral from "numeral";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { Edit2, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  deleteLocation,
  fetchLocations,
  getLocation,
  setEditId,
} from "../../app/reducers/Location/locationSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import EditLocationModel from "./EditLocationModel";

const ViewLocation = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchLocations());
    }, []);

  const { loading, location } = useSelector(getLocation);
  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Location Name",
      accessor: "name",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Edit2
              onClick={() => {
                dispatch(setEditId(row.original._id));
              }}
            />
            <Trash
              size={18}
              color="red"
              onClick={() => {
                dispatch(deleteLocation(row.original._id));
              }}
            />
          </div>
        );
      },
    },
  ];
  const columnsMemo = useMemo(() => columns, [columns]);
  const data = useMemo(() => location.docs ? location.docs : [], [location.docs]);
    console.log(data)
  return (
    <PageWithCard heading="View Locations">
      {loading ? (
        <ClipLoader />
      ) : (
        <>
          <EditLocationModel />
          <TableWithHeadingAndSearch
            heading="Locations"
            columns={columnsMemo}
            data={data}
          />
        </>
      )}
    </PageWithCard>
  );
};

export default ViewLocation;
