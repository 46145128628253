import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "category";

export const fetchCategories = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editCategory = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCategory = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteCategory = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createCategory = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const getCategorySlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchCategories, SLICE_NAME),
    ...createData.generateExtraReducers(createCategory, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteCategory, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchCategory, SLICE_NAME),
    ...editData.generateExtraReducers(editCategory, SLICE_NAME)
  },
});

export const getCategories = (state) => state.categories;
export const { setEditId } = getCategorySlice.actions;
export default getCategorySlice.reducer;
