import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { useCallback } from "react";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import ImageComponent from "../../components/ImageComponent";

const IncompletePurchase = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        console.log(row);
        return (
          <ImageComponent
            src={row.original?.greyColorData?.image}
            style={{ width: "120px" }}
          />
        );
      },
    },
    {
      Header: "Grey Data",
      accessor: "greyData.name",
    },
    {
      Header: "Grey Color",
      accessor: "greyColorData.color",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Manufacturer",
      accessor: "manufacturerData.name",
    },
    {
      Header: "Expected Delivery Date",
      accessor: "expected_delivery_date",
    },
  ];

  const columnsProduct = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        console.log(row);
        return (
          <ImageComponent
            src={row.original?.productData?.image}
            style={{ width: "120px" }}
          />
        );
      },
    },
    {
      Header: "Catalog",
      accessor: "catalogData.name",
    },
    {
      Header: "Product",
      accessor: "productData.color",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Manufacturer",
      accessor: "manufacturerData.name",
    },
    {
      Header: "Expected Delivery Date",
      accessor: "expected_delivery_date",
    },
  ];

  const columnsJob = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        console.log(row);
        return (
          <ImageComponent
            src={row.original?.productData?.image}
            style={{ width: "120px" }}
          />
        );
      },
    },
    {
      Header: "Catalog",
      accessor: "catalogData.name",
    },
    {
      Header: "Product",
      accessor: "productData.color",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Manufacturer",
      accessor: "manufacturerData.name",
    },
    {
      Header: "Expected Delivery Date",
      accessor: "expected_delivery_date",
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: null,
    },
    validationSchema: Yup.object({
      type: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      let selectedType = selectType(values.type);
      if (selectedType) {
        const string = QueryString.stringify({
          populate: true,
          expected_delivery_date: {
            $lte: new Date(),
          },
        });
        const respData = await authAxiosInstance.get(
          `${selectedType}?${string}`
        );
        if (respData?.data?.data) {
          setInventoryData(respData.data?.data?.docs);
          setLoading(false);
          if (respData.data?.data?.docs.length == 0) {
            alert("No Data Found");
          }
        } else {
          alert("Something Went Wrong");
          // setLoading(false)
        }
      }
    },
  });

  const selectType = (type) => {
    if (type == "grey") {
      return "greyPo";
    } else if (type == "product") {
      return "productPO";
    } else {
      return "jobWorkPO";
    }
  };

  const selectColumn = () => {
    let type = formik.values.type;
    if (type == "grey") {
      return columns;
    } else if (type == "product") {
      return columnsProduct;
    } else {
      return columnsJob;
    }
  };

  const debouncedSearch = useCallback(
    _.debounce(async (search) => {
      setSearch(search);
      let selectedType = selectType(formik.values.type);
      console.log(selectedType, "check");
      let data = {
        populate: true,
        search: search,
        expected_delivery_date: {
          $lte: new Date(),
        },
      };
      const string = QueryString.stringify(data);
      const respData = await authAxiosInstance.get(`${selectedType}?${string}`);
      if (respData?.data?.data) {
        setInventoryData(respData.data?.data?.docs);
        setLoading(false);
      } else {
        alert("Something Went Wrong");
        setLoading(false);
      }
    }, 300),
    [JSON.stringify(formik.values)]
  );

  return (
    <PageWithCard heading="Incomplete Purchase">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 mb-2">
        <FormikSelectGroup
          formik={formik}
          label="Incomplete Type"
          name="type"
          onChange={(selectedOption) => {
            setInventoryData([]);
            setSearch("");
            formik.setFieldValue("type", selectedOption.value);
          }}
          required
          options={generateOptions({
            array: [
              { label: "Grey Purchase Order", value: "grey" },
              { label: "Product Purchase Order", value: "product" },
              { label: "Job Purchase Order", value: "job" },
            ],
            valueField: "value",
            labelField: "label",
          })}
        />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>

      {((inventoryData && inventoryData.length > 0) || search) && (
        <TableWithHeadingAndGlobalSearch
          heading="Incomplete Purchase Order"
          data={inventoryData}
          loading={loading}
          columns={selectColumn()}
          searchFunction={(value) => {
            debouncedSearch(value);
          }}
        />
      )}
    </PageWithCard>
  );
};

export default IncompletePurchase;
