import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateReset, generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "embroideryPO";

export const fetchEmbroideryOrders = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editEmbroideryOrder = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchEmbroideryOrder = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteEmroideryOrder = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createEmroideryOrder = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

export const updateEmroideryOrder = createAsyncThunk(
  "embroideryPO",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const resp = await authAxiosInstance.patch("embroideryPO", data);
      if(resp.data){
        return resp.data
      }
    } catch (error) {
      if(error.response.data.message) {
        toast.error(error.response.data.message);
        return rejectWithValue({error : error.response.data.message})
      }
      toast.error("Something went wrong");
      return rejectWithValue({error : "Something went wrong"})
    }
  }
);

export const updateStatusPurchaseOrder = createAsyncThunk(
  "productPO",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await authAxiosInstance.patch("productPO/status", data);
      return resp.data;
    } catch (error) {
      if(error.response.data.message) {
        toast.error(error.response.data.message);
        return rejectWithValue({error : error.response.data.message})
      }
      toast.error("Something went wrong");
      return rejectWithValue({error : "Something went wrong"})
    }
  }
);

const EmbroideryOrderSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    reset: generateReset(SLICE_NAME),
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchEmbroideryOrders, SLICE_NAME),
    ...createData.generateExtraReducers(createEmroideryOrder, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteEmroideryOrder, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchEmbroideryOrder, SLICE_NAME),
    ...editData.generateExtraReducers(editEmbroideryOrder, SLICE_NAME),

    [updateEmroideryOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEmroideryOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateEmroideryOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error
    },
    [updateStatusPurchaseOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [updateStatusPurchaseOrder.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateStatusPurchaseOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error
    },
  },
});

export const getEmbroideryPO = (state) => state.embroideryPO;
export const { setEditId,reset } = EmbroideryOrderSlice.actions;
export default EmbroideryOrderSlice.reducer;
