import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import FormikSelectGroup from '../../components/formik/FormikSelectGroup'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton'
import {
    fetchUsers,
    getUsers,
} from '../../app/reducers/Users/userSlice'
import { useNavigate } from 'react-router-dom'
import { generateOptions } from '../../utils/Utils'
import {
    fetchLocations,
    getLocation
} from '../../app/reducers/Location/locationSlice'
import {
     fe ,
    updateStatusPurchaseOrder,
    getProductPO,
    setEditId,
    reset,
    updatePurchaseOrder
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice'
import { Edit2, Eye, Trash } from 'react-feather'
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import FormikMultiSelect from '../../components/formik/FormikMultiSelect'
import PaginationClassic from '../../components/pagination/PaginationClassic'
import { fetchEmbroideryOrders,getEmbroideryPO } from '../../app/reducers/Embroidery/embroiderySlice'
import ViewEmbroideryPurchasePanel from './ViewEmbroideryPurchasePanel'

const ViewEmbroidery = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [viewPurchaseModel, setViewPurchaseModalOpen] = useState(false)
    const [viewingParty, setViewingParty] = useState(null)
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(reset({}))
    }, [])

    useEffect(() => {
        dispatch(fetchUsers({ role: 'manufacturer' }))
        dispatch(fetchEmbroideryOrders({populate:true}))
        dispatch(fetchLocations())
    }, [])

   

    const { users } = useSelector(getUsers)
    const { location } = useSelector(getLocation)
    const { embroideryPO, loading: productPoLoading } = useSelector(getEmbroideryPO)

 
    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop
                return row.index + 1
            },
        },
        {
            Header: 'Catalog',
            accessor: 'catalogData.name'
        },
        {
            Header: 'Location',
            accessor: 'locationData.name',
        },
        {
            Header: 'Color',
            accessor: 'productData.color',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'Ordered Total',
            accessor: 'quantity',
        },
        {
            Header: 'Ordered Remaining',
            accessor: 'remaining',
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop
                return (

                    <div
                        className='flex items-center gap-2'
                        onClick={(e) => {
                            e.stopPropagation()
                        }}>
                        <Eye
                            size={18}
                            color="green"
                            onClick={() => {
                                setViewPurchaseModalOpen(true);
                                setViewingParty(row.original);
                            }}
                        />
                    </div>
                )
            },
        },
    ]
    const debouncedSearch = useCallback(
        _.debounce((search,values) => {
            let data  ={}
            if(values.location) {
                data["location"] = values.location
            }
            if(values.manufacturer){
                data["manufacturer"] = values.manufacturer
            }
            dispatch(fetchEmbroideryOrders({ ...data,search: search,populate:true,"status": { $in: values.status } }))
        }, 300),
        []
    )

    const locationdata = useMemo(
        () => (location?.docs ? location.docs : []),
        [location]
    )

    const data = useMemo(
        () => (embroideryPO?.docs ? embroideryPO.docs : []),
        [embroideryPO]
    )

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            location: '',
            status: [],
            manufacturer: '',
        },
        validationSchema: Yup.object({
            location: Yup.string().required(),
            status: Yup.array().required(),
            manufacturer: Yup.string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            dispatch(fetchEmbroideryOrders({ ...values,"status": { $in: values.status }, populate: true }))
        },
    })

    useEffect(() => {
        let data  ={}
        if(formik.values.location) {
            data["location"] = formik.values.location
        }
        if(formik.values.manufacturer){
            data["manufacturer"] = formik.values.manufacturer
        }
        dispatch(fetchEmbroideryOrders({...data,"status": { $in: formik.values.status },populate:true,page}))
    },[page])

    return (
        <>
            <ViewEmbroideryPurchasePanel
                open={viewPurchaseModel}
                setOpen={setViewPurchaseModalOpen}
                party={viewingParty}
            />
            <PageWithCard heading='View Embroidery Order'>
                <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4'>

                    <FormikSelectGroup
                        formik={formik}
                        label='Manufacturer'
                        name='manufacturer'
                        options={generateOptions({
                            array: users ? users.docs : [],
                            valueField: '_id',
                            labelField: 'name',
                        })}
                        required
                    />

                    <FormikSelectGroup
                        formik={formik}
                        label='Location'
                        name='location'
                        required
                        options={generateOptions({
                            array: locationdata,
                            valueField: '_id',
                            labelField: 'name',
                        })}
                    />

                    <FormikMultiSelect
                        formik={formik}
                        label='Status'
                        name='status'
                        required
                        options={generateOptions({
                            array: [
                                { label: 'Pending', value: 'pending' },
                                { label: 'Completed', value: 'completed' },
                                { label: 'Settled', value: 'settled' },
                                { label: 'Incomplete', value: 'incomplete' },
                            ],
                            valueField: 'value',
                            labelField: 'label',
                        })}
                    />

                    <div>
                        <PrimaryButton type='submit'>Submit</PrimaryButton>
                    </div>
                </form>
                {/* // )} */}
                {/* <EditGreyModal /> */}
                <TableWithHeadingAndGlobalSearch
                    heading='Embroidery Orders'
                    data={data}
                    columns={columns}
                    loading={productPoLoading}
                    searchFunction={(value) => {
                        debouncedSearch(value,formik.values)
                    }}
                />
             <PaginationClassic paginationDetails={embroideryPO} setPage={setPage} />
            </PageWithCard>
        </>
    )
}

export default ViewEmbroidery
