import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchSendJobWorksOrders,
  getJobWorkPO,
  setEditId,
  reset,
} from "../../app/reducers/SendJobWork/SendJobWork";
import { Edit2, Eye, Trash } from "react-feather";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import ViewJobPanel from "./ViewJobPanel";
import moment from "moment";

const ViewJobWork = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [viewPurchaseModel, setViewPurchaseModalOpen] = useState(false);
  const [viewingParty, setViewingParty] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(reset({}));
  }, []);

  useEffect(() => {
    dispatch(fetchUsers({ role: "job_work" }));
    dispatch(fetchLocations());
  }, []);

  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const { jobWorkPO, loading: jobWorkPoLoading } = useSelector(getJobWorkPO);

  const handleSettled = async (rowData) => {
    let data = {
      _id: rowData._id,
      status: "settled",
    };
    await dispatch(updateStatusGreyPurchaseOrder(data));
    dispatch(
      fetchGreyPurchaseOrders({
        manufacturer: rowData.manufacturer,
        location: rowData.location,
        status: rowData.status,
        populate: true,
      })
    );
  };

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      Header: "Catalog",
      accessor: "catalogData.name",
    },
    {
      Header: "Colour",
      accessor: "productData.color",
    },
    {
      Header: "Job Work User",
      accessor: "manufacturerData.name",
    },
    {
      Header: "Job Work",
      accessor: "jobWorkName",
    },
    {
      Header: "Location",
      accessor: "locationData.name",
    },
    {
      Header: "Price",
      accessor: "price",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Ordered Total",
      accessor: "quantity",
    },
    {
      Header: "Ordered Remaining",
      accessor: "remaining",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Eye
              size={18}
              color="green"
              onClick={() => {
                setViewPurchaseModalOpen(true);
                setViewingParty(row.original);
              }}
            />
          </div>
        );
      },
    },
  ];

  const locationdata = useMemo(
    () => (location?.docs ? location.docs : []),
    [location]
  );

  const data = useMemo(
    () => (jobWorkPO?.docs ? jobWorkPO.docs : []),
    [jobWorkPO]
  );
  // console.log(data,"dekhtehai")

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: [],
      status: [],
      manufacturer: [],
    },
    validationSchema: Yup.object({
      location: Yup.string().required(),
      status: Yup.array().required(),
      manufacturer: Yup.string().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log(values, "checking");
      dispatch(
        fetchSendJobWorksOrders({
          ...values,
          status: { $in: values.status },
          populate: true,
          page,
          limit: 2,
        })
      );
    },
  });

  useEffect(() => {
    let data = {};
    if (formik.values.location) {
      data["location"] = formik.values.location;
    }
    if (formik.values.manufacturer) {
      data["manufacturer"] = formik.values.manufacturer;
    }
    dispatch(
      fetchSendJobWorksOrders({
        ...data,
        status: { $in: formik.values.status },
        populate: true,
        page,
      })
    );
  }, [page]);

  const debouncedSearch = useCallback(
    _.debounce((search, values) => {
      dispatch(
        fetchSendJobWorksOrders({
          ...values,
          status: { $in: values.status },
          search: search,
          populate: true,
        })
      );
    }, 300),
    []
  );

  return (
    <>
      <ViewJobPanel
        open={viewPurchaseModel}
        setOpen={setViewPurchaseModalOpen}
        party={viewingParty}
      />
      <PageWithCard heading="View Job Work Orders">
        {/* {loading ? (
        <ClipLoader />
      ) : ( */}
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikMultiSelect
            formik={formik}
            label="Manufacturer"
            name="manufacturer"
            options={generateOptions({
              array: users ? users.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
            required
          />

          <FormikMultiSelect
            formik={formik}
            label="Location"
            name="location"
            required
            options={generateOptions({
              array: locationdata,
              valueField: "_id",
              labelField: "name",
            })}
          />

          <FormikMultiSelect
            formik={formik}
            label="Status"
            name="status"
            required
            options={generateOptions({
              array: [
                { label: "Pending", value: "pending" },
                { label: "Completed", value: "completed" },
                { label: "Settled", value: "settled" },
                { label: "Incomplete", value: "incomplete" },
              ],
              valueField: "value",
              labelField: "label",
            })}
          />

          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
        {/* // )} */}
        {/* <EditGreyModal /> */}
        <TableWithHeadingAndGlobalSearch
          heading="Job Work Orders"
          data={data}
          columns={columns}
          loading={jobWorkPoLoading}
          searchFunction={(value) => {
            debouncedSearch(value, formik.values);
          }}
        />
        <PaginationClassic paginationDetails={jobWorkPO} setPage={setPage} />
      </PageWithCard>
    </>
  );
};

export default ViewJobWork;
