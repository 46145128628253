import React from "react";

const DangerButton = ({ children, className, type = "button", ...props }) => {
  return (
    <button
      className={`btn bg-rose-500 hover:bg-rose-600 text-white ${className}`}
      {...props}
      type={type}
    >
      {children}
    </button>
  );
};

export default DangerButton;
