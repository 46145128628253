import QueryString from "qs";
import React, { useCallback, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import moment from "moment";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import _ from "lodash";
import { exportCsv } from "../../utils/Utils";
import { limitVar } from "../../utils/limitVariable";

export const ProductSalesReport = () => {
  const [productSalesData, setProductSalesData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProductSalesReport();
  }, [page]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate: moment().startOf("day").valueOf(),
      endDate: moment().endOf("day").valueOf(),
    },
    onSubmit: async (values) => {
      console.log(values);
      try {
        setLoading(true);
        const data = {
          createdAt: {
            $gte: values.startDate,
            $lte: values.endDate,
          },
          populate: true,
        };
        const string = QueryString.stringify(data);
        const resp = await authAxiosInstance.get(
          `/report/product-sales?${string}`
        );
        console.log(resp);
        setProductSalesData(resp?.data?.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch sales");
      } finally {
        setLoading(false);
      }
    },
  });

  const getProductSalesReport = async (search) => {
    try {
      setLoading(true);
      const data = {
        createdAt: {
          $gte: formik.values.startDate,
          $lte: formik.values.endDate,
        },
        populate: true,
        page,
      };
      if (search) {
        data.search = search;
      }
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(
        `/report/product-sales?${string}`
      );
      console.log(resp, "data");
      setProductSalesData(resp?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch product sales");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + productSalesData?.pagingCounter;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row?.original?.createdAt).format("DD/MM/YYYY");
      },
    },

    {
      Header: "Customer Name",
      accessor: "customerName",
    },
    {
      Header: "SKU",
      accessor: "product.sku",
    },
    {
      Header: "Price",
      accessor: "product.price",
    },
    {
      Header: "Quantity",
      accessor: "product.qty",
    },
    {
      Header: "Sell Price",
      accessor: "product.sellPrice",
    },
    // {
    //   Header: "Received Amount",
    //   Cell: ({ row }) => {
    //     if (row?.original?.receivedAmount) {
    //       const totalAmount = row?.original?.receivedAmount?.reduce(
    //         (acc, crr) => {
    //           return acc + crr?.amount;
    //         },
    //         0
    //       );

    //       return totalAmount;
    //     } else {
    //       return "No  Received Amount Found";
    //     }
    //   },
    // },
  ];

  const downloadExportCsv = async () => {
    try {
      setLoading(true);
      const data = {
        createdAt: {
          $gte: formik.values.startDate,
          $lte: formik.values.endDate,
        },
        populate: true,
        limit: limitVar,
      };
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(
        `/report/product-sales?${string}`
      );
      if (resp?.data?.data?.docs?.length > 0) {
        const csvData = resp?.data?.data?.docs?.map((el) => {
          //   const totalAmount = el?.receivedAmount?.reduce((acc, crr) => {
          //     return acc + crr?.amount;
          //   }, 0);
          return {
            "Customer Name": el?.customerName,
            SKU: el?.product?.sku,
            Price: el?.product?.price || 0,
            Quantity: el?.product?.qty || 0,
            "Sell Price": el?.product?.sellPrice || 0,
            // "Received Amount": totalAmount || 0,
          };
        });
        console.log(csvData, "csv");
        exportCsv(csvData);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to Download");
    } finally {
      setLoading(false);
    }
  };

  const dataMemo = useMemo(
    () => (productSalesData?.docs ? productSalesData?.docs : []),
    [productSalesData?.docs]
  );
  const columnsMemo = useMemo(() => columns, [columns]);

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      console.log("searching");
      getProductSalesReport(search);
    }, 300),
    [JSON.stringify(productSalesData)]
  );

  return (
    <PageWithCard heading="Product Sales Report">
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-2 gap-2 m-2"
      >
        <FormikInputDateGroup
          label="Start Date"
          name="startDate"
          formik={formik}
        />
        <FormikInputDateGroup label="End Date" name="endDate" formik={formik} />
        <div className="flex flex-row gap-2">
          <PrimaryButton type="submit">Submit</PrimaryButton>

          <PrimaryButton type="button" onClick={downloadExportCsv}>
            Download
          </PrimaryButton>
        </div>
      </form>
      <TableWithHeadingAndGlobalSearch
        heading="Product Sales Report"
        data={dataMemo}
        columns={columnsMemo}
        loading={loading}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
      />
      <PaginationClassic
        paginationDetails={{
          ...productSalesData,
        }}
        setPage={setPage}
      />
    </PageWithCard>
  );
};
