import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { Edit2, Eye, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { fetchDirecPOs, getDirectPO } from "../../app/reducers/DirectPO/DirectPOSlice";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useFormik } from "formik";
import { generateOptions } from "../../utils/Utils";
import * as Yup from 'yup'
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import moment from 'moment'
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import PaginationClassic from "../../components/pagination/PaginationClassic";

const ViewDirectProductPO = () => {
    const dispatch = useDispatch();
    const [page,setPage] = useState(1)

    const { loading, productDirectPO } = useSelector(getDirectPO);
    const columns = [
        {
            Header: "SRNO",
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },
        {
            Header: "location",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.locationData?.name}
                    </span>
                );
            },
        },
        {
            Header: "Date",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.createdAt ? moment(row.original?.createdAt).format('DD-MM-YYYY') : ''}
                    </span>
                );
            },
        },
        {
            Header: "manufacturer/job_work",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.manufacturerData?.name}
                    </span>
                );
            },
        },
        {
            Header: "catalog",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.catalogData?.name}
                    </span>
                );
            },
        },
        {
            Header: "product",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.productData?.color}
                    </span>
                );
            },
        },
        {
            Header: "Quantity",
            accessor: "quantity",
        },
        {
            Header: "Type",
            accessor: "type",
        },
    ];
    const columnsMemo = useMemo(() => columns, [columns]);
    const dataMemo = useMemo(() => (productDirectPO?.docs ? productDirectPO.docs : []), [productDirectPO.docs]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            type: '',
            startDate: '',
            endDate: ''
        },
        validationSchema: Yup.object({
            type: Yup.string().required(),
            startDate: Yup.mixed().required(),
            endDate: Yup.mixed().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            dispatch(fetchDirecPOs({
                type: values.type, populate: true, createdAt: {
                    $gte: values.startDate,
                    $lte: values.endDate,
                },
            }))
        },
    })

    useEffect(() => {
        let data = {}
        if(formik.values.startDate && formik.values.endDate) { 
            data["createdAt"] = {
                $gte: formik.values.startDate,
                $lte: formik.values.endDate,
            }
        }
        if(formik.values.type) {
            data['type']= formik.values.type
        }
        dispatch(fetchDirecPOs({ populate: true, page: page,...data }));
    }, [page]);


    return (
        <PageWithCard heading="View Direct Product Purchase Order">
            <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4 mb-3'>
                <FormikSelectGroup
                    formik={formik}
                    label='Select Type'
                    name='type'
                    options={generateOptions({
                        array: [{ label: 'finished', value: 'finished' }, { label: 'unfinished', value: 'unfinished' }, { label: 'unfinishedEmbroidery', value: 'unfinished embroidery' }],
                        valueField: 'label',
                        labelField: 'value',
                    })}
                    required
                />
                <FormikInputDateGroup
                    formik={formik}
                    label="From"
                    name="startDate"
                    required
                />
                <FormikInputDateGroup formik={formik} label="To" name="endDate" required />
                <div>
                    <PrimaryButton type='submit'>Submit</PrimaryButton>
                </div>
            </form>
            <TableWithHeadingAndSearch
                columns={columnsMemo}
                data={dataMemo}
                heading="Direct Product Purchase Order"
            />
            <PaginationClassic paginationDetails={productDirectPO} setPage={setPage} />
        </PageWithCard>
    );
};

export default ViewDirectProductPO;
