import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchGreyQualities,
  getGreyQualities,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import { useMemo } from "react";
import { useState } from "react";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { createDirectPO } from "../../app/reducers/DirectPO/DirectPOSlice";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import { X } from "react-feather";
import ProgressBar from "../../components/progressBar/ProgressBar";

const DirectUnfinishedOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [progressLoading, setProgressLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchGreyQualities());
    dispatch(fetchLocations());
  }, []);

  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const { loading: greyLoading, greyQuality } = useSelector(getGreyQualities);
  const { loading: catalogLoading, catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);
  const locationInfoId = localStorage.getItem("locationInfoId");

  const data = useMemo(() => (catalog?.docs ? catalog.docs : []), [catalog]);
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );
  // console.log(currentItem, "index")

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      manufacturer: "",
      location: locationInfoId,
      catalog: "",
      products: [],
    },
    validationSchema: Yup.object({
      manufacturer: Yup.string().required(),
      location: Yup.string().required(),
      catalog: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      setProgressLoading(true);
      setTotalItem(values.products.length);
      for (let i = 0; i < values.products.length; i++) {
        const product = values.products[i];
        const data = {
          location: values.location,
          catalog: values.catalog,
          manufacturer: values.manufacturer,
          product: product.product,
          quantity: product.quantity,
        };
        await dispatch(createDirectPO({ ...data, type: "unfinished" }));
        setCurrentItem((prevItem) => prevItem + 1);
      }
      formik.resetForm();
    },
  });

  return (
    <>
      <PageWithCard heading="Direct Unfinished Order">
        <div className="text-slate-800 font-semibold mb-4">Purchase Order</div>
        {/* {loading ? (
                <ClipLoader />
            ) : ( */}
        {progressLoading ? (
          <ProgressBar currentItem={currentItem} totalItem={totalItem} />
        ) : (
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
            <FormikSelectGroup
              formik={formik}
              label="Manufacturer"
              name="manufacturer"
              required
              onChange={(data) => {
                dispatch(fetchCatalogs({ manufacturer: data.value }));
                formik.setFieldValue("manufacturer", data.value);
              }}
              onBlur={formik.handleBlur}
              options={generateOptions({
                array: users ? users.docs : [],
                valueField: "_id",
                labelField: "name",
              })}
            />
            {formik.values.manufacturer && (
              <>
                <FormikSelectGroup
                  formik={formik}
                  label="Location"
                  name="location"
                  required
                  options={generateOptions({
                    array: location ? location.docs : [],
                    valueField: "_id",
                    labelField: "name",
                  })}
                />
                <FormikSelectGroup
                  formik={formik}
                  label="Select Catalog"
                  name={`catalog`}
                  onChange={async (selectedOption) => {
                    dispatch(fetchProducts({ catalog: selectedOption.value }));
                    formik.setFieldValue(`catalog`, selectedOption.value);
                  }}
                  options={generateOptions({
                    array: data,
                    valueField: "_id",
                    labelField: "name",
                  })}
                  required
                />
                {formik.values.catalog && (
                  <FormikProvider value={formik}>
                    <FieldArray
                      name="products"
                      render={(arrayHelpers) => {
                        return (
                          <div>
                            {formik.values.products.map((item, index) => (
                              <div
                                className="relative flex flex-col gap-4"
                                key={index}
                              >
                                <X
                                  color="red"
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="absolute top-0 right-0"
                                />
                                <FormikSelectGroup
                                  formik={formik}
                                  label="Select Color"
                                  name={`products.${index}.product`}
                                  options={generateOptions({
                                    array: productData,
                                    valueField: "_id",
                                    labelField: "color",
                                  })}
                                  required
                                />

                                <FormikInputGroup
                                  formik={formik}
                                  label="Quantity"
                                  type="number"
                                  name={`products.${index}.quantity`}
                                  required
                                />
                              </div>
                            ))}
                            <SecondaryButton
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  product: "",
                                  quantity: "",
                                })
                              }
                            >
                              Add More
                            </SecondaryButton>
                          </div>
                        );
                      }}
                    />
                  </FormikProvider>
                )}
              </>
            )}
            <div>
              <PrimaryButton type="submit" onClick={formik.handleSubmit}>
                Submit
              </PrimaryButton>
            </div>
          </form>
        )}
      </PageWithCard>
    </>
  );
};

export default DirectUnfinishedOrder;
