import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "jobWork";

export const fetchJobWorks = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editJobWorks = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchJobWork = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteJobWork = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createJobWork = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const getJobWorkSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchJobWorks, SLICE_NAME),
    ...createData.generateExtraReducers(createJobWork, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteJobWork, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchJobWork, SLICE_NAME),
    ...editData.generateExtraReducers(fetchEditData, SLICE_NAME)
  },
});

export const getJobWork = (state) => state.jobWork;
export const { setEditId } = getJobWorkSlice.actions;
export default getJobWorkSlice.reducer;
