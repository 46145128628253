import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikFileInput from "../../components/formik/FormikFileInput";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import {
  createProductWithFile,
  fetchProducts,
} from "../../app/reducers/Product/productSlice";
import { generateOptions } from "../../utils/Utils";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import { useMemo } from "react";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { authAxiosInstance } from "../../utils/axiosConfig";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";

const CreateProduct = () => {
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [errorData, setErrorData] = useState([]);
  const [progressLoading, setProgressLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchCatalogs());
  }, []);
  const { catalog } = useSelector(getCatalog);

  const catalogData = useMemo(
    () => (catalog?.docs ? catalog.docs : []),
    [catalog]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      catalog: "",
      products: [
        {
          color: "",
          image: "",
          size: ["free"],
        },
      ],
    },
    validationSchema: Yup.object({
      catalog: Yup.string().required(),
      products: Yup.array()
        .of(
          Yup.object({
            color: Yup.string().required("color is required"),
            image: Yup.string(),
          })
        )
        .required()
        .min(1, "Please Add atleast one product"),
    }),
    onSubmit: async (values, { resetForm }) => {
      let catalogDetail = catalogData.find((d) => d._id == values.catalog);

      if (values.products.length > 0) {
        setProgressLoading(true);
        setTotalItem(values.products.length);
        values.products.forEach(async (item, index) => {
          const resp = await dispatch(
            fetchProducts({ catalog: catalogDetail._id })
          );
          const catalogData = resp?.payload?.data?.docs?.map((el) =>
            el.color.toLowerCase()
          );
          if (catalogData.includes(item.color.toLowerCase())) {
            setErrorData((prev) => [
              ...prev,
              {
                // ...item.color,
                msg: `${item.color} already Exists`,
              },
            ]);
          } else {
            let data = {
              catalog: values.catalog,
              catalogName: catalogDetail.name,
              category: catalogDetail.category,
              catalogId: catalogDetail.catalogId,
              ...values.products[index],
            };
            const response = await authAxiosInstance.post(`product`, data, {
              headers: { "Content-Type": "multipart/form-data" },
            });
            if (response.data) {
              setCurrentItem((prevState) => prevState + 1);
            }
          }
        });
      }
      resetForm();
    },
  });

  return (
    <PageWithCard heading="Product">
      <div className="text-slate-800 font-semibold mb-4">Create Product</div>

      {progressLoading ? (
        <ProgressBar
          currentItem={currentItem}
          totalItem={totalItem}
          errorData={errorData}
        />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          {/* <FormikSelectGroup
            formik={formik}
            label='Catalog'
            name='catalog'
            options={generateOptions({
              array: catalogData,
              valueField: '_id',
              labelField: 'name',
            })}
            required
          /> */}

          <FormikAsyncSelect
            formik={formik}
            label="Select Catalog"
            getOptions={async (search) => {
              const resp = await dispatch(fetchCatalogs({ search }));
              console.log(resp, "checking response");
              return resp.payload.data.docs.map((ele) => ({
                label: ele.name,
                value: ele._id,
              }));
            }}
            onChange={async (selectedOption) => {
              console.log(selectedOption, "selected Option");
              // dispatch(fetchProducts({ catalog: selectedOption.value }));
              formik.setFieldValue(`catalog`, selectedOption.value);
              formik.setFieldValue(`catalogName`, selectedOption.label);

            }}
            name={`catalog`}
            options={generateOptions({
              array: catalogData,
              valueField: "_id",
              labelField: "name",
            })}
            required
          />

          <FormikProvider value={formik}>
            <FieldArray
              name="products"
              render={(arrayHelpers) => {
                return (
                  <div className="flex flex-col gap-2">
                    {/* <p>Address</p> */}
                    <div>
                      {formik.values.products.map((ele, index) => (
                        <div
                          className="relative p-4 mb-2"
                          style={{
                            border: "1px solid #d6c7c7",
                            borderRadius: "5px",
                          }}
                          key={index}
                        >
                          <FormikInputGroup
                            formik={formik}
                            name={`products.${index}.color`}
                            required
                            label="Color"
                          />
                          <FormikMultiSelect
                            formik={formik}
                            label="Size"
                            name={`products.${index}.size`}
                            required
                            options={generateOptions({
                              array: [{ label: "Free", value: "free" }],
                              valueField: "value",
                              labelField: "label",
                            })}
                          />
                          {(formik.values.catalogName && formik.values.products[index].color) &&
                            <FormikDirectFileUpload
                              name={`products.${index}.image`}
                              formik={formik}
                              label="Image"
                              location={`trendy_sarees2/catalogs/${formik.values.catalogName}/`}
                              fileName={formik.values.products[index].color}
                            />}
                          {/* <FormikFileInput
                            name={`products.${index}.image`}
                            formik={formik}
                            label="Image"
                          /> */}
                          <div>
                            <DangerButton
                              className="mt-3"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                              type="button"
                            >
                              Remove
                            </DangerButton>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <SecondaryButton
                        onClick={() => {
                          arrayHelpers.push({ size: ["free"] });
                        }}
                        type="button"
                      >
                        Add More
                      </SecondaryButton>
                    </div>
                  </div>
                );
              }}
            />
          </FormikProvider>
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  );
};

export default CreateProduct;
