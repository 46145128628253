import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { useNavigate } from "react-router-dom";
import { generateOptions } from "../../utils/Utils";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchGreyPurchaseOrders,
  updateStatusGreyPurchaseOrder,
  getGreyPo,
  setEditId,
  reset,
} from "../../app/reducers/GreyPurchaseOrder/greyPOSlice";
import { Edit2, Eye, Trash } from "react-feather";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { useCallback } from "react";
import { useMemo } from "react";
import EditPurchaseModel from "./EditPurchaseModel";
import { useState } from "react";
import ViewPurchasePanel from "./ViewPurchasePanel";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import moment from "moment";
import ImageComponent from "../../components/ImageComponent";

const ViewPurchaseOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [viewPurchaseModel, setViewPurchaseModalOpen] = useState(false);
  const [viewingParty, setViewingParty] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(reset({}));
  }, []);

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchLocations());
    dispatch(fetchCatalogs());
  }, []);

  const { users } = useSelector(getUsers);
  const { location } = useSelector(getLocation);
  const { greyPO, loading: greyPoLoading } = useSelector(getGreyPo);

  const handleSettled = async (rowData) => {
    let data = {
      _id: rowData._id,
      greyColor: rowData.greyColor,
      location: rowData.location,
      remaining: rowData.remaining,
      status: "settled",
    };
    await dispatch(updateStatusGreyPurchaseOrder(data));
    let oldData = {};
    if (formik.values.location) {
      oldData["location"] = formik.values.location;
    }
    if (formik.values.manufacturer) {
      oldData["manufacturer"] = formik.values.manufacturer;
    }
    dispatch(
      fetchGreyPurchaseOrders({
        ...oldData,
        status: { $in: formik.values.status },
        populate: true,
        page,
      })
    );
  };

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        console.log(row);
        return (
          <ImageComponent src={row.original?.greyColorData?.image}
            style={{ width: "120px" }} />

        );
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      Header: "Grey Quality",
      accessor: "greyData.name",
    },
    {
      Header: "Grey Color",
      accessor: "greyColorData.color",
    },
    {
      Header: "Location",
      accessor: "locationData.name",
    },
    {
      Header: "Unit",
      accessor: "greyData.unit",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Ordered Total",
      accessor: "quantity",
    },
    {
      Header: "Ordered Remaining",
      accessor: "remaining",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Eye
              size={18}
              color="green"
              onClick={() => {
                setViewPurchaseModalOpen(true);
                setViewingParty(row.original);
              }}
            />
            {/* <Edit2
                            color='blue'
                            onClick={() => {
                                dispatch(setEditId(row.original._id));
                            }}
                        /> */}
            {row.original.status == "settled" ? (
              <div>Settled</div>
            ) : ["direct", "completed"].includes(row.original.status) ? (
              ""
            ) : (
              <PrimaryButton onClick={() => handleSettled(row.original)}>
                Settled
              </PrimaryButton>
            )}
          </div>
        );
      },
    },
  ];

  const locationdata = useMemo(
    () => (location?.docs ? location.docs : []),
    [location]
  );
  const { catalog } = useSelector(getCatalog);

  const catalogData = useMemo(
    () => (catalog?.docs ? catalog.docs : []),
    [catalog]
  );
  const data = useMemo(() => (greyPO?.docs ? greyPO.docs : []), [greyPO]);
  // console.log(data,"dekhtehai")

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: [],
      status: [],
      manufacturer: [],
      from: moment().startOf("day").valueOf(),
      to: moment().endOf("day").valueOf(),
    },

    onSubmit: async (values, { resetForm }) => {
      console.log(values, "checking");
      const checkData = {
        location: { $in: values.location },
        manufacturer: { $in: values.manufacturer },
        status: { $in: values.status },
        createdAt: {
          $gte: values.from,
          $lte: values.to,
        },
      };
      dispatch(fetchGreyPurchaseOrders({ ...checkData, populate: true, page }));
      // formik.values.photo = ''
      // resetForm()
    },
  });

  useEffect(() => {
    let data = {
      location: { $in: formik.values.location },
      manufacturer: { $in: formik.values.manufacturer },
      status: { $in: formik.values.status },
      createdAt: {
        $gte: formik.values.from,
        $lte: formik.values.to,
      },
    };

    dispatch(
      fetchGreyPurchaseOrders({
        ...data,

        populate: true,
        page,
      })
    );
  }, [page]);

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      let data = {
        location: { $in: formik.values.location },
        manufacturer: { $in: formik.values.manufacturer },
        status: { $in: formik.values.status },
      };
      dispatch(
        fetchGreyPurchaseOrders({
          ...data,

          search: search,
          populate: true,
        })
      );
    }, 300),
    []
  );

  return (
    <>
      <ViewPurchasePanel
        open={viewPurchaseModel}
        setOpen={setViewPurchaseModalOpen}
        party={viewingParty}
      />
      <EditPurchaseModel values={formik.values} />
      <PageWithCard heading="View Purchase Order">
        {/* {loading ? (
        <ClipLoader />
      ) : ( */}
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
          <FormikMultiSelect
            formik={formik}
            label="Manufacturer"
            name="manufacturer"
            options={generateOptions({
              array: users ? users.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
            required
          />

          <FormikMultiSelect
            formik={formik}
            label="Location"
            name="location"
            required
            options={generateOptions({
              array: locationdata,
              valueField: "_id",
              labelField: "name",
            })}
          />

          <FormikMultiSelect
            formik={formik}
            label="Status"
            name="status"
            required
            options={generateOptions({
              array: [
                { label: "Direct", value: "direct" },
                { label: "Pending", value: "pending" },
                { label: "Completed", value: "completed" },
                { label: "Settled", value: "settled" },
                { label: "Incomplete", value: "incomplete" },
              ],
              valueField: "value",
              labelField: "label",
            })}
          />
          <FormikInputDateGroup
            formik={formik}
            label="From"
            name="from"
            required
          />
          <FormikInputDateGroup formik={formik} label="To" name="to" required />

          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>
        {/* // )} */}
        {/* <EditGreyModal /> */}
        <TableWithHeadingAndGlobalSearch
          heading="Purchase Orders"
          data={data}
          columns={columns}
          loading={greyPoLoading}
          searchFunction={(value) => {
            debouncedSearch(value);
          }}
        />
        <PaginationClassic paginationDetails={greyPO} setPage={setPage} />
      </PageWithCard>
    </>
  );
};

export default ViewPurchaseOrder;
