import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "stockTransfer";

export const fetchStocks = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editStock = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchStock = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteStock = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createStockTransfer = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const stockTransferSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchStocks, SLICE_NAME),
    ...createData.generateExtraReducers(createStockTransfer, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteStock, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchStock, SLICE_NAME),
    ...editData.generateExtraReducers(editStock, SLICE_NAME)
  },
});

export const getStockTransfer = (state) => state.stockTransfer;
export const { setEditId } = stockTransferSlice.actions;
export default stockTransferSlice.reducer;
