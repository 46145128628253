import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "directPO";

export const fetchDirectPurchaseOrders = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editDirectPurchaseOrder = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchDirectPurchaseOrder = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteDirectPurchaseOrder = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createDirectPurchaseOrder = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const directPurchaseOrder = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchDirectPurchaseOrders, SLICE_NAME),
    ...createData.generateExtraReducers(createDirectPurchaseOrder, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteDirectPurchaseOrder, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchDirectPurchaseOrder, SLICE_NAME),
    ...editData.generateExtraReducers(editDirectPurchaseOrder, SLICE_NAME),
  },
});

export const getDirectPo = (state) => state.directPurchaseOrder;
export const { setEditId } = directPurchaseOrder.actions;
export default directPurchaseOrder.reducer;
