import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TableWithHeadingAndGlobalSearch from "../components/Table/TableWithHeadingAndGlobalSearch";
import { authAxiosInstance } from "../utils/axiosConfig";

const InventoryFilter = () => {
  const [greyQualityCount, setGreyQualityCount] = useState();
  const [greyQualityData, setGreyQualityData] = useState();
  const { unit } = useParams();
  const navigate = useNavigate();

  useEffect(async () => {
    if (unit) {
      const result = await authAxiosInstance.get("dashboard");
      if (result) {
        setGreyQualityCount(result.data?.data?.greyQuality);
        setGreyQualityData(
          result.data?.data?.greyQualityData?.filter(
            (d) => d.greyQuality?.unit == unit
          )
        );
      }
    }
  }, [unit]);

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      console.log("searching");
      dispatch(fetchEmails({ search: search }));
    }, 300),
    []
  );

  const colsMemo = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        console.log(row);
        return (
          <img
            src={row.original?.greyColor?.image}
            style={{ width: "120px" }}
          />
        );
      },
    },
    {
      Header: "Grey Color",
      accessor: "greyColor.color",
    },
    {
      Header: "Grey Quality",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <a
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/sendOrder/${row.original.greyQuality?._id}`);
            }}
          >
            <span style={{ textDecoration: "underline", color: "blue" }}>
              {row.original?.greyQuality?.name}
            </span>
          </a>
        );
      },
    },
    {
      Header: "Unit",
      accessor: "greyQuality.unit",
    },
    {
      Header: "Pending",
      accessor: "pending",
    },
    {
      Header: "Received",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original.greyQuality?.unit === "pcs"
              ? row.original.received
              : row.original.received.toFixed(2)}
          </span>
        );
      },
    },
    {
      Header: "Pending Print",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original.greyQuality?.unit === "pcs"
              ? row.original.pendingPrint
              : row.original.pendingPrint.toFixed(2)}
          </span>
        );
      },
    },
    {
      Header: "Pending Embroidery",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>

            {row.original?.greyQuality?.unit === "pcs"

              ? row.original.pendingGreyEmbroidery
              : row.original.pendingGreyEmbroidery.toFixed(2)}
          </span>
        );
      },
    },
    {
      Header: "Finished Print",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original.greyQuality?.unit === "pcs"
              ? row.original.finishedPrint
              : row.original.finishedPrint.toFixed(2)}
          </span>
        );
        return;
      },
    },
    {
      Header: "Damaged",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original.greyQuality?.unit === "pcs"
              ? row.original.damaged
              : row.original.damaged.toFixed(2)}
          </span>
        );
      },
    },
    {
      Header: "Shortage",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original.greyQuality?.unit === "pcs"
              ? row.original.shortage
              : row.original.shortage.toFixed(2)}
          </span>
        );
      },
    },
  ];

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="mb-8">
        {/* Title */}
        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
          Grey Inventory
        </h1>
        <p>Type: - {unit}</p>
      </div>
      {greyQualityData && (
        <TableWithHeadingAndGlobalSearch
          heading="Grey Inventory"
          data={greyQualityData}
          columns={colsMemo}
        />
      )}
      <div className="bg-white shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px"></div>
      </div>
    </div>
  );
};

export default InventoryFilter;
