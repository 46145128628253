import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { Edit2, Trash, X } from "react-feather";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { useCallback } from "react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  fetchProducts,
  getProduct,
  setEditId,
  deleteProduct,
  editProduct,
} from "../../app/reducers/Product/productSlice";
import EditProductModal from "./EditProductModel";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import EditProduct from "./EditProduct";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import {
  discontinueCatalog,
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  deleteSingleObject,
  fetchAssets,
  getMediaLibrary,
} from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import QueryString from "qs";
import ImageComponent from "../../components/ImageComponent";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";

const ViewProduct = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const { baseUrl } = useSelector(getMediaLibrary);
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchProducts({ catalog: id, populate: true }));
    dispatch(fetchAssets({ currentFolder: "" }));
  }, []);

  const { loading, product } = useSelector(getProduct);

  const data = useMemo(() => (product?.docs ? product.docs : []), [product]);

  const openImagePreview = (row) => {
    setPhotoIndex(row.index);
    setIsOpen(true);
  };

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Photo",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <>
            {row.original?.image ? (
              <div style={{ position: "relative" }}>
                <X
                  className="cursor-pointer"
                  onClick={async (e) => {
                    e.stopPropagation();
                    const resp = await dispatch(
                      deleteSingleObject({
                        keys: row.original?.image,
                      })
                    );
                    await dispatch(
                      editProduct({ _id: row.original._id, image: "" })
                    );
                    console.log(resp);
                    if (resp.payload.data) {
                      toast.success("Image Deleted");
                      dispatch(fetchProducts({ catalog: id, populate: true }));
                    }
                  }}
                  style={{ position: "absolute", top: "0px", left: "-24px" }}
                />
                {/* <img
                  onClick={() => openImagePreview(row)}
                  src={baseUrl + row.original?.image}
                  style={{ width: "150px", height: "150px", cursor: "pointer" }}
                /> */}
                <ImageComponent
                  src={row.original?.image}
                  style={{ width: "150px", height: "150px", cursor: "pointer" }}
                  baseUrlRequired={true}
                />
              </div>
            ) : (
              <PrimaryButton
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditData(row.original);
                  setModalOpen(true);
                }}
              >
                Add Photo
              </PrimaryButton>
            )}
          </>
        );
      },
    },
    {
      Header: "Color",
      accessor: "color",
    },
    {
      Header: "Sku",
      accessor: "sku",
    },
    {
      Header: "Price",
      // Cell: (prop) => {
      //   const { data, row } = prop;
      //   return <span>{row.original?.catalogData?.price}</span>;
      // },
      accessor: "catalogData.price",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        console.log("action col", row.original);
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <Edit2
              color="blue"
              onClick={() => {
                dispatch(setEditId(row.original._id));
              }}
            />
            <Trash
              color="red"
              onClick={async () => {
                const string = QueryString.stringify({
                  product: row?.original?._id,
                });
                const checkInventoryResp = await authAxiosInstance.get(
                  `productInventory/product?${string}`
                );
                // console.log("checkInventory", checkInventoryResp?.data?.data?.docs?.[0])
                const inventoryData = checkInventoryResp?.data?.data?.docs?.[0];

                if (
                  (inventoryData.finished == 0) &
                  (inventoryData.pendingJobWork == 0) &
                  (inventoryData.unfinishedEmbroidery == 0) &
                  (inventoryData.pendingUnfinishedEmbroidery == 0) &
                  (inventoryData.unfinished == 0) &
                  (inventoryData.sold == 0) &
                  (inventoryData.inTransfer == 0) &
                  (inventoryData.pending == 0)
                ) {
                  await dispatch(
                    deleteSingleObject({
                      keys: row.original?.image,
                    })
                  );
                  await dispatch(fetchProducts({ catalog: id }));
                  await dispatch(deleteProduct({ id: row.original._id }));
                  dispatch(fetchProducts({ catalog: id, populate: true }));
                  console.log("deleting product");
                } else {
                  toast.error("Can not delete this product");
                }
                // console.log("checkInventory====>", checkInventory)

                // const catalogData = resp?.payload?.data?.docs?.map(
                //   (el) => el.color
                // );
                // const colorCount = catalogData.filter(
                //   (item) => item === row?.original?.color
                // ).length;

                // if (colorCount > 1) {
                //
                // } else {
                //   toast.error("You are not allowed to delete this data");
                // }
              }}
            />
          </div>
        );
      },
    },
  ];

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchProducts({ search: search }));
    }, 300),
    []
  );

  return (
    <>
      <EditProduct
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        editData={editData}
        setEditData={setEditData}
      />
      <div className="relative">
        <PageWithCard heading={product?.docs?.[0]?.catalogData?.name}>
          <DangerButton
            className={"absolute top-9 right-10 md:right-32"}
            onClick={() => {
              const confirmRes = confirm(
                `Are you sure to Discontinue ${product?.docs?.[0]?.catalogData?.name}`
              );
              console.log(confirmRes);
              if (confirmRes) {
                return dispatch(discontinueCatalog(id));
              }
            }}
          >
            Discontinue
          </DangerButton>
          {isOpen && (
            <Lightbox
              mainSrc={data[photoIndex].image}
              nextSrc={data[(photoIndex + 1) % data.length]}
              prevSrc={data[(photoIndex + data.length - 1) % data.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + data.length - 1) % data.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % data.length)
              }
            />
          )}

          <TableWithHeadingAndSearch
            heading="Products"
            data={data}
            columns={columns}
            loading={loading}
            searchFunction={(value) => {
              debouncedSearch(value);
            }}
          />
          <EditProductModal catalogId={id} />
        </PageWithCard>
      </div>
    </>
  );
};

export default ViewProduct;
