import { useFormik } from "formik";
import React, { useEffect } from "react";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { getUsers, fetchUsers } from "../../app/reducers/Users/userSlice";
import { generateOptions } from "../../utils/Utils";
import { fetchGreyQualities } from "../../app/reducers/GreyQuality/greyQualitySlice";
import {
  fetchSendJobWorksOrders,
  updateSendJobWorksOrder,
  reset,
  getJobWorkPO,
} from "../../app/reducers/SendJobWork/SendJobWork";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import { useMemo } from "react";
import { useState } from "react";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import { toast } from "react-toastify";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import {
  getCatalog,
  fetchCatalogs,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchGreyEmbroideries,
  getGreyEmbroidery,
  editGreyEmbroidery,
} from "../../app/reducers/GreyEmbroidery/greyEmbroiderySlice";
import ImageComponent from "../../components/ImageComponent";
const inputStyle = {
  width: "100px",
  borderColor: "#e4e6fc",
  borderRadius: "7px",
};

const productStyle = {
  width: "70px",
  borderColor: "#e4e6fc",
  borderRadius: "7px",
};

const numberInputStyle = {
  width: "70px",
  borderColor: "#e4e6fc",
  borderRadius: "7px",
};

const ReceiveGreyEmbroidery = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset({}));
  }, []);

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchLocations());
  }, []);

  const { loading, users } = useSelector(getUsers);
  const { greyEmbroidery } = useSelector(getGreyEmbroidery);
  const { location, loading: locationLoading } = useSelector(getLocation);
  const [manufacturer, setManufacturere] = useState("");
  const [typeData, setTypeData] = useState([]);
  const greyEmbroideryData = useMemo(
    () =>
      greyEmbroidery?.docs
        ? greyEmbroidery.docs.filter(
          (d) => d.status != "completed" && d.status != "settled"
        )
        : [],
    [greyEmbroidery]
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { loading: catalogLoading, catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);

  const catalogData = useMemo(
    () => (catalog?.docs ? catalog.docs : []),
    [catalog]
  );
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      manufacturer: manufacturer ? manufacturer : "",
      catalog: "",
      product: "",
      location: "",
      poData: greyEmbroideryData
        ? greyEmbroideryData.map((d) => {
          return {
            ...d,
            activate: false,
            receivedNew: 0,
            damaged: 0,
            return: 0,
          };
        })
        : [],
    },
    onSubmit: async (values) => {
      formik.resetForm();
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, []);

  const submitDetails = async (e, index) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      // if (!formik.values.location || !formik.values.product || !formik.values.catalog || !formik.values.type) {
      //     return toast.error('Please select all fields!!')
      // }

      let detail = formik.values.poData[index];
      let received = [
        {
          date: new Date(),
          receivedItem: detail.receivedNew,
          damagedItem: detail.damaged,
          return: detail.return,
        },
      ];
      console.log("checkDetails", detail.catalog, detail.product, detail.type);
      // let received = [{ date: new Date(), receivedItem: detail.receivedNew, damagedItem: detail.damaged, return: detail.return, location: formik.values.location, product: formik.values.product, catalog: formik.values.catalog, type: formik.values.type }]
      if (
        detail.receivedNew + detail.damaged + detail.return >
        detail.remaining
      ) {
        return toast.error("Remaining limit Exceeded");
      }

      let remaining =
        detail.remaining -
        (detail.receivedNew + detail.damaged + detail.return);
      let data = {
        _id: detail._id,
        greyColor: detail.greyColor,
        location: detail.location,
        catalog: detail.catalog,
        product: detail.product,
        type: detail.type,
        received: [...detail.received, ...received],
        remaining: remaining,
      };
      if (remaining == 0) {
        data["status"] = "completed";
      } else {
        data["status"] = "incomplete";
      }
      // return
      await dispatch(editGreyEmbroidery(data));
      await dispatch(
        fetchGreyEmbroideries({
          manufacturer: formik.values.manufacturer,
          populate: true,
          status: { $in: ["pending", "incomplete"] },
        })
      );
      setManufacturere(manufacturer);
      formik.setFieldValue("manufacturer", formik.values.manufacturer);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-10">
      <h1 className="text-3xl font-bold">Receive Grey Embroidery</h1>
      <br></br>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <FormikSelectGroup
          formik={formik}
          label="Manufacturer"
          name="manufacturer"
          onChange={async (data) => {
            await dispatch(
              fetchGreyEmbroideries({
                manufacturer: data.value,
                populate: true,
                status: { $in: ["pending", "incomplete"] },
              })
            );
            dispatch(fetchCatalogs());
            setManufacturere(data.value);
            formik.setFieldValue("manufacturer", data.value);
          }}
          required
          options={generateOptions({
            array: users ? users.docs : [],
            valueField: "_id",
            labelField: "name",
          })}
        />
        {/* {
                    formik.values.manufacturer &&
                    <>
                        <FormikSelectGroup
                            formik={formik}
                            label='Location'
                            name='location'
                            // value={formik.values.manufacturer}
                            required
                            options={generateOptions({
                                array: location ? location.docs : [],
                                valueField: '_id',
                                labelField: 'name',
                            })}
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label='Select Catalog'
                            onChange={async (selectedOption) => {
                                let findCatalog = catalogData.find(d => d._id == selectedOption.value)
                                if (findCatalog?.isEmbroidery) {
                                    setTypeData([{ label: 'finished', value: 'finished' }, { label: 'unfinished', value: 'unfinished' }, { label: 'unfinishedEmbroidery', value: 'unfinished embroidery' }])
                                } else {
                                    setTypeData([{ label: 'finished', value: 'finished' }, { label: 'unfinished', value: 'unfinished' }])
                                }
                                dispatch(fetchProducts({ catalog: selectedOption.value }))
                                formik.setFieldValue(
                                    `catalog`,
                                    selectedOption.value
                                )
                            }}
                            name={`catalog`}
                            options={generateOptions({
                                array: catalogData,
                                valueField: '_id',
                                labelField: 'name',
                            })}
                            required
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label='Select Color'
                            name={`product`}
                            options={generateOptions({
                                array: productData,
                                valueField: '_id',
                                labelField: 'color',
                            })}
                            required
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label='Select Type'
                            name='type'
                            options={generateOptions({
                                array: typeData,
                                valueField: 'label',
                                labelField: 'value',
                            })}
                            required
                        />
                    </>
                } */}

        {/* border-collapse border border-slate-1000 table-auto w-full divide-y divide-slate-400 */}
        <div className="relative overflow-x-auto">
          <table
            className="table-auto w-full border text-center"
            style={{ padding: "10px" }}
          >
            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200 p-2">
              <th scope="col" style={{ padding: "15px" }}>
                SRNO
              </th>
              <th scope="col" style={{ width: "170px" }}>
                Photo
              </th>
              <th scope="col">Grey Quaity</th>
              <th scope="col">Grey Color</th>
              <th scope="col">Location</th>
              <th scope="col">Catalog</th>
              <th scope="col">Color</th>
              <th scope="col">Type</th>
              <th scope="col">Ordered Total</th>
              <th scope="col">Ordered Remaining</th>
              <th scope="col">Received</th>
              <th scope="col">Damaged</th>
              <th scope="col">Return</th>
              <th scope="col">Action</th>
            </thead>
            {formik.values.poData &&
              formik.values.poData.length > 0 &&
              formik.values.poData.map((product, index) => (
                <>
                  {/* {console.log("RGE product", product)} */}
                  <tbody className="text-sm divide-y divide-slate-200">
                    <td>{index + 1}.</td>
                    <td className="p-3">
                      <div
                        style={{
                          width: "170px",
                          height: "auto",
                          padding: "10px",
                        }}
                      >
                        {product?.greyColorData?.image && (
                          <ImageComponent src={product?.greyColorData?.image} />
                          // <img
                          //   src={product?.greyColorData?.image}
                          //   alt="nothing"
                          // />
                        )}
                      </div>
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={product?.greyQualityData?.name}
                        name={`product.${index}`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={product?.greyColorData?.color}
                        name={`product.${index}`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={product?.locationData?.name}
                        name={`product.${index}`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={product?.catalog}
                        name={`product.${index}`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={product?.productData?.color}
                        name={`product.${index}`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={inputStyle}
                        value={product?.type}
                        name={`product.${index}`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        formik={formik}
                        style={numberInputStyle}
                        value={product?.quantity}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        type="number"
                        formik={formik}
                        value={formik.values.poData[index]?.remaining}
                        style={numberInputStyle}
                        name={`poData.${index}.remaining`}
                        readOnly
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        type="number"
                        min={0}
                        formik={formik}
                        style={{
                          width: "70px",
                          backgroundColor:
                            !formik.values.poData[index].activate &&
                            "#D8D8D880",
                          borderColor: "#e4e6fc",
                          borderRadius: "7px",
                        }}
                        max={
                          formik.values.poData[index].remaining -
                          (formik.values.poData[index].damaged +
                            formik.values.poData[index].return)
                        }
                        disabled={!formik.values.poData[index].activate}
                        name={`poData.${index}.receivedNew`}
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        type="number"
                        formik={formik}
                        min={0}
                        style={{
                          width: "70px",
                          backgroundColor:
                            !formik.values.poData[index].activate &&
                            "#D8D8D880",
                          borderColor: "#e4e6fc",
                          borderRadius: "7px",
                        }}
                        max={
                          formik.values.poData[index].remaining -
                          (formik.values.poData[index].receivedNew +
                            formik.values.poData[index].return)
                        }
                        disabled={!formik.values.poData[index].activate}
                        name={`poData.${index}.damaged`}
                      />
                    </td>
                    <td className="p-3">
                      <FormikInputGroup
                        type="number"
                        formik={formik}
                        min={0}
                        style={{
                          width: "70px",
                          backgroundColor:
                            !formik.values.poData[index].activate &&
                            "#D8D8D880",
                          borderColor: "#e4e6fc",
                          borderRadius: "7px",
                        }}
                        max={
                          formik.values.poData[index].remaining -
                          (formik.values.poData[index].receivedNew +
                            formik.values.poData[index].damaged)
                        }
                        disabled={!formik.values.poData[index].activate}
                        name={`poData.${index}.return`}
                      />
                    </td>
                    <td className="p-3">
                      <div style={{ display: "flex", gap: "10px" }}>
                        {!formik.values.poData[index].activate && (
                          <PrimaryButton
                            onClick={() =>
                              formik.setFieldValue(
                                `poData.${index}.activate`,
                                true
                              )
                            }
                          >
                            Activate
                          </PrimaryButton>
                        )}
                        {formik.values.poData[index].activate && (
                          <>
                            <DangerButton
                              onClick={() =>
                                formik.setFieldValue(
                                  `poData.${index}.activate`,
                                  false
                                )
                              }
                            >
                              Deactivate
                            </DangerButton>
                            <PrimaryButton
                              disabled={isSubmitting}
                              onClick={(e) => submitDetails(e, index)}
                            >
                              Submit
                            </PrimaryButton>
                          </>
                        )}
                      </div>
                    </td>
                  </tbody>
                </>
              ))}
          </table>
        </div>
      </form>
    </div>
  );
};

export default ReceiveGreyEmbroidery;
