import _ from "lodash";
import moment from "moment";
import QueryString from "qs";
import { useFormik } from "formik";
import { authAxiosInstance } from "../../utils/axiosConfig";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { exportCsv } from "../../utils/Utils";
import { toast } from "react-toastify";
import { limitVar } from "../../utils/limitVariable";

export const ReturnReport = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [returnData, setReturnData] = useState([]);
  const [totalReturnAmount, setTotalReturnAmount] = useState({});

  useEffect(() => {
    getReturnReport();
    getReturnAmount();
  }, [page]);

  const formik = useFormik({
    initialValues: {
      startDate: moment().startOf("day").valueOf(),
      endDate: moment().endOf("day").valueOf(),
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const data = {
          createdAt: {
            $gte: values.startDate,
            $lte: values.endDate,
          },
          populate: true,
        };
        const string = QueryString.stringify(data);
        const respReturnData = await authAxiosInstance.get(`/return?${string}`);
        if (respReturnData) {
          setReturnData(respReturnData?.data?.data);
        }

        const respReturnAmount = await authAxiosInstance.get(
          `/report/return-amount?${string}`
        );
        if (respReturnAmount?.data?.data?.docs?.length > 0) {
          const sumAmount = respReturnAmount?.data?.data?.docs;
          sumAmount?.forEach((el) => setTotalReturnAmount(el));
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch Return Data");
      } finally {
        setLoading(false);
      }
    },
  });

  const getReturnReport = async (search) => {
    try {
      setLoading(true);
      const data = {
        createdAt: {
          $gte: formik.values.startDate,
          $lte: formik.values.endDate,
        },
        populate: true,
        page,
      };
      if (search) {
        data.search = search;
      }
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(`/return?${string}`);
      setReturnData(resp?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch return Data");
    } finally {
      setLoading(false);
    }
  };

  const getReturnAmount = async () => {
    try {
      setLoading(true);
      const data = {
        createdAt: {
          $gte: formik.values.startDate,
          $lte: formik.values.endDate,
        },
        populate: true,
        page,
      };
      //   if (search) {
      //     data.search = search;
      //   }
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(
        `/report/return-amount?${string}`
      );
      if (resp?.data?.data?.docs?.length > 0) {
        const sumAmount = resp?.data?.data?.docs;
        sumAmount?.forEach((el) => setTotalReturnAmount(el));
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to Sum Total Sales Amount");
    }
  };

  const downloadExportCsv = async () => {
    try {
      setLoading(true);
      const data = {
        createdAt: {
          $gte: formik.values.startDate,
          $lte: formik.values.endDate,
        },
        populate: true,
        limit: limitVar,
      };
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(`/return?${string}`);
      if (resp?.data?.data?.docs?.length > 0) {
        const csvData = resp?.data?.data?.docs?.map((el) => {
          return {
            Customer: el?.customerData?.name || "",
            Color: el?.productData?.color || "",
            "Catalog Name": el?.catalogData?.name || "",
            SKU: el?.productData?.sku || "",
            Quantity: el?.quantity || "",
            Type: el?.type || "",
            Price: ` ${el?.catalogData.price}` || "",
            Amount: ` ${el?.amount}` || "",
          };
        });
        console.log(csvData, "csv");
        exportCsv(csvData);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to Download");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + returnData?.pagingCounter;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row?.original?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      Header: "Customer",
      accessor: "customerData.name",
    },
    {
      Header: "Color",
      accessor: "productData.color",
    },
    {
      Header: "Catalog Name",
      accessor: "catalogData.name",
    },
    {
      Header: "SKU",
      accessor: "productData.sku",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Return Software",
      accessor: "dataType",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Price",
      Cell: ({ row }) => {
        return <>₹ {row?.original?.catalogData?.price}</>;
      },
    },

    {
      Header: "Amount",
      Cell: ({ row }) => {
        return <>₹ {row?.original?.amount}</>;
      },
    },
  ];

  const dataMemo = useMemo(
    () => (returnData?.docs ? returnData?.docs : []),
    [returnData?.docs]
  );
  const columnsMemo = useMemo(() => columns, [columns]);

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      console.log("searching");
      getReturnReport(search);
    }, 300),
    [JSON.stringify(returnData)]
  );

  return (
    <PageWithCard heading="Return Report">
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-2 gap-2 m-2"
      >
        <FormikInputDateGroup
          label="Start Date"
          name="startDate"
          formik={formik}
        />
        <FormikInputDateGroup label="End Date" name="endDate" formik={formik} />
        <div className="flex flex-row gap-2">
          <PrimaryButton type="submit">Submit</PrimaryButton>
          <PrimaryButton type="button" onClick={downloadExportCsv}>
            Download
          </PrimaryButton>
        </div>
      </form>
      {totalReturnAmount && (
        <div className="text font-semibold mb-2 flex flex-row gap-2">
          {totalReturnAmount?.totalAmt && (
            <>
              <p>
                Total Amount : <span>{`₹ ${totalReturnAmount?.totalAmt}`}</span>
              </p>
              <p>
                Total Qty :{" "}
                <span>
                  {totalReturnAmount ? totalReturnAmount?.totalQty : ""}
                </span>
              </p>
            </>
          )}
        </div>
      )}
      <TableWithHeadingAndGlobalSearch
        heading="Return Report"
        data={dataMemo}
        columns={columnsMemo}
        loading={loading}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
      />
      <PaginationClassic
        paginationDetails={{
          ...returnData,
        }}
        setPage={setPage}
      />
    </PageWithCard>
  );
};
