import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../utils/axiosConfig";

const initialState = {
  user: {},
  error: null,
  jwt: null,
  loading: false,
  userList:{},
  updateCustomer:{},
  fetchRole:{}
};

export const postRegister = createAsyncThunk(
  "user/postRegister",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`user/register`, data);
     
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue({
        error: "Some error has occured please try again",
      });
    }
  }
);

export const DeleteUser = createAsyncThunk(
  "user/postRegister",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`user/remove/${id}`);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue({
        error: "Some error has occured please try again",
      });
    }
  }
);

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (data, { rejectWithValue }) => {
    try {
      const _filters = data
      const response = await axiosInstance.post(`user/list`, _filters);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue({
        error: "Some error has occured please try again",
      });
    }
  }
);

export const updateRegister = createAsyncThunk(
  "user/updateRegister",
  async ( data, { rejectWithValue }) => {
    try {
      console.log("id", id)
      const response = await axiosInstance.post(`user/update/`, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue({
        error: "Some error has occured please try again",
      });
    }
  }
);


export const fetchRole = createAsyncThunk(
  "user/fetchRole",
  async ({ rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`role/list`);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue({
        error: "Some error has occured please try again",
      });
    }
  }
);


const authSlice = createSlice({
  name: "customer",
  initialState,
  extraReducers: {
    [postRegister.pending]: (state, action) => {
      state.loading = true;
    },
    [postRegister.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload.data.user;
    },
    [postRegister.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    
    [fetchUser.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.userList = action.payload.data;
    },
    [fetchUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },

    [updateRegister.pending]: (state, action) => {
      state.loading = true;
    },
    [updateRegister.fulfilled]: (state, action) => {
      state.loading = false;
      state.updateCustomer = action.payload.data;
    },
    [updateRegister.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },

    [fetchRole.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.fetchRole = action.payload.data;
    },
    [fetchRole.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

//  export const { fetchUser } = authSlice.actions;

export const getCustomer = (state) => state.customer;
export const { checkJwt, logout, } = authSlice.actions;
export default authSlice.reducer;
