import { FieldArray, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import FormikInputGroup from '../../components/formik/FormikInputGroup'
import FormikFileInput from '../../components/formik/FormikFileInput'
import FormikSelectGroup from '../../components/formik/FormikSelectGroup'
import FormikMultiSelect from '../../components/formik/FormikMultiSelect'
import { ClipLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton'
import DangerButton from '../../components/infrastructure/Buttons/DangerButton'
import {
  createProductWithFile
} from '../../app/reducers/Product/productSlice'
import { generateOptions } from '../../utils/Utils'
import {
  fetchCatalogs, getCatalog
} from '../../app/reducers/Catalog/catalogSlice'
import { useMemo } from 'react'
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton'
import ProgressBar from '../../components/progressBar/ProgressBar'
import { authAxiosInstance } from '../../utils/axiosConfig'
import { fetchGreyQualities, getGreyQualities } from '../../app/reducers/GreyQuality/greyQualitySlice'
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload'


const AddGreyColor = () => {
  const dispatch = useDispatch()
  const [currentItem, setCurrentItem] = useState(0)
  const [totalItem, setTotalItem] = useState(0)
  const [progressLoading, setProgressLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchGreyQualities())
  }, [])

  const { loading: greyLoading, greyQuality } = useSelector(getGreyQualities)

  const greyQualityData = useMemo(
    () => (greyQuality?.docs ? greyQuality.docs : []),
    [greyQuality]
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      greyQuality: '',
      products: [
        {
          color: '',
          image: '',
        }
      ]
    },
    validationSchema: Yup.object({
      greyQuality: Yup.string().required(),
      products: Yup.array()
        .of(
          Yup.object({
            color: Yup.string().required('color is required'),
            image: Yup.string(),
          })
        )
        .required()
        .min(1, 'Please Add atleast one product'),
    }),
    onSubmit: async (values, { resetForm }) => {
      let greyQualityDetail = greyQualityData.find(d => d._id == values.greyQuality)
      if (values.products.length > 0) {
        setProgressLoading(true)
        setTotalItem(values.products.length)
        values.products.forEach(async (item, index) => {
          let data = {
            greyQuality: values.greyQuality,
            greyQualityName: greyQualityDetail.name,
            ...values.products[index],
          }
          const response = await authAxiosInstance.post(`greyColor`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          if (response.data) {
            setCurrentItem((prevState) => prevState + 1)
          }
        })
      }
      resetForm()
    },
  })

  return (
    <PageWithCard heading='Grey Color'>
      <div className='text-slate-800 font-semibold mb-4'>Create Grey Color</div>

      {progressLoading ? (
        <ProgressBar currentItem={currentItem} totalItem={totalItem} />
      ) : (
        <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4'>
          <FormikSelectGroup
            formik={formik}
            label='Grey Quality'
            name='greyQuality'
            options={generateOptions({
              array: greyQualityData,
              valueField: '_id',
              labelField: 'name',
            })}
            required
          />
          <FormikProvider value={formik}>
            <FieldArray
              name='products'
              render={(arrayHelpers) => {
                return (
                  <div className='flex flex-col gap-2'>
                    {/* <p>Address</p> */}
                    <div>
                      {formik.values.products.map((ele, index) => (
                        <div
                          className='relative p-4 mb-2'
                          style={{
                            border: '1px solid #d6c7c7',
                            borderRadius: '5px',
                          }}
                          key={index}
                        >
                          <FormikInputGroup formik={formik} name={`products.${index}.color`} required label='Color' />
                          {formik.values.products[index].color &&
                            <FormikDirectFileUpload
                              name={`products.${index}.image`}
                              formik={formik}
                              label="Image"
                              location={`trendy_sarees2/greyColor/${formik.values.products[index].color}/`}
                              fileName={formik.values.products[index].color}
                            />
                          }
                          {/* <FormikFileInput name={`products.${index}.image`} formik={formik} label='Image' /> */}
                          <div>
                            <DangerButton
                              className='mt-3'
                              onClick={() => {
                                arrayHelpers.remove(index)
                              }}
                              type='button'
                            >
                              Remove
                            </DangerButton>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <SecondaryButton
                        onClick={() => {
                          arrayHelpers.push({ size: ["free"] })
                        }}
                        type='button'
                      >
                        Add More
                      </SecondaryButton>
                    </div>
                  </div>
                )
              }}
            />
          </FormikProvider>
          <div>
            <PrimaryButton type='submit'>Submit</PrimaryButton>
          </div>
        </form>
      )}
    </PageWithCard>
  )
}

export default AddGreyColor
