import { FieldArray, FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import {
  fetchGreyQualities,
  setEditId,
  getGreyQualities,
  fetchGreyQuality,
  editGreyQuality,
} from "../../app/reducers/GreyQuality/greyQualitySlice";
import EditModal from "../../components/infrastructure/Modals/EditModal";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import FormikFileInput from "../../components/formik/FormikFileInput";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import { useState } from "react";
import { GREY_QUALITY_UNITS } from "../../utils/dropdownOptions";

const EditGreyModal = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers({ role: "manufacturer" }));
  }, []);

  const [loadingUpload, setLoadingUpload] = useState(false);

  const { elementEditId, editDataLoading, elementEditData } =
    useSelector(getGreyQualities);
  const { users } = useSelector(getUsers);

  //   useDispatch()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...elementEditData,
    },
    onSubmit: async (values) => {
      if (values.photo) {
        const data = {
          photo: values.photo,
        };
        setLoadingUpload(true);
        const response = await authAxiosInstance.post(`greyQuality/url`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.data?.success) {
          let data = {
            ...values,
            photo: response.data?.data?.photo,
          };
          setLoadingUpload(false);
          await dispatch(editGreyQuality(data));
          dispatch(fetchGreyQualities());
        } else {
          toast.error("Something Went Wrong");
        }
      }else{
        setLoadingUpload(true);
        await dispatch(editGreyQuality(values));
        dispatch(fetchGreyQualities());
        setLoadingUpload(false);
      }
    },
  });
  useEffect(() => {
    if (elementEditId) {
      dispatch(fetchGreyQuality({ _id: elementEditId }));
    }
  }, [elementEditId]);
  return (
    <EditModal
      editProperty={elementEditId}
      setEditProperty={setEditId}
      getEditData={fetchGreyQuality}
      title="Edit Grey"
    >
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-4 p-4"
        >
          <FormikInputGroup label="Name" formik={formik} name="name" required />
          <FormikSelectGroup
            formik={formik}
            label="Unit"
            name="unit"
            required
            options={generateOptions({
              array: GREY_QUALITY_UNITS,
              valueField: "value",
              labelField: "label",
            })}
          />
          <FormikFileInput name="photo" formik={formik} label="Image" />
          <FormikSelectGroup
            formik={formik}
            label="Manufacturer"
            name="manufacturer"
            options={generateOptions({
              array: users ? users.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
            required
          />
          <div className="mt-4">
            {loadingUpload ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Edit</PrimaryButton>
            )}
          </div>
        </form>
      )}
    </EditModal>
  );
};

export default EditGreyModal;
