import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import ImageComponent from "../../components/ImageComponent";

const GreyInventoryInternal = () => {
  const { quality, location } = useParams();
  const [inventoryData, setInventoryData] = useState([]);
  useEffect(async () => {
    try {
      const locations = location.split(",");
      const string = QueryString.stringify({
        locations,
        quality,
      });
      const resp = await authAxiosInstance.get(
        `/dashboard/greyInternalInventory?${string}`
      );
      if (resp.data.data) {
        setInventoryData(resp.data.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch Inventory");
    }
  }, [quality, location]);
  const cols = [
    {
      Header: "Photo",
      Cell: ({ row }) => {
        if (row?.original?.greyColorData?.image) {
          return (
            <ImageComponent src={row.original.greyColorData.image} className={"max-w-[170px]"} />

          );
        } else {
          return <p>No Photo</p>;
        }
      },
    },
    {
      Header: "Color",
      accessor: "greyColorData.color",
    },
    {
      Header: "Pending",
      accessor: "pending",
    },
    {
      Header: "Pending Print",
      accessor: "pendingPrint",
    },
    {
      Header: "Pending Grey Embroidery",
      accessor: "pendingGreyEmbroidery",
    },
    {
      Header: "Received",
      accessor: "received",
    },
    {
      Header: "Finished Print",
      accessor: "finishedPrint",
    },
    {
      Header: "Shortage",
      accessor: "shortage",
    },
    {
      Header: "Damaged",
      accessor: "damaged",
    },
  ];
  const data = useMemo(() => inventoryData, [inventoryData]);
  return (
    <PageWithCard heading="Grey Internal Inventory">
      <TableWithHeadingAndSearch data={data} columns={cols} />
    </PageWithCard>
  );
};

export default GreyInventoryInternal;
