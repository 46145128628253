import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  fetchUser,
  getUsers,
  setEditId,
} from "../../app/reducers/Users/userSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import EditUserForm from "./EditUserForm";
import { generateOptions } from "../../utils/Utils";
import { getAuth } from "../../app/reducers/Auth/authSlice";

const EditUser = () => {
  const { elementEditId, users, editUserLoading } = useSelector(getUsers);
  const dispatch = useDispatch();
  const { user } = useSelector(getAuth);
  useEffect(() => {
    if (elementEditId) {
      dispatch(fetchUser({ _id: elementEditId }));
    } else {
      if (user.role === "admin") {
        dispatch(fetchUsers({ role: { $ne: "customer" } }));
      } else {
        dispatch(fetchUsers({ __t: "vendor" }));
      }
    }
  }, [elementEditId]);

  return (
    <PageWithCard>
      {elementEditId ? (
        <>
          {editUserLoading ? (
            <ClipLoader />
          ) : (
            <>
              <EditUserForm />
            </>
          )}
        </>
      ) : (
        <>
          <label>Select User to edit</label>
          <Select
            options={
              users?.docs?.map
                ? users?.docs.map((ele) => ({
                    value: ele["_id"],
                    label: `${ele["username"]} / ${ele["name"]}`,
                  }))
                : []
            }
            onChange={(selectedOption) => {
              dispatch(setEditId(selectedOption.value));
            }}
          />
        </>
      )}
    </PageWithCard>
  );
};

export default EditUser;
