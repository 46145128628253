import React from "react";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import { generateOptions } from "../../utils/Utils";
import { fetchCatalogs } from "../../app/reducers/Catalog/catalogSlice";
import { useDispatch } from "react-redux";
import { fetchProducts } from "../../app/reducers/Product/productSlice";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { useState } from "react";

export const PurchaseOrderContainer = ({ formik, index, arrayHelpers }) => {
  console.log("index", index)
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  return (
    <div
      className="relative p-4 mb-2"
      style={{
        border: "1px solid #d6c7c7",
        borderRadius: "5px",
      }}
      key={index}
    >
      <FormikAsyncSelect
        formik={formik}
        label="Select Catalog"
        name={`catalog.${index}.catalog`}
        getOptions={async (search) => {
          const resp = await dispatch(
            fetchCatalogs({ search, manufacturer: formik.values.manufacturer })
          );
          console.log(resp);
          return resp.payload.data.docs.map((ele) => ({
            label: ele.name,
            value: ele._id,
          }));
        }}
        onChange={async (selectedOption) => {

          dispatch(
            fetchProducts({
              catalog: selectedOption.value,
            })
          ).then((result) => {
            console.log("result", result)


            // console.log("resp", response)

            setProducts(result?.payload?.data?.docs);

            console.log("selectedOption", selectedOption)
            formik.setFieldValue(
              `catalog.${index}.catalog`,
              selectedOption.value
            );


          }).catch((err) => {
            console.log('err', err)
          });

        }}
        required
      />


      {formik?.errors?.catalog &&
        Array.isArray(formik?.errors?.catalog) &&
        formik?.errors?.catalog[index]?.catalog &&
        formik?.errors?.catalog[index]?.catalog ? (
        <p className="text-xs text-red-500">
          {formik.errors["catalog"][index]?.catalog}
        </p>
      ) : null}
      {formik.values?.catalog[index]?.catalog && (
        <>
          <FormikSelectGroup
            formik={formik}
            label="Select Color"
            name={`catalog.${index}.product`}
            onChange={(selectedOption) => {
              let productExist = formik.values.catalog.find(
                (d) =>
                  d.product == selectedOption.value &&
                  d.catalog == formik.values.catalog[index].catalog
              );
              if (productExist) {
                return toast.error("Product already exist");
              }
              formik.setFieldValue(
                `catalog.${index}.product`,
                selectedOption.value
              );
            }}
            options={generateOptions({
              array: products,
              valueField: "_id",
              labelField: "color",
            })}
            required
          />
          {formik?.errors?.catalog &&
            Array.isArray(formik?.errors?.catalog) &&
            formik?.errors?.catalog[index]?.product &&
            formik?.errors?.catalog[index]?.product ? (
            <p className="text-xs text-red-500">
              {formik.errors["catalog"][index].product}
            </p>
          ) : null}
          <FormikInputGroup
            formik={formik}
            label="Quantity"
            type="number"
            value={formik.values.catalog[index].quantity}
            name={`catalog.${index}.quantity`}
            required
          />
          {formik?.errors?.catalog &&
            Array.isArray(formik?.errors?.catalog) &&
            formik?.errors?.catalog[index]?.quantity ? (
            <p className="text-xs text-red-500">
              {formik.errors["catalog"][index].quantity}
            </p>
          ) : null}
        </>
      )}
      <div>
        <DangerButton
          className="mt-3"
          onClick={() => {
            arrayHelpers.remove(index);
          }}
          type="button"
        >
          Remove
        </DangerButton>
      </div>
    </div>
  );
};
