import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect,useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { fetchUsers, getUsers } from "../../app/reducers/Users/userSlice";
import { generateOptions } from "../../utils/Utils";
import { useMemo } from "react";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import {
  fetchCatalogs,
  getCatalog,
} from "../../app/reducers/Catalog/catalogSlice";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import { toast } from "react-toastify";
import { authAxiosInstance } from "../../utils/axiosConfig";
import ReactSelect from "react-select/async";
import QueryString from "qs";
import { X } from "react-feather";
import ProgressBar from '../../components/progressBar/ProgressBar'

const TableHeader = ({ headers }) => {
  return (
    <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
      <tr>
        {headers?.map((header) => (
          <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-semibold text-left">{header}</div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
const StockTransfer = () => {
  const dispatch = useDispatch();
  const [progressLoading, setProgressLoading] = useState(false)
  const [currentItem, setCurrentItem] = useState(0)
  const [totalItem, setTotalItem] = useState(0)
  const [errorData, setErrorData] = useState([])
  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchCatalogs({ limit: 300 }));
  }, []);

  const { loading: locationLoading, location } = useSelector(getLocation);
  const { loading: catalogLoading, catalog } = useSelector(getCatalog);
  const { loading: productLoading, product } = useSelector(getProduct);

  const locationInfoId = localStorage.getItem("locationInfoId");

  const data = useMemo(() => (catalog?.docs ? catalog.docs : []), [catalog]);
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      from: locationInfoId ? locationInfoId : "",
      to: "",
      products: [],
    },
    validationSchema: Yup.object({
      to: Yup.string().required(),
      from: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      let products = values?.products
      if (products.length > 0) {
        setProgressLoading(true)
        setTotalItem(products.length)
        for (let i = 0; i < products.length; i++) {
          try {
            let data = {
              from : values.from,
              to: values.to,
              catalog: products[i].catalog,
              product: products[i].product,
              quantity: products[i].quantity,
              status: 'pending'
            }
            const response = await authAxiosInstance.post(`stockTransfer`, data)
            if (response.data) {
              setCurrentItem((prevState) => prevState + 1)
            }
          }
          catch (error) {
            if (error.response.data) {
              setCurrentItem((prevState) => prevState + 1)
              setErrorData(prev => [...prev, { ...products[i], error: JSON.stringify(error.response?.data) }])
            }
          }
        }
      } else {
        alert('Please select atleast one product')
      }
      // dispatch(createStockTransfer({ ...values, status: "pending" }));
      formik.resetForm();
    },
  });

  return (
    <>
      <PageWithCard heading="Stock Transfer">
        {progressLoading ?
          <ProgressBar currentItem={currentItem} totalItem={totalItem} errorData={errorData} />
          : (
            <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
              {locationLoading ? (
                <ClipLoader />
              ) : (
                <FormikSelectGroup
                  formik={formik}
                  name="from"
                  label="From"
                  isClearable
                  options={generateOptions({
                    array: location ? location.docs : [],
                    valueField: "_id",
                    labelField: "name",
                  })}
                  onChange={(field) => {
                    if (!field) {
                      formik.setFieldValue("from", "");
                    }
                    if (field.value == formik.values.to) {
                      return alert("Same Store Transfer Not allowed");
                    }
                    formik.setFieldValue("from", field.value);
                  }}
                />
              )}
              {locationLoading ? (
                <ClipLoader />
              ) : (
                <FormikSelectGroup
                  formik={formik}
                  name="to"
                  label="To"
                  isClearable
                  options={generateOptions({
                    array: location ? location.docs : [],
                    valueField: "_id",
                    labelField: "name",
                  })}
                  onChange={(field) => {
                    if (!field) {
                      formik.setFieldValue("to", "");
                    }
                    if (field.value == formik.values.from) {
                      return alert("Same Store Transfer Not allowed");
                    }
                    formik.setFieldValue("to", field.value);
                  }}
                />
              )}
              <FormikProvider value={formik}>
                <FieldArray
                  name="products"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        <label>Product</label>
                        <ReactSelect
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={async (option) => {
                            const product = JSON.parse(option.value);
                            const string = QueryString.stringify({
                              product: product._id,
                              location: formik.values.from,
                            });
                            const resp = await authAxiosInstance.get(
                              `productInventory?${string}`
                            );
                            const stock = resp.data.data?.docs[0]?.finished;
                            if (stock <= 0 || !stock) {
                              return alert("product out of stock");
                            }
                            if (
                              formik.values.products.find(
                                (ele) => ele.product === product._id
                              )
                            ) {
                              alert("already in list");
                            } else {
                              arrayHelpers.insert(0, {
                                product: product._id,
                                catalog: product.catalogData?._id,
                                quantity: 1,
                                stock: stock,
                                sku: product.sku,
                              });
                            }
                          }}
                          loadOptions={async (search) => {
                            const string = QueryString.stringify({
                              search,
                              populate: true
                            });
                            const resp = await authAxiosInstance.get(
                              `product?${string}`
                            );
                            const products = resp.data.data.docs;
                            return products.map((ele) => {
                              const barcode = ele.sku
                              return {
                                label: `${barcode} - ${ele?.catalogData?.name} / ${ele.color}`,
                                value: JSON.stringify(ele),
                              };
                            });
                          }}
                        />
                        <table className="table-auto w-full">
                          <TableHeader
                            headers={["", "stock", "quantity", "sku"]}
                          />
                          <tbody className="text-sm divide-y divide-slate-200">
                            {formik.values.products &&
                              formik.values.products.map((ele, i) => (
                                <tr key={ele.product}>
                                  <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="flex">
                                      <X
                                        onClick={() => {
                                          arrayHelpers.remove(i);
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <FormikInputGroup
                                      name={`products.${i}.stock`}
                                      formik={formik}
                                      type="number"
                                      readOnly
                                    />
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <FormikInputGroup
                                      name={`products.${i}.quantity`}
                                      type="number"
                                      formik={formik}
                                      min={0}
                                      max={formik.values.products[i].stock}
                                    />
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <FormikInputGroup
                                      name={`products.${i}.sku`}
                                      formik={formik}
                                      type="text"
                                      readOnly
                                    />
                                  </td>

                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </>
                    );
                  }}
                ></FieldArray>
              </FormikProvider>
              <div>
                <PrimaryButton type="submit" onClick={formik.handleSubmit}>
                  Submit
                </PrimaryButton>
              </div>
            </form>)}
      </PageWithCard>
    </>
  );
};

export default StockTransfer;
