import React, { useEffect } from "react";
import { useMemo } from "react";
import { Edit2, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  fetchBankbooks,
  fetchCashbooks,
  getCashbooks,
} from "../../app/reducers/Cashbook/cashbookSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { generateOptions } from "../../utils/Utils";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import { useFormik } from "formik";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import moment from "moment";
import * as Yup from "yup";
import ReactSelect from "react-select";
import {
  CASHBOOK_PAYMENT_METHODS,
  PAYMENT_METHODS,
} from "../../utils/dropdownOptions";

const ViewBankBook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cashbook, loading } = useSelector(getCashbooks);
  const { location, loading: locationLoading } = useSelector(getLocation);
  const locationInfoId = localStorage.getItem("locationInfoId");

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Store",
      accessor: "locationData.name",
    },
    {
      Header: "Expense Category",
      Cell: (prop) => {
        const { row } = prop;
        console.log(row.original);
        return row.original?.expenseCategory
          ? row.original?.expenseCategory
          : "-";
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Balance",
      accessor: "balance",
    },
    {
      Header: "Note",
      accessor: "notes",
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row.original.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      Header: "type",
      accessor: "type",
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      store: locationInfoId,
      from: moment().startOf("day").valueOf(),
      to: moment().endOf("day").valueOf(),
    },
    validationSchema: Yup.object({
      from: Yup.number().required(),
      to: Yup.string().required(),
      store: Yup.string().required(),
    }),
    onSubmit: (values) => {
      dispatch(
        fetchBankbooks({
          populate: true,
          "store": values.store,
          createdAt: {
            $gte: values.from,
            $lte: values.to,
          },
        })
      );
    },
  });

  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(
    () => (cashbook.docs ? cashbook.docs : []),
    [cashbook]
  );

  useEffect(() => {
    dispatch(
      fetchBankbooks({
        populate: true,
        store: formik.values.store,
        createdAt: {
          $gte: formik.values.from,
          $lte: formik.values.to,
        },
      })
    );
    dispatch(fetchLocations({ populate: true }));
  }, []);

  return (
    <PageWithCard heading="View UPI Book">
      <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
        <>
          <label className="block text-sm font-medium">Mode</label>
          <ReactSelect
            options={generateOptions({
              array: CASHBOOK_PAYMENT_METHODS,
              labelField: "label",
              valueField: "value",
            })}
            value={{ label: "UPI", value: "bank" }}
            onChange={(selectedOption) => {
              console.log(selectedOption);
              switch (selectedOption.value) {
                case "cash":
                  navigate("/viewCashbook");
                  break;
                case "card":
                  navigate("/viewCardbook");
                  break;

                default:
                  break;
              }
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </>
        {locationLoading ? (
          <ClipLoader />
        ) : (
          <FormikSelectGroup
            name="store"
            label="Stores"
            formik={formik}
            options={generateOptions({
              array: location?.docs ? location?.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
          />
        )}
        <FormikInputDateGroup
          formik={formik}
          label="From"
          name="from"
          required
        />
        <FormikInputDateGroup formik={formik} label="To" name="to" required />
        <div>
          <PrimaryButton className="mb-3" type="submit">
            Submit
          </PrimaryButton>
        </div>
      </form>
      {loading ? (
        <ClipLoader />
      ) : (
        <TableWithHeadingAndSearch columns={columnsMemo} data={dataMemo} />
      )}
    </PageWithCard>
  );
};

export default ViewBankBook;
