import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "stockAdjustment";

export const fetchStockAdjustments = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const fetchProductStockAdjustments = fetchData.generateThunk(
  `${SLICE_NAME}/product`,
    `${SLICE_NAME}/product`
);
export const editStock = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchStock = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteStock = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createStockAdjustment = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createProductStockAdjustment = createData.generateThunk(
    `${SLICE_NAME}/product`,
    `${SLICE_NAME}/product`
  );

const stockAdjustmentSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchStockAdjustments, SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchProductStockAdjustments, `${SLICE_NAME}Product`),
    ...createData.generateExtraReducers(createStockAdjustment, SLICE_NAME),
    ...createData.generateExtraReducers(createProductStockAdjustment, `${SLICE_NAME}/product`),
    ...deleteData.generateExtraReducers(deleteStock, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchStock, SLICE_NAME),
    ...editData.generateExtraReducers(editStock, SLICE_NAME)
  },
});

export const getStockAdjustment = (state) => state.stockAdjustment;
export const { setEditId } = stockAdjustmentSlice.actions;
export default stockAdjustmentSlice.reducer;
