import moment from "moment";
import React from "react";
import { File } from "react-feather";
import { useSelector } from "react-redux";
import PrimaryBadge from "../../components/infrastructure/Badges/PrimaryBadge";
import SecondaryBadge from "../../components/infrastructure/Badges/SecondaryBadge";
import TransactionPanel from "../../components/infrastructure/Panels/TransactionPanel";
import Tab from "../../components/infrastructure/Tabs/Tab";
import Tabs from "../../components/infrastructure/Tabs/Tabs";
import { checkURL } from "../../utils/Utils";

const PanelSection = ({ label, value }) => {
  return (
    <div className="flex items-center gap-2">
      <p className="text-xs">{label} :</p>
      <p className="text-base"> {value}</p>
    </div>
  );
};

const ViewPartyPanel = ({ open, setOpen, user }) => {
  return (
    <TransactionPanel
      transactionPanelOpen={open}
      setTransactionPanelOpen={setOpen}
    >
      <div className="max-w-sm mx-auto lg:max-w-none">
        <div className="text-slate-800 font-semibold  mb-1">
          <p className="mb-3">{user.name}</p>
          <SecondaryBadge>{user.role}</SecondaryBadge>
        </div>
        <div className="bg-white p-4 rounded-lg text-md my-2">
          <div className="flex flex-col gap-4">
            {/* <PanelSection label="Name" value={user.name} /> */}
            <PanelSection label="Phone" value={user.username} />
            <PanelSection label="Company Name" value={user.companyName} />
            <PanelSection label="GST" value={user.gstNumber} />
            <PanelSection label="address" value={user.address} />
            <PanelSection label="Email" value={user.email} />
          </div>
        </div>
      </div>
    </TransactionPanel>
  );
};

export default ViewPartyPanel;
