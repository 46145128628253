import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { Edit2, Eye, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  deleteUser,
  fetchUsers,
  getUsers,
  setEditUserId,
} from "../../app/reducers/Users/userSlice";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import ViewPartyPanel from "./ViewUserPanel";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";

const ViewCustomers = () => {
  const { loading, users } = useSelector(getUsers);
  const [viewUserModalOpen, setViewUserModalOpen] = useState(false);
  const [viewingUser, setViewingUser] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const debouncedSearch = useCallback(
    _.debounce(async (search) => {
      dispatch(fetchUsers({ role: "customer", search: search }));
    }, 300),
    []
  );

  useEffect(() => {
    dispatch(fetchUsers({ role: "customer" }));
  }, []);
  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Name",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <a
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/customer/${row.original?._id}`);
            }}
          >
            <span style={{ textDecoration: "underline", color: "blue" }}>
              {row.original?.name}
            </span>
          </a>
        );
      },
    },
    {
      Header: "Phone",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.phone ? row.original.phone : row.original.username}
          </span>
        );
      },
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Eye
              size={18}
              color="green"
              onClick={() => {
                setViewUserModalOpen(true);
                setViewingUser(row.original);
              }}
            />
            {/* <Edit2
              size={18}
              color="blue"
              onClick={() => {
                dispatch(setEditId(row.original._id));
                navigate("/editUser");
              }}
            /> */}
            <Trash
              size={18}
              color="red"
              onClick={async () => {
                await dispatch(
                  deleteUser({
                    id: row.original._id,
                  })
                );
                dispatch(fetchUsers());
              }}
            />
          </div>
        );
      },
    },
  ];
  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(() => (users?.docs ? users.docs : []), [users.docs]);
  return (
    <PageWithCard heading="View Users">
      <ViewPartyPanel
        setOpen={setViewUserModalOpen}
        open={viewUserModalOpen}
        user={viewingUser}
      />
      <TableWithHeadingAndGlobalSearch
        columns={columnsMemo}
        data={dataMemo}
        heading="Users"
        loading={loading}
        searchFunction={debouncedSearch}
      />
    </PageWithCard>
  );
};

export default ViewCustomers;
