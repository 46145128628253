import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { fetchUnfinished, getUnfinished } from "../../app/reducers/Unfinished/unfinishedSlice";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import { fetchTransferToDamage, getTransferToDamage } from "../../app/reducers/TransferToDamage/transferToDamageSlice";

const ViewTransferToDamage = () => {
    const dispatch = useDispatch();
    const [page,setPage] = useState(1)

    useEffect(() => {
        dispatch(fetchTransferToDamage({ populate: true, page: page }));
    }, [page]);

    const { loading, finishedDamage } = useSelector(getTransferToDamage);
    const columns = [
        {
            Header: "SRNO",
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },
        {
            Header: "location",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.locationData?.name}
                    </span>
                );
            },
        },
        {
            Header: "catalog",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.catalogData?.name}
                    </span>
                );
            },
        },
        {
            Header: "product",
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original?.productData?.color}
                    </span>
                );
            },
        },
        {
            Header: "Quantity",
            accessor: "quantity",
        },
    ];
    const columnsMemo = useMemo(() => columns, [columns]);
    const dataMemo = useMemo(() => (finishedDamage?.docs ? finishedDamage.docs : []), [finishedDamage.docs]);

    return (
        <PageWithCard heading="Transfer To Damage">
            <TableWithHeadingAndSearch
                columns={columnsMemo}
                data={dataMemo}
                heading="Transfer To Damage"
            />
             <PaginationClassic paginationDetails={finishedDamage} setPage={setPage} />
        </PageWithCard>
    );
};

export default ViewTransferToDamage;
