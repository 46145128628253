import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import {
  fetchUnfinished,
  getUnfinished,
} from "../../app/reducers/Unfinished/unfinishedSlice";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import moment from "moment";

const ViewUnfinishedTransfer = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const debouncedSearch = useCallback(
    _.debounce(async (search) => {
      console.log("searching");
      dispatch(fetchUnfinished({ populate: true, search }));
    }, 300),
    []
  );

  useEffect(() => {
    dispatch(fetchUnfinished({ populate: true, page: page }));
  }, [page]);

  const { loading, unfinished } = useSelector(getUnfinished);
  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Date",
      Cell: (prop) => {
        const { data, row } = prop;
        return moment(row?.original?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      Header: "location",
      Cell: ({ row }) => {
        return <span>{row.original?.locationData?.name}</span>;
      },
    },
    {
      Header: "catalog",
      Cell: ({ row }) => {
        return <span>{row.original?.catalogData?.name}</span>;
      },
    },
    {
      Header: "product",
      Cell: ({ row }) => {
        return <span>{row.original?.productData?.color}</span>;
      },
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
  ];
  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(
    () => (unfinished?.docs ? unfinished.docs : []),
    [unfinished.docs]
  );

  return (
    <PageWithCard heading="Unfinished To Damage Transfer">
      <TableWithHeadingAndGlobalSearch
        columns={columnsMemo}
        data={dataMemo}
        searchFunction={debouncedSearch}
        heading="Unfinished To Damage"
      />
      <PaginationClassic paginationDetails={unfinished} setPage={setPage} />
    </PageWithCard>
  );
};

export default ViewUnfinishedTransfer;
