import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import Select from "react-select/async";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { toast } from "react-toastify";

const SearchProductModal = ({
  searchProductModalOpen,
  setSearchProductModalOpen,
}) => {
  return (
    <ModalBasic
      modalOpen={searchProductModalOpen}
      setModalOpen={setSearchProductModalOpen}
      title="Search Product"
    >
      <div className="p-4 min-h-screen">
        <Select
          loadOptions={async (value) => {
            const data = {
              populate: true,
              search: value,
            };
            const string = QueryString.stringify(data);
            const resp = await authAxiosInstance.get(`/product?${string}`);
            return resp.data.data.docs.map((ele) => ({
              label: `${ele.sku} - ${ele.color}/${ele.catalogData.name}`,
              value: JSON.stringify(ele),
            }));
          }}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          onChange={(option) => {
            const product = JSON.parse(option.value);
            navigator.clipboard.writeText(product.sku);
            toast.info("sku copied");
          }}
        />
      </div>
    </ModalBasic>
  );
};

export default SearchProductModal;
