import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "expenseCategory";

export const fetchExpenseCategories = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editExpenseCategory = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchExpenseCategory = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteExpenseCategory = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createExpenseCategory = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const expenseCategorySlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchExpenseCategories, SLICE_NAME),
    ...createData.generateExtraReducers(createExpenseCategory, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteExpenseCategory, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchEditData, SLICE_NAME),
    ...editData.generateExtraReducers(editExpenseCategory, SLICE_NAME),
  },
});

export const getExpenseCategories = (state) => state.expenseCategory;
export const { setEditId } = expenseCategorySlice.actions;
export default expenseCategorySlice.reducer;
