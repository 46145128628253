import React from "react";
import SecondaryBadge from "../../components/infrastructure/Badges/SecondaryBadge";
import TransactionPanel from "../../components/infrastructure/Panels/TransactionPanel";
import moment from "moment"

const ViewPurchasePanel = ({ open, setOpen, party }) => {
    return (
        <TransactionPanel
            transactionPanelOpen={open}
            setTransactionPanelOpen={setOpen}
        >
            <div className="max-w-sm mx-auto lg:max-w-none">
                <div className="text-slate-800 font-semibold  mb-1">
                    <p className="mb-3"></p>
                    <SecondaryBadge>Received Items</SecondaryBadge>
                    {
                        party?.received && party?.received.length > 0 && party.received.map(data => (
                            <>
                                <div
                                    className='relative p-4 mb-2'
                                    style={{
                                        border: '1px solid #d6c7c7',
                                        borderRadius: '5px',
                                        marginTop:"3px"
                                    }}
                                >   
                                    <p><span className="font-light">Date :-</span>{moment(data.date).format('YYYY-DD-MM')}</p>
                                    <p><span className="font-light">Received Item :-</span>{data.receivedItem}</p>
                                    <p><span className="font-light">Damaged Item :-</span> {data.damagedItem}</p>

                                </div>
                            </>
                        ))
                    }
                </div>
            </div>
        </TransactionPanel>
    );
};

export default ViewPurchasePanel;
