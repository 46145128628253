import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxiosInstance, axiosInstance } from "../../../utils/axiosConfig";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "sale";

export const fetchSales = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editSale = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchSale = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteSale = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createSale = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const saleSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchSales, SLICE_NAME),
    ...createData.generateExtraReducers(createSale, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteSale, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchSale, SLICE_NAME),
    ...editData.generateExtraReducers(editSale, SLICE_NAME),
  },
});

export const getSale = state => state.sale;
export const { setEditId } = saleSlice.actions;
export default saleSlice.reducer;
