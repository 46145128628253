export const GENDER = [
  { label: "male", value: "male" },
  { label: "female", value: "female" },
];
export const EMPLOYEE_ROLES = [
  { label: "Admin", value: "admin" },
  { label: "Store Manager", value: "store_manager" },
  { label: "Godown Manager", value: "godown_manager" },
  { label: "Stock Transfer Manager", value: "stock_transfer" },
  { label: "Employee", value: "employee" },
];
export const VENDOR_ROLES = [
  { label: "Manufacturer", value: "manufacturer" },
  { label: "Job Work", value: "job_work" },
];

export const TRANSFER_TYPES = [
  { label: "Finished", value: "finished" },
  { label: "Unfinished", value: "unfinished" },
  { label: "Unfinished Embroidery", value: "unfinishedEmbroidery" },
];

export const STOCK_TRANSFER_STATUS = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
];

export const VOLUME = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];
export const FORM = [
  { label: "Liquid", value: "LIQ" },
  { label: "Powder", value: "POW" },
  { label: "SaltFree", value: "SF" },
  { label: "Press Cake", value: "PC" },
  { label: "Granules", value: "GR" },
  { label: "Crystal", value: "CR" },
];

export const PAYMENT_METHODS = [
  { label: "Cash", value: "cash" },
  { label: "UPI", value: "bank" },
  { label: "Credit", value: "credit" },
];

export const CASHBOOK_PAYMENT_METHODS = [
  { label: "Cash", value: "cash" },
  { label: "UPI", value: "bank" },
];

export const CASH_REPORT_PAYMENT_METHODS = [
  { label: "Cash", value: "cash" },
  { label: "UPI", value: "bank" },
];

export const CASH_REPORT_PAYMENT_TYPE = [
  { label: "Credit(+)", value: "credit" },
  { label: "Debit(-)", value: "debit" },
];

export const PAYMENT_TYPE = [
  { label: "Credit(+)", value: "credit" },
  { label: "Debit(-)", value: "debit" },
];

export const DATA_TYPE = [
  { label: "New", value: "new" },
  { label: "Old", value: "old" },
];

export const TSHIRT_SIZE = [
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
  { label: "XL", value: "XL" },
  { label: "Xxl", value: "Xxl" },
  { label: "XXxl", value: "XXxl" },
];
export const MUSIC_PREFERENCE = [
  { label: "Veg", value: "Veg" },
  { label: "Non veg", value: "Non veg" },
  { label: "Jain", value: "Jain" },
];
export const FOOD_PREFERENCE = [
  { label: "Rock", value: "Rock" },
  { label: "Jazz", value: "Jazz" },
  { label: "Bollywood party", value: "Bollywood party" },
  { label: "Sufi", value: "Sufi" },
  { label: "Ghazal", value: "Ghazal" },
];
export const CREW_MEMBERS = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
];
export const TITLE = [
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Mrs.", value: "Mrs." },
];

export const GREY_QUALITY_UNITS = [
  { label: "meter", value: "meter" },
  { label: "pcs", value: "pcs" },
];
export const CATALOG_TYPES = [
  { label: "Embroidery Catalog", value: "embroidery" },
  { label: "Normal Catalog", value: "normal" },
];

export const catalogStatus = [
  { label: "Active", value: "true" },
  { label: "Discontinued", value: "false" },
];
