import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import FormikInputGroup from '../../components/formik/FormikInputGroup'
import { ClipLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton'
import {
  fetchJobWorks,
  getJobWork,
  createJobWork,
  deleteJobWork
} from '../../app/reducers/JobWork/jobWorkSlice'
import { Trash } from 'react-feather'
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch'
import { useCallback } from 'react'
import { useMemo } from 'react'
import moment from 'moment/moment'

const CreateJobWork = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchJobWorks())
  }, [])
  const { loading, jobWork } = useSelector(getJobWork)

  const columns = [
    {
      Header: 'SRNO',
      Cell: (prop) => {
        const { data, row } = prop
        return row.index + 1
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Price',
      accessor: 'price',
    },
    {
      Header: 'created_at',
      Cell: (prop) => {
        const { data, row } = prop
        return moment(row.original.createdAt).format('YYYY-MM-DD HH:mm')
      },
    },
    {
      Header: 'Action',
      Cell: (prop) => {
        const { data, row } = prop
        return (
          <div
            className='flex items-center gap-2'
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Trash
              color='red'
              onClick={async () => {
                await dispatch(deleteJobWork({ id: row.original._id }))
                dispatch(fetchJobWorks())
              }}
            />
          </div>
        )
      },
    },
  ]

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchJobWorks({ search: search }))
    }, 300),
    []
  )
  const data = useMemo(
    () => (jobWork?.docs ? jobWork.docs : []),
    [jobWork]
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      price: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      price: Yup.number().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
        console.log(values,"chek")
      await dispatch(createJobWork({ ...values }))
      dispatch(fetchJobWorks())
      resetForm()
    },
  })

  return (
    <PageWithCard heading='Job Work Name'>
      <div className='text-slate-800 font-semibold mb-4'>Job Work Names</div>
      {loading ? (
        <ClipLoader />
      ) : (
      <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4'>
        <FormikInputGroup label='Name' formik={formik} name='name' required />
        <FormikInputGroup label='Price' type="number" formik={formik} name='price' required />
        <div>
          <PrimaryButton type='submit'>Submit</PrimaryButton>
        </div>
      </form>
      )}
      <TableWithHeadingAndGlobalSearch
        heading='Job Work Names'
        data={data}
        columns={columns}
        loading={loading}
        searchFunction={(value) => {
          debouncedSearch(value)
        }}
      />
    </PageWithCard>
  )
}

export default CreateJobWork
