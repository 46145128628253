import { FieldArray, FormikProvider, useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import EditModal from "../../components/infrastructure/Modals/EditModal";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { getProduct, setEditId, fetchProduct, fetchGreyColors, editProductWithFile } from "../../app/reducers/GreyColor/greyColorSlice";
import { useMemo } from "react";
import FormikFileInput from "../../components/formik/FormikFileInput";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";

const EditColorModal = ({ modalOpen, setModalOpen, editData }) => {
    console.log('cehck new')
    const dispatch = useDispatch()
    const folderName = editData?.color
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            _id: editData?._id,
            greyQuality: editData?.greyQuality,
            color: editData?.color,
            image: ''
        },
        onSubmit: async (values) => {
            await dispatch(editProductWithFile({ ...values }));
            dispatch(fetchGreyColors({ greyQuality: values.greyQuality, populate: true }))
            setModalOpen(false)
            window.location.reload()
        },
    });
    return (
        <ModalBasic modalOpen={modalOpen} setModalOpen={setModalOpen} title={"Edit Grey Color"}>
            <form onSubmit={formik.handleSubmit} className='flex flex-col gap-4 p-4'>
                <FormikInputGroup
                    label="SystemId"
                    name="_id"
                    formik={formik}
                    required
                    readOnly
                />
                <FormikInputGroup label='Color' formik={formik} name='color' required />
                <FormikDirectFileUpload
                    name={`image`}
                    formik={formik}
                    label="Image"
                    location={`trendy_sarees2/greyColor/${folderName}/`}
                />
                {/* <FormikFileInput name='image' formik={formik} label='Image' /> */}
                <div>
                    <PrimaryButton type='submit'>Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default EditColorModal;
