import React from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import _ from "lodash";
import { useFormik } from "formik";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { Edit2, Trash } from "react-feather";
import { useState } from "react";
import { getAuth } from "../../app/reducers/Auth/authSlice";
import { fetchExpenseCategories,getExpenseCategories,deleteExpenseCategory,createExpenseCategory } from "../../app/reducers/ExpenseCategory/expenseCategorySlice";
import * as Yup from "yup";

const ExpenseCategory = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1)
  const { expenseCategory, loading } = useSelector(getExpenseCategories);
  const { user } = useSelector(getAuth)
  useEffect(() => {
    dispatch(fetchExpenseCategories());
  }, []);

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
             
            <Trash
              color="red"
              onClick={async () => {
                await dispatch(deleteExpenseCategory({ id: row.original._id }));
                dispatch(fetchExpenseCategories());
              }}
            />
          </div>
        );
      },
    },
  ];


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
        name: Yup.string().required(),
      }),
    onSubmit: async (values, { resetForm }) => {
      await dispatch(createExpenseCategory(values));
      dispatch(fetchExpenseCategories());
      resetForm();
    },
  });
  const data = useMemo(
    () => (expenseCategory?.docs ? expenseCategory.docs : []),
    [expenseCategory]
  );
  const colsMemo = useMemo(() => columns, [columns]);
  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchExpenseCategories({ search: search }));
    }, 300),
    []
  );
  return (
    <PageWithCard heading="Expense Category">
      <div className="w-full mb-8 flex flex-col gap-4">
        <h1>Create Expense Category</h1>
        {formik.isSubmitting ? (
          <ClipLoader />
        ) : (
          <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
            <FormikInputGroup
              formik={formik}
              name="name"
              label="Expense Category"
              required
            />
            <div>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
          </form>
        )}
      </div>

      <TableWithHeadingAndGlobalSearch
        heading="Expense Category"
        data={data}
        columns={colsMemo}
        loading={loading}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
      />
    </PageWithCard>
  );
};

export default ExpenseCategory;
